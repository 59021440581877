/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  JSONObject: any;
};



export type AcceptInviteInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type Account = {
  __typename?: 'Account';
  companyName: Scalars['String'];
  expiresAt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  overrideLimit?: Maybe<PlanOverride>;
  plan: Plan;
  stats: AccountStats;
  subscription?: Maybe<StripeSubscription>;
  superAdmin: Scalars['Boolean'];
  users: Array<User>;
};

export type AccountStats = {
  __typename?: 'AccountStats';
  adminCount: Scalars['Int'];
  dataSourceCount: Scalars['Int'];
  dataWarehouseCount: Scalars['Int'];
  reportCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type AdminUpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AirtableAirbyteConfig = {
  apiKey: Scalars['String'];
  baseId: Scalars['String'];
  tables: Array<Maybe<Scalars['String']>>;
};

export type AirtableConfig = {
  apiKey: Scalars['String'];
};

export type AirtableDataSource = DataSource & {
  __typename?: 'AirtableDataSource';
  apiKey: Scalars['String'];
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type AlationColumn = {
  __typename?: 'AlationColumn';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metadata: AlationMetadata;
  name: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<ColumnType>;
};

export type AlationConnection = {
  __typename?: 'AlationConnection';
  dataSources: Array<AlationDataSource>;
  instance: AlationInstance;
  queries: Array<AlationQuery>;
  refreshToken: Scalars['String'];
};


export type AlationConnectionDataSourcesArgs = {
  ids?: Maybe<Array<Scalars['Int']>>;
};


export type AlationConnectionQueriesArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type AlationDataSource = {
  __typename?: 'AlationDataSource';
  alationDataSourceId: Scalars['Int'];
  credentials?: Maybe<AlationDwCreds>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metadata: AlationMetadata;
  name: Scalars['String'];
  schemas: Array<AlationSchema>;
  type: DataWarehouseType;
};


export type AlationDataSourceSchemasArgs = {
  columnName?: Maybe<Scalars['String']>;
  schemaId?: Maybe<Scalars['Int']>;
  schemaName?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
};

export type AlationDataWarehouse = DataWarehouse & {
  __typename?: 'AlationDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  ssh?: Maybe<Ssh>;
  type: DataWarehouseType;
};


export type AlationDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type AlationDataWarehouseInput = {
  alationDataSourceId: Scalars['Int'];
  database: Scalars['String'];
  host: Scalars['String'];
  instanceURL: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

/**
 * Type for Alation DataWarehouse Credentials for the user.
 * Currently Doesn't support Bigquery
 */
export type AlationDwCreds = {
  __typename?: 'AlationDwCreds';
  database?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  usingOAuth?: Maybe<Scalars['Boolean']>;
  warehouse?: Maybe<Scalars['String']>;
};

export enum AlationFilterType {
  Date = 'date',
  Integer = 'integer',
  Raw = 'raw',
  String = 'string'
}

export type AlationFlag = {
  __typename?: 'AlationFlag';
  createdDate: Scalars['String'];
  flagReason: Scalars['String'];
  flagType: AlationFlagType;
  id: Scalars['Int'];
  user: AlationUser;
};

export enum AlationFlagType {
  Deprecation = 'DEPRECATION',
  Endorsement = 'ENDORSEMENT',
  Warning = 'WARNING'
}

export type AlationInstance = {
  __typename?: 'AlationInstance';
  instanceURL: Scalars['String'];
};

export type AlationLoginInput = {
  code?: Maybe<Scalars['String']>;
  codeVerifier?: Maybe<Scalars['String']>;
  instanceURL: Scalars['String'];
  redirectURL: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
  usePartitionedCookies?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['Int']>;
};

export type AlationMetadata = {
  __typename?: 'AlationMetadata';
  flags: Array<AlationFlag>;
  tags: Array<AlationTag>;
};

export enum AlationObjectType {
  Query = 'Query',
  Table = 'Table'
}

export type AlationQuery = {
  __typename?: 'AlationQuery';
  alationDataSourceId: Scalars['Int'];
  dataWarehouseId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  filters: Array<AlationQueryFilter>;
  id: Scalars['Int'];
  lastUpdatedTime?: Maybe<Scalars['String']>;
  metadata: AlationMetadata;
  query: Scalars['String'];
  title: Scalars['String'];
};

export type AlationQueryFilter = {
  __typename?: 'AlationQueryFilter';
  defaultValue?: Maybe<Scalars['String']>;
  eg?: Maybe<Scalars['String']>;
  help?: Maybe<Scalars['String']>;
  isRaw: Scalars['Boolean'];
  multipleItems: Scalars['Boolean'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  type: AlationFilterType;
};

export type AlationSchema = {
  __typename?: 'AlationSchema';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metadata: AlationMetadata;
  name: Scalars['String'];
  tables: Array<AlationTable>;
  title: Scalars['String'];
};


export type AlationSchemaTablesArgs = {
  tableId?: Maybe<Scalars['Int']>;
};

export type AlationTable = {
  __typename?: 'AlationTable';
  columns: Array<AlationColumn>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metadata: AlationMetadata;
  name: Scalars['String'];
  title: Scalars['String'];
};


export type AlationTableColumnsArgs = {
  columnId?: Maybe<Scalars['Int']>;
  columnName?: Maybe<Scalars['String']>;
};

export type AlationTag = {
  __typename?: 'AlationTag';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AlationUser = {
  __typename?: 'AlationUser';
  displayName: Scalars['String'];
  id: Scalars['Int'];
  userURL: Scalars['String'];
};

export type AmplitudeAbDataSource = DataSource & {
  __typename?: 'AmplitudeAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type AmplitudeAirbyteConfig = {
  apiKey: Scalars['String'];
  secretKey: Scalars['String'];
  startDate: Scalars['String'];
};

export enum AppVersion {
  V1 = 'v1',
  V2 = 'v2'
}

export enum AwsJobStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Runnable = 'RUNNABLE',
  Running = 'RUNNING',
  Starting = 'STARTING',
  Submitted = 'SUBMITTED',
  Succeeded = 'SUCCEEDED'
}

export type AzureSqlDataWarehouse = DataWarehouse & {
  __typename?: 'AzureSQLDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  role: Scalars['String'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  type: DataWarehouseType;
  warehouse: Scalars['String'];
};


export type AzureSqlDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type AzureSqlDataWarehouseInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type AzureSqlDataWarehouseUpdateInput = {
  host?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AzureSynapseDataWarehouse = DataWarehouse & {
  __typename?: 'AzureSynapseDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  role: Scalars['String'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  type: DataWarehouseType;
  warehouse: Scalars['String'];
};


export type AzureSynapseDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type AzureSynapseDataWarehouseInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type AzureSynapseDataWarehouseUpdateInput = {
  host?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type BigqueryDataWarehouse = DataWarehouse & {
  __typename?: 'BigqueryDataWarehouse';
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  projectId?: Maybe<Scalars['String']>;
  projects: Array<BigqueryProject>;
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  type: DataWarehouseType;
};


export type BigqueryDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type BigqueryDataWarehouseInput = {
  code: Scalars['String'];
};

export type BigqueryDataWarehouseUpdateInput = {
  datasetLocation?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  serviceAccountKey?: Maybe<Scalars['String']>;
};

export type BigqueryProject = {
  __typename?: 'BigqueryProject';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BillingAddress = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type BraintreeAbDataSource = DataSource & {
  __typename?: 'BraintreeAbDataSource';
  dataWarehouse: DataWarehouse;
  environment: Scalars['String'];
  id: Scalars['Int'];
  merchantId: Scalars['String'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  publicKey: Scalars['String'];
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type BraintreeAirbyteConfig = {
  environment: Scalars['String'];
  merchantId: Scalars['String'];
  privateKey: Scalars['String'];
  publicKey: Scalars['String'];
  startDate: Scalars['String'];
};

export type ChorusAirbyteConfig = {
  apiToken: Scalars['String'];
};

export type Column = {
  __typename?: 'Column';
  name: Scalars['String'];
  type: ColumnType;
};

export enum ColumnType {
  Boolean = 'boolean',
  Json = 'json',
  Number = 'number',
  Text = 'text',
  Time = 'time',
  Unknown = 'unknown'
}

export type ConnectedSheet = {
  __typename?: 'ConnectedSheet';
  fileName: Scalars['String'];
  id: Scalars['String'];
  owner: User;
  sheets: Array<Maybe<Sheet>>;
  type: SpreadSheetType;
  url: Scalars['String'];
};

export type ConnectedSheetPagination = {
  __typename?: 'ConnectedSheetPagination';
  hasNextPage: Scalars['Boolean'];
  totalObjects: Scalars['Int'];
};

export type ConnectedSheetPaginationInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ConnectedSheetResponse = {
  __typename?: 'ConnectedSheetResponse';
  connectedSheets: Array<ConnectedSheet>;
  pagination: ConnectedSheetPagination;
};

export enum ConnectedSheetsColumnType {
  DataWarehouse = 'DataWarehouse',
  FileName = 'FileName',
  LastUpdated = 'LastUpdated',
  Location = 'Location',
  Owner = 'Owner'
}

export type CreditCard = {
  __typename?: 'CreditCard';
  brand: Scalars['String'];
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  last4: Scalars['String'];
};

export type DataSet = {
  __typename?: 'DataSet';
  dataSource: DataSource;
  id: Scalars['Int'];
  recipe: Recipe;
  runs: Array<DataSetRun>;
};


export type DataSetRunsArgs = {
  id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type DataSetCreateInput = {
  dataSourceId: Scalars['Int'];
  recipeName: Scalars['String'];
};

export type DataSetRun = {
  __typename?: 'DataSetRun';
  awsJobId?: Maybe<Scalars['String']>;
  awsJobStatus?: Maybe<Scalars['String']>;
  endTime: Scalars['String'];
  logs: Array<Maybe<Log>>;
  startTime: Scalars['String'];
  statusReason?: Maybe<Scalars['String']>;
};

export type DataSource = {
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type DataSourceInput = {
  config?: Maybe<DataSourceInputConfig>;
  dataWarehouseId: Scalars['Int'];
  name: Scalars['String'];
  schema: Scalars['String'];
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type DataSourceInputConfig = {
  airtable?: Maybe<AirtableConfig>;
  airtable_ab?: Maybe<AirtableAirbyteConfig>;
  amplitude_ab?: Maybe<AmplitudeAirbyteConfig>;
  braintree_ab?: Maybe<BraintreeAirbyteConfig>;
  chorus_ab?: Maybe<ChorusAirbyteConfig>;
  double_click_campaign_manager?: Maybe<DoubleClickConfig>;
  dropbox?: Maybe<DropBoxConfig>;
  facebook?: Maybe<FacebookConfig>;
  facebook_ads_ab?: Maybe<FacebookAirbyteConfig>;
  facebook_pages_ab?: Maybe<FacebookPagesAirbyteConfig>;
  google_ads_ab?: Maybe<GoogleAdsAirbyteConfig>;
  google_analytics_ab?: Maybe<GoogleAnalyticsAirbyteConfig>;
  google_sheets?: Maybe<GoogleSheetsConfig>;
  google_sheets_ab?: Maybe<GoogleSheetsAirbyteConfig>;
  hubspot_ab?: Maybe<HubspotAirbyteConfig>;
  intercom_ab?: Maybe<IntercomAirbyteConfig>;
  jira_ab?: Maybe<JiraAirbyteConfig>;
  microsoft_ads_ab?: Maybe<MicrosoftAdsAirbyteConfig>;
  mssql_ab?: Maybe<MssqlAirbyteConfig>;
  mysql_ab?: Maybe<MysqlAirbyteConfig>;
  netsuite_ab?: Maybe<NetsuiteAirbyteConfig>;
  optimizely_ab?: Maybe<OptimizelyAirbyteConfig>;
  oracle_financials_ab?: Maybe<OracleFinancialsAbConfig>;
  outreach_ab?: Maybe<OutreachAirbyteConfig>;
  paypal_ab?: Maybe<PaypalAirbyteConfig>;
  postgres_ab?: Maybe<PostgresAirbyteConfig>;
  quickbooks_ab?: Maybe<QuickbooksAirbyteConfig>;
  retently_ab?: Maybe<RetentlyAirbyteConfig>;
  s3?: Maybe<S3Config>;
  s3_ab?: Maybe<S3AirbyteConfig>;
  sage_intacct_ab?: Maybe<SageIntacctAirbyteConfig>;
  salesforce_ab?: Maybe<SalesforceAirbyteConfig>;
  shopify_ab?: Maybe<ShopifyAirbyteConfig>;
  stripe_ab?: Maybe<StripeAirbyteConfig>;
  zendesk_ab?: Maybe<ZendeskAirbyteConfig>;
  zendesk_chat_ab?: Maybe<ZendeskChatAirbyteConfig>;
};

export type DataSourceStatus = {
  __typename?: 'DataSourceStatus';
  lastRunAt?: Maybe<Scalars['String']>;
  setupState: SetupState;
  syncErrorLogs: Array<Scalars['String']>;
  syncState: SyncState;
};

export enum DataSourceType {
  Adwords = 'adwords',
  Airtable = 'airtable',
  AirtableAb = 'airtable_ab',
  Amplitude = 'amplitude',
  AmplitudeAb = 'amplitude_ab',
  Anaplan = 'anaplan',
  AppleSearchAds = 'apple_search_ads',
  Bingads = 'bingads',
  Braintree = 'braintree',
  BraintreeAb = 'braintree_ab',
  ChorusAb = 'chorus_ab',
  DoubleClickCampaignManager = 'double_click_campaign_manager',
  Dropbox = 'dropbox',
  Facebook = 'facebook',
  FacebookAds = 'facebook_ads',
  FacebookAdsAb = 'facebook_ads_ab',
  FacebookPages = 'facebook_pages',
  FacebookPagesAb = 'facebook_pages_ab',
  GoogleAdsAb = 'google_ads_ab',
  GoogleAnalytics = 'google_analytics',
  GoogleAnalyticsAb = 'google_analytics_ab',
  GoogleSheets = 'google_sheets',
  GoogleSheetsAb = 'google_sheets_ab',
  Hubspot = 'hubspot',
  HubspotAb = 'hubspot_ab',
  Intercom = 'intercom',
  IntercomAb = 'intercom_ab',
  Jira = 'jira',
  JiraAb = 'jira_ab',
  MicrosoftAdsAb = 'microsoft_ads_ab',
  MssqlAb = 'mssql_ab',
  MysqlAb = 'mysql_ab',
  NetsuiteAb = 'netsuite_ab',
  NetsuiteSuiteanalytics = 'netsuite_suiteanalytics',
  OptimizelyAb = 'optimizely_ab',
  OracleFinancialsAb = 'oracle_financials_ab',
  OutreachAb = 'outreach_ab',
  PaypalAb = 'paypal_ab',
  PinterestAds = 'pinterest_ads',
  PostgresAb = 'postgres_ab',
  Quickbooks = 'quickbooks',
  QuickbooksAb = 'quickbooks_ab',
  RetentlyAb = 'retently_ab',
  S3 = 's3',
  S3Ab = 's3_ab',
  SageIntacctAb = 'sage_intacct_ab',
  Salesforce = 'salesforce',
  SalesforceAb = 'salesforce_ab',
  Shopify = 'shopify',
  ShopifyAb = 'shopify_ab',
  SnapchatAds = 'snapchat_ads',
  Square = 'square',
  Stripe = 'stripe',
  StripeAb = 'stripe_ab',
  Taboola = 'taboola',
  TwitterAds = 'twitter_ads',
  Xero = 'xero',
  Zendesk = 'zendesk',
  ZendeskAb = 'zendesk_ab',
  ZendeskChat = 'zendesk_chat',
  ZendeskChatAb = 'zendesk_chat_ab'
}

export type DataSourceTypeInfo = {
  __typename?: 'DataSourceTypeInfo';
  description?: Maybe<Scalars['String']>;
  iconURL: Scalars['String'];
  id: DataSourceType;
  name: Scalars['String'];
};

export type DataSourceUpdateInput = {
  id: Scalars['Int'];
  sourceSchema?: Maybe<Array<SourceSchemaInput>>;
  syncFrequency?: Maybe<SyncFrequency>;
};

export type DataWarehouse = {
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  type: DataWarehouseType;
};


export type DataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type DataWarehouseConfigInput = {
  alation?: Maybe<AlationDataWarehouseInput>;
  azureSQL?: Maybe<AzureSqlDataWarehouseInput>;
  azureSynapse?: Maybe<AzureSynapseDataWarehouseInput>;
  bigquery?: Maybe<BigqueryDataWarehouseInput>;
  databricks?: Maybe<DatabricksDataWarehouseInput>;
  mysql?: Maybe<MysqlDataWarehouseInput>;
  oracle?: Maybe<OracleDataWarehouseInput>;
  postgres?: Maybe<PostgresDataWarehouseInput>;
  redshift?: Maybe<RedshiftDataWarehouseInput>;
  snowflake?: Maybe<SnowflakeDataWarehouseInput>;
  sqlServer?: Maybe<SqlServerDataWarehouseInput>;
};

export type DataWarehouseConfigUpdateInput = {
  azureSQL?: Maybe<AzureSqlDataWarehouseUpdateInput>;
  azureSynapse?: Maybe<AzureSynapseDataWarehouseUpdateInput>;
  bigquery?: Maybe<BigqueryDataWarehouseUpdateInput>;
  databricks?: Maybe<DatabricksDataWarehouseUpdateInput>;
  mysql?: Maybe<MysqlDataWarehouseUpdateInput>;
  oracle?: Maybe<OracleDataWarehouseUpdateInput>;
  postgres?: Maybe<PostgresDataWarehouseUpdateInput>;
  redshift?: Maybe<RedshiftDataWarehouseUpdateInput>;
  snowflake?: Maybe<SnowflakeDataWarehouseUpdateInput>;
  sqlServer?: Maybe<SqlServerDataWarehouseUpdateInput>;
};

export enum DataWarehouseFeature {
  Sso = 'SSO'
}

export type DataWarehouseInput = {
  config?: Maybe<DataWarehouseConfigInput>;
  name: Scalars['String'];
  type: DataWarehouseType;
};

export type DataWarehouseStatus = {
  __typename?: 'DataWarehouseStatus';
  connected: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
};

export enum DataWarehouseType {
  Alation = 'alation',
  AzureSql = 'azureSQL',
  AzureSynapse = 'azureSynapse',
  Bigquery = 'bigquery',
  Databricks = 'databricks',
  Kloudio = 'kloudio',
  Mysql = 'mysql',
  Oracle = 'oracle',
  Postgres = 'postgres',
  Redshift = 'redshift',
  Snowflake = 'snowflake',
  SqlServer = 'sqlServer'
}

export type DataWarehouseUpdateInput = {
  config?: Maybe<DataWarehouseConfigUpdateInput>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type DatabricksDataWarehouse = DataWarehouse & {
  __typename?: 'DatabricksDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  role: Scalars['String'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  type: DataWarehouseType;
  warehouse: Scalars['String'];
};


export type DatabricksDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type DatabricksDataWarehouseInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type DatabricksDataWarehouseUpdateInput = {
  host?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Destination = {
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  type: DestinationType;
};

export type DestinationAccountInput = {
  email?: Maybe<EmailDestinationAccountInput>;
  excel?: Maybe<ExcelDestinationAccountInput>;
  gsheets?: Maybe<GSheetsDestinationAccountInput>;
  slack?: Maybe<SlackDestinationAccountInput>;
};

export type DestinationConfig = ExcelDestinationConfig | GSheetsDestinationConfig | SlackDestinationConfig;

export type DestinationConfigFilterInput = {
  excel?: Maybe<ExcelFilterConfigInput>;
  gsheets?: Maybe<GSheetsFilterConfigInput>;
};

export type DestinationConfigInput = {
  email?: Maybe<EmailDestinationConfigInput>;
  excel?: Maybe<ExcelDestinationConfigInput>;
  gsheets?: Maybe<GSheetsDestinationConfigInput>;
  slack?: Maybe<SlackDestinationConfigInput>;
};

export type DestinationFilterInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DestinationType>;
};

export type DestinationInput = {
  config: DestinationAccountInput;
  type: DestinationType;
};

export type DestinationTokenInput = {
  gsheets?: Maybe<GSheetsTokenInput>;
};

export enum DestinationType {
  Email = 'email',
  Excel = 'excel',
  Gsheets = 'gsheets',
  Slack = 'slack'
}

export type DestinationUpdateInput = {
  destination: DestinationInput;
  id: Scalars['Int'];
};

export type DoubleClickConfig = {
  table: Scalars['String'];
};

export type DoubleClickDataSource = DataSource & {
  __typename?: 'DoubleClickDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  table: Scalars['String'];
  type: DataSourceType;
};

export type DropBoxConfig = {
  table: Scalars['String'];
};

export type DropboxDataSource = DataSource & {
  __typename?: 'DropboxDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  table: Scalars['String'];
  type: DataSourceType;
};

export type Email = {
  __typename?: 'Email';
  emailAddress: Scalars['String'];
  id: Scalars['Int'];
};

export type EmailDestination = Destination & {
  __typename?: 'EmailDestination';
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  type: DestinationType;
};

export type EmailDestinationAccountInput = {
  email: Scalars['String'];
};

export type EmailDestinationConfigInput = {
  emailAsAttachment?: Maybe<Scalars['Boolean']>;
};

export type EmailShareInput = {
  alationUser?: Maybe<Scalars['Boolean']>;
  extraOptions?: Maybe<ShareOptions>;
  shareableIds: Array<Scalars['Int']>;
  shareableType: ShareableType;
  shareholders: Array<EmailShareholderInput>;
};

export type EmailShareholderInput = {
  accessLevel: ShareAccessLevel;
  email: Scalars['String'];
};

export enum ErrorCode {
  ExpiredPlan = 'EXPIRED_PLAN',
  InvalidSchedule = 'INVALID_SCHEDULE'
}

export type ExcelDestination = Destination & {
  __typename?: 'ExcelDestination';
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  type: DestinationType;
};

export type ExcelDestinationAccountInput = {
  code: Scalars['String'];
};

export type ExcelDestinationConfig = {
  __typename?: 'ExcelDestinationConfig';
  documentId: Scalars['String'];
  documentName: Scalars['String'];
  documentURL: Scalars['String'];
  shareableURL: Scalars['String'];
  sheetId: Scalars['String'];
  startCellId?: Maybe<Scalars['String']>;
};

export type ExcelDestinationConfigInput = {
  documentURL: Scalars['String'];
  sheetId: Scalars['String'];
  startCellId?: Maybe<Scalars['String']>;
};

export type ExcelFilterConfigInput = {
  destinationId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['String']>;
  sheetId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FacebookAdsAbDataSource = DataSource & {
  __typename?: 'FacebookAdsAbDataSource';
  accountId: Scalars['String'];
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type FacebookAirbyteConfig = {
  accessToken: Scalars['String'];
  accountId: Scalars['String'];
  startDate: Scalars['String'];
};

export type FacebookConfig = {
  table: Scalars['String'];
};

export type FacebookDataSource = DataSource & {
  __typename?: 'FacebookDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  table: Scalars['String'];
  type: DataSourceType;
};

export type FacebookPagesAbDataSource = DataSource & {
  __typename?: 'FacebookPagesAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  pageId: Scalars['String'];
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type FacebookPagesAirbyteConfig = {
  accessToken: Scalars['String'];
  pageId: Scalars['String'];
};

export type GSheetDestination = Destination & {
  __typename?: 'GSheetDestination';
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  selectedSpreadsheet?: Maybe<GSheetSpreadsheet>;
  spreadsheets: Array<GSheetSpreadsheet>;
  type: DestinationType;
};


export type GSheetDestinationSpreadsheetsArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GSheetSheet = {
  __typename?: 'GSheetSheet';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type GSheetSpreadsheet = {
  __typename?: 'GSheetSpreadsheet';
  id: Scalars['String'];
  name: Scalars['String'];
  sheets: Array<GSheetSheet>;
};

export type GSheetsDestinationAccountInput = {
  code: Scalars['String'];
};

export type GSheetsDestinationConfig = {
  __typename?: 'GSheetsDestinationConfig';
  sheetId: Scalars['Int'];
  spreadsheetId: Scalars['String'];
  spreadsheetName: Scalars['String'];
  startCellId?: Maybe<Scalars['String']>;
};

export type GSheetsDestinationConfigInput = {
  sheetId: Scalars['Int'];
  spreadsheetId: Scalars['String'];
  spreadsheetName: Scalars['String'];
  startCellId?: Maybe<Scalars['String']>;
};

export type GSheetsFilterConfigInput = {
  sheetId?: Maybe<Scalars['Int']>;
  spreadsheetId?: Maybe<Scalars['String']>;
};

export type GSheetsTokenInput = {
  accessToken: Scalars['String'];
};

export type GenericDataSource = DataSource & {
  __typename?: 'GenericDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type GoogleAdsAbDataSource = DataSource & {
  __typename?: 'GoogleAdsAbDataSource';
  customerIds: Array<Scalars['String']>;
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  loginCustomerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type GoogleAdsAirbyteConfig = {
  authCode: Scalars['String'];
  customerIds: Array<Scalars['String']>;
  loginCustomerId?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
};

export type GoogleAnalyticsAbDataSource = DataSource & {
  __typename?: 'GoogleAnalyticsAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type GoogleAnalyticsAirbyteConfig = {
  authCode: Scalars['String'];
  startDate: Scalars['String'];
  viewId: Scalars['String'];
};

export type GoogleLoginInput = {
  idToken: Scalars['String'];
};

export type GoogleSheetsAirbyteConfig = {
  authCode: Scalars['String'];
  spreadsheetId: Scalars['String'];
};

export type GoogleSheetsConfig = {
  table: Scalars['String'];
};

export type GoogleSheetsDataSource = DataSource & {
  __typename?: 'GoogleSheetsDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  table: Scalars['String'];
  type: DataSourceType;
};

export type GoogleSignUpInput = {
  companyName: Scalars['String'];
  firstName: Scalars['String'];
  googleIdToken: Scalars['String'];
  lastName: Scalars['String'];
  username: Scalars['String'];
};

export type Grid = {
  __typename?: 'Grid';
  columns: Array<GridColumn>;
  rows: Array<Scalars['JSON']>;
};

export type GridColumn = {
  __typename?: 'GridColumn';
  alias?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  schema: Scalars['String'];
  table: Scalars['String'];
};

export type HubspotAbDataSource = DataSource & {
  __typename?: 'HubspotAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type HubspotAirbyteConfig = {
  apiKey: Scalars['String'];
  startDate: Scalars['String'];
};

export type IntercomAbDataSource = DataSource & {
  __typename?: 'IntercomAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type IntercomAirbyteConfig = {
  authCode: Scalars['String'];
  startDate: Scalars['String'];
};



export type JiraAbDataSource = DataSource & {
  __typename?: 'JiraAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type JiraAirbyteConfig = {
  apiToken: Scalars['String'];
  domain: Scalars['String'];
  email: Scalars['String'];
  startDate: Scalars['String'];
};

export type Log = {
  __typename?: 'Log';
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type LoggedInUser = {
  __typename?: 'LoggedInUser';
  isNewUser?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  user: User;
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MicrosoftAdsAbDataSource = DataSource & {
  __typename?: 'MicrosoftAdsAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
  userId: Scalars['String'];
};

export type MicrosoftAdsAirbyteConfig = {
  authCode: Scalars['String'];
  customerId: Scalars['String'];
  dailyReports: Scalars['Boolean'];
  hourlyReports: Scalars['Boolean'];
  monthlyReports: Scalars['Boolean'];
  redirectURI: Scalars['String'];
  startDate: Scalars['String'];
  userId: Scalars['String'];
  weeklyReports: Scalars['Boolean'];
};

export type MssqlAirbyteConfig = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type MssqlDataSource = DataSource & {
  __typename?: 'MssqlDataSource';
  dataWarehouse: DataWarehouse;
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type MultiSelectOption = {
  __typename?: 'MultiSelectOption';
  display: Scalars['String'];
  value: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptUserInvitation: LoggedInUser;
  addMembers: Array<Scalars['Int']>;
  adminUpdateUser: User;
  cloneReport: Report;
  copyDataWarehouse: DataWarehouse;
  createDataSet: DataSet;
  createDataSource: DataSource;
  createDataWarehouse: DataWarehouse;
  createDestination: Destination;
  createReport: Report;
  createSQLReport: Report;
  createSchedule: Schedule;
  createTeam: Team;
  dataWarehouseStatus: DataWarehouseStatus;
  deleteDataSource: DataSource;
  deleteDataWarehouse: DataWarehouse;
  deleteDestination: Destination;
  deleteReport: Array<Report>;
  deleteSchedule: Schedule;
  deleteTeam: Scalars['Boolean'];
  fetchDataSourceRuns: Scalars['Int'];
  forceSyncDataSource: DataSource;
  getDefaultDataWarehouse: DataWarehouse;
  inviteUsers: Array<UserInvitation>;
  login?: Maybe<LoggedInUser>;
  loginWithAlation?: Maybe<LoggedInUser>;
  loginWithGoogle?: Maybe<LoggedInUser>;
  logout?: Maybe<Scalars['Boolean']>;
  overridePlanLimit?: Maybe<PlanOverride>;
  pauseDataSource: Scalars['Boolean'];
  removeMembers: Scalars['Int'];
  removeUser: Scalars['Boolean'];
  requestPasswordReset: Scalars['Boolean'];
  resendActivationLink: Scalars['Boolean'];
  resetPassword: LoggedInUser;
  runDataSet: DataSetRun;
  runSchedule: ScheduleRun;
  setSuperAdmin: Account;
  share: Array<Share>;
  shareWithEmail: Array<Share>;
  signUp: Scalars['Boolean'];
  signUpWithGoogle: LoggedInUser;
  subscribePlan?: Maybe<PlanSubscription>;
  switchPlan: Account;
  syncedAlationConnection?: Maybe<AlationConnection>;
  uninviteUser: Scalars['Boolean'];
  unshare: Array<Share>;
  updateDataSource: DataSource;
  updateDataWarehouse: DataWarehouse;
  updateDestination: Destination;
  updatePayment?: Maybe<Scalars['Boolean']>;
  updateReport: Report;
  updateSQLReport: Report;
  updateSchedule: Schedule;
  updateShareAccess: Array<Share>;
  updateTeam: Team;
  updateUser: User;
  upgradePlan: Account;
};


export type MutationAcceptUserInvitationArgs = {
  input: AcceptInviteInput;
};


export type MutationAddMembersArgs = {
  id: Scalars['Int'];
  members: Array<Scalars['Int']>;
};


export type MutationAdminUpdateUserArgs = {
  input: AdminUpdateUserInput;
};


export type MutationCloneReportArgs = {
  dwId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationCopyDataWarehouseArgs = {
  id: Scalars['Int'];
};


export type MutationCreateDataSetArgs = {
  input: DataSetCreateInput;
};


export type MutationCreateDataSourceArgs = {
  input: DataSourceInput;
};


export type MutationCreateDataWarehouseArgs = {
  input: DataWarehouseInput;
};


export type MutationCreateDestinationArgs = {
  input: DestinationInput;
};


export type MutationCreateReportArgs = {
  input: ReportInput;
};


export type MutationCreateSqlReportArgs = {
  input: SqlReportInput;
};


export type MutationCreateScheduleArgs = {
  input: ScheduleInput;
};


export type MutationCreateTeamArgs = {
  description: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDataWarehouseStatusArgs = {
  input: DataWarehouseInput;
};


export type MutationDeleteDataSourceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDataWarehouseArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDestinationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteReportArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteScheduleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTeamArgs = {
  id: Scalars['Int'];
};


export type MutationForceSyncDataSourceArgs = {
  id: Scalars['Int'];
};


export type MutationInviteUsersArgs = {
  invitations: Array<UserInvitationInput>;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginWithAlationArgs = {
  input: AlationLoginInput;
};


export type MutationLoginWithGoogleArgs = {
  input: GoogleLoginInput;
};


export type MutationLogoutArgs = {
  usingPartitionedCookies?: Maybe<Scalars['Boolean']>;
};


export type MutationOverridePlanLimitArgs = {
  accountId: Scalars['Int'];
  planOverride?: Maybe<PlanOverrideInput>;
};


export type MutationPauseDataSourceArgs = {
  id: Scalars['Int'];
  pause: Scalars['Boolean'];
};


export type MutationRemoveMembersArgs = {
  id: Scalars['Int'];
  members: Array<Scalars['Int']>;
};


export type MutationRemoveUserArgs = {
  userId: Scalars['Int'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationResendActivationLinkArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRunDataSetArgs = {
  id: Scalars['Int'];
};


export type MutationRunScheduleArgs = {
  id: Scalars['Int'];
};


export type MutationSetSuperAdminArgs = {
  enabled: Scalars['Boolean'];
};


export type MutationShareArgs = {
  input: ShareInput;
};


export type MutationShareWithEmailArgs = {
  input: EmailShareInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSignUpWithGoogleArgs = {
  input: GoogleSignUpInput;
};


export type MutationSubscribePlanArgs = {
  address?: Maybe<BillingAddress>;
  planId: Scalars['String'];
};


export type MutationSwitchPlanArgs = {
  accountId: Scalars['Int'];
  planType: PlanType;
};


export type MutationUninviteUserArgs = {
  id: Scalars['Int'];
};


export type MutationUnshareArgs = {
  input: ShareInput;
};


export type MutationUpdateDataSourceArgs = {
  input: DataSourceUpdateInput;
};


export type MutationUpdateDataWarehouseArgs = {
  input: DataWarehouseUpdateInput;
};


export type MutationUpdateDestinationArgs = {
  input: DestinationUpdateInput;
};


export type MutationUpdatePaymentArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationUpdateReportArgs = {
  input: UpdateReportInput;
};


export type MutationUpdateSqlReportArgs = {
  input: UpdateSqlReportInput;
};


export type MutationUpdateScheduleArgs = {
  input: ScheduleUpdateInput;
};


export type MutationUpdateShareAccessArgs = {
  input: UpdatedShareHolderInput;
};


export type MutationUpdateTeamArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpgradePlanArgs = {
  clientSecret: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type MysqlAirbyteConfig = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type MysqlDataSource = DataSource & {
  __typename?: 'MysqlDataSource';
  dataWarehouse: DataWarehouse;
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type MysqlDataWarehouse = DataWarehouse & {
  __typename?: 'MysqlDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  ssh?: Maybe<Ssh>;
  type: DataWarehouseType;
};


export type MysqlDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type MysqlDataWarehouseInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  ssh?: Maybe<SshInput>;
  username: Scalars['String'];
};

export type MysqlDataWarehouseUpdateInput = {
  database?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  ssh?: Maybe<SshInput>;
  username?: Maybe<Scalars['String']>;
};

export type NetsuiteAirbyteConfig = {
  accessToken: Scalars['String'];
  consumerKey: Scalars['String'];
  consumerSecret: Scalars['String'];
  realm: Scalars['String'];
  tokenSecret: Scalars['String'];
};

export type OnboardingState = {
  __typename?: 'OnboardingState';
  answers: Scalars['JSONObject'];
  completed: Scalars['Boolean'];
  currentStep: Scalars['Int'];
};

export type OptimizelyAirbyteConfig = {
  personalToken: Scalars['String'];
};

export type OracleDataWarehouse = DataWarehouse & {
  __typename?: 'OracleDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  role: Scalars['String'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  type: DataWarehouseType;
  warehouse: Scalars['String'];
};


export type OracleDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type OracleDataWarehouseInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type OracleDataWarehouseUpdateInput = {
  host?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type OracleFinancialsAbConfig = {
  instanceURL: Scalars['String'];
  password: Scalars['String'];
  startDate: Scalars['String'];
  userName: Scalars['String'];
};

export type OutreachAbDataSource = DataSource & {
  __typename?: 'OutreachAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type OutreachAirbyteConfig = {
  authCode: Scalars['String'];
  startDate: Scalars['String'];
};

export type PaypalAbDataSource = DataSource & {
  __typename?: 'PaypalAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type PaypalAirbyteConfig = {
  clientId: Scalars['String'];
  secret: Scalars['String'];
  startDate: Scalars['String'];
};

export type Plan = {
  __typename?: 'Plan';
  adminLimit?: Maybe<Scalars['Int']>;
  dataSourceLimit?: Maybe<Scalars['Int']>;
  dataWarehouseLimit?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: PlanType;
  name: Scalars['String'];
  price?: Maybe<PlanPrice>;
  reportLimit?: Maybe<Scalars['Int']>;
  scheduleLimit?: Maybe<Scalars['Int']>;
  upgradableTo: Array<Plan>;
  userLimit?: Maybe<Scalars['Int']>;
};

export type PlanOverride = {
  __typename?: 'PlanOverride';
  accountId: Scalars['Int'];
  adminLimit?: Maybe<Scalars['Int']>;
  dataSourceLimit?: Maybe<Scalars['Int']>;
  dataWarehouseLimit?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  reportLimit?: Maybe<Scalars['Int']>;
  scheduleLimit?: Maybe<Scalars['Int']>;
  userLimit?: Maybe<Scalars['Int']>;
};

export type PlanOverrideInput = {
  adminLimit?: Maybe<Scalars['Int']>;
  dataSourceLimit?: Maybe<Scalars['Int']>;
  dataWarehouseLimit?: Maybe<Scalars['Int']>;
  reportLimit?: Maybe<Scalars['Int']>;
  scheduleLimit?: Maybe<Scalars['Int']>;
  userLimit?: Maybe<Scalars['Int']>;
};

export type PlanPrice = {
  __typename?: 'PlanPrice';
  monthly: Scalars['Int'];
  yearly: Scalars['Int'];
};

export enum PlanRecurrence {
  Monthly = '_monthly',
  Yearly = '_yearly'
}

export type PlanSubscription = {
  __typename?: 'PlanSubscription';
  clientSecret: Scalars['String'];
  customerId: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export enum PlanType {
  Enterprise = 'ENTERPRISE',
  Essentials = 'ESSENTIALS',
  Free_2021 = 'FREE_2021',
  Premium = 'PREMIUM',
  Pro = 'PRO',
  Pro_2021 = 'PRO_2021',
  ProPlus_2021 = 'PRO_PLUS_2021',
  Starter = 'STARTER',
  Startup_2021 = 'STARTUP_2021',
  Team = 'TEAM',
  Trial = 'TRIAL'
}

export type PostgresAirbyteConfig = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type PostgresDataSource = DataSource & {
  __typename?: 'PostgresDataSource';
  dataWarehouse: DataWarehouse;
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type PostgresDataWarehouse = DataWarehouse & {
  __typename?: 'PostgresDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  ssh?: Maybe<Ssh>;
  type: DataWarehouseType;
};


export type PostgresDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type PostgresDataWarehouseInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  ssh?: Maybe<SshInput>;
  username: Scalars['String'];
};

export type PostgresDataWarehouseUpdateInput = {
  database?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  ssh?: Maybe<SshInput>;
  username?: Maybe<Scalars['String']>;
};

export enum Provider {
  Airbyte = 'AIRBYTE',
  Fivetran = 'FIVETRAN'
}

export type Query = {
  __typename?: 'Query';
  accounts: Array<Account>;
  alationConnection?: Maybe<AlationConnection>;
  appVersion: AppVersion;
  connectCardUrl: Scalars['String'];
  dataSet: DataSet;
  dataSets: Array<DataSet>;
  dataSource: DataSource;
  dataSourceTypeInfo: Array<DataSourceTypeInfo>;
  dataSources: Array<DataSource>;
  dataWarehouse: DataWarehouse;
  dataWarehouses: Array<DataWarehouse>;
  destination: Destination;
  destinations: Array<Destination>;
  isDWFeatureEnabled: Scalars['Boolean'];
  isLoggedIn: Scalars['Boolean'];
  listConnectedSheets: ConnectedSheetResponse;
  me: User;
  mockReport: Scalars['String'];
  myAccount: Account;
  pendingUserInvitations: Array<UserInvitation>;
  plans: Array<Plan>;
  recipes: Array<Recipe>;
  report: Report;
  reports: Array<Report>;
  runReport: ReportRun;
  runReportByName: ReportRun;
  runSQLReport: ReportRun;
  schedule: Schedule;
  schedules: Array<Schedule>;
  snowflakeOAuthURL: Scalars['String'];
  teams: Array<Team>;
  userInvitation: UserInvitationInfo;
};


export type QueryAlationConnectionArgs = {
  excludeDeleted?: Maybe<Scalars['Boolean']>;
};


export type QueryAppVersionArgs = {
  username: Scalars['String'];
};


export type QueryConnectCardUrlArgs = {
  id: Scalars['Int'];
};


export type QueryDataSetArgs = {
  id: Scalars['Int'];
};


export type QueryDataSourceArgs = {
  id: Scalars['Int'];
};


export type QueryDataWarehouseArgs = {
  id: Scalars['Int'];
  reportId?: Maybe<Scalars['Int']>;
};


export type QueryDestinationArgs = {
  id: Scalars['Int'];
};


export type QueryDestinationsArgs = {
  filter?: Maybe<DestinationFilterInput>;
};


export type QueryIsDwFeatureEnabledArgs = {
  feature: DataWarehouseFeature;
  id: Scalars['Int'];
};


export type QueryListConnectedSheetsArgs = {
  onlyMine?: Maybe<Scalars['Boolean']>;
  page?: Maybe<ConnectedSheetPaginationInput>;
  searchInput?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SortConnectedSheetsInput>;
  spreadsheetID?: Maybe<Scalars['String']>;
};


export type QueryMockReportArgs = {
  duration: Scalars['Int'];
};


export type QueryRecipesArgs = {
  dataSourceTypes?: Maybe<Array<Maybe<DataSourceType>>>;
};


export type QueryReportArgs = {
  id: Scalars['Int'];
};


export type QueryReportsArgs = {
  reportIds?: Maybe<Array<Scalars['Int']>>;
};


export type QueryRunReportArgs = {
  input: ReportInput;
  reportId?: Maybe<Scalars['Int']>;
};


export type QueryRunReportByNameArgs = {
  name: Scalars['String'];
  params?: Maybe<Array<ReportParamsInput>>;
};


export type QueryRunSqlReportArgs = {
  input: SqlReportInput;
  reportId?: Maybe<Scalars['Int']>;
};


export type QueryScheduleArgs = {
  id: Scalars['Int'];
};


export type QuerySchedulesArgs = {
  filter?: Maybe<ScheduleFilterInput>;
};


export type QuerySnowflakeOAuthUrlArgs = {
  id: Scalars['Int'];
  role?: Maybe<Scalars['String']>;
};


export type QueryUserInvitationArgs = {
  token: Scalars['String'];
};

export type QueryAggregation = {
  __typename?: 'QueryAggregation';
  col: QueryColumn;
  func: QueryAggregationFn;
};

export enum QueryAggregationFn {
  Count = 'count',
  Max = 'max',
  Min = 'min',
  Sum = 'sum'
}

export type QueryAggregationInput = {
  col: QueryColumnInput;
  func: QueryAggregationFn;
};

export type QueryColumn = {
  __typename?: 'QueryColumn';
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type QueryColumnInput = {
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum QueryComparisonOp {
  Eq = 'eq',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  Ne = 'ne'
}

export type QueryCondition = {
  __typename?: 'QueryCondition';
  col: QueryColumn;
  op: QueryConditionOp;
  val?: Maybe<Scalars['String']>;
};

export type QueryConditionInput = {
  col: QueryColumnInput;
  op: QueryConditionOp;
  val?: Maybe<Scalars['String']>;
};

export enum QueryConditionOp {
  Contains = 'contains',
  EndsWith = 'endsWith',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEquals = 'greaterThanOrEquals',
  LessThan = 'lessThan',
  LessThanOrEquals = 'lessThanOrEquals',
  NotEquals = 'notEquals',
  StartsWith = 'startsWith'
}

export type QueryFilter = {
  __typename?: 'QueryFilter';
  col: QueryColumn;
  label?: Maybe<Scalars['String']>;
  op: QueryFilterOp;
  vals?: Maybe<Array<Scalars['String']>>;
};

export type QueryFilterInput = {
  col: QueryColumnInput;
  op: QueryFilterOp;
  vals?: Maybe<Array<Scalars['String']>>;
};

export enum QueryFilterOp {
  Between = 'between',
  In = 'in',
  NotIn = 'notIn'
}

export type QueryJoin = {
  __typename?: 'QueryJoin';
  join: ReportTable;
  on: Array<QueryJoinCondition>;
  type: QueryJoinType;
};

export type QueryJoinCondition = {
  __typename?: 'QueryJoinCondition';
  childCol: QueryColumn;
  op: QueryComparisonOp;
  parentCol: QueryColumn;
};

export type QueryJoinConditionInput = {
  childCol: QueryColumnInput;
  op: QueryComparisonOp;
  parentCol: QueryColumnInput;
};

export type QueryJoinInput = {
  join: ReportTableInput;
  on: Array<QueryJoinConditionInput>;
  type: QueryJoinType;
};

export enum QueryJoinType {
  Inner = 'inner',
  Left = 'left',
  Outer = 'outer',
  Right = 'right'
}

export type QueryTable = {
  __typename?: 'QueryTable';
  aggregations?: Maybe<Array<QueryAggregation>>;
  cols: Array<QueryColumn>;
  groupBy?: Maybe<Array<QueryColumn>>;
  name: Scalars['String'];
};

export type QueryTableInput = {
  aggregations?: Maybe<Array<QueryAggregationInput>>;
  cols: Array<QueryColumnInput>;
  groupBy?: Maybe<Array<QueryColumnInput>>;
  name: Scalars['String'];
};

export type QuickbooksAirbyteConfig = {
  authCode: Scalars['String'];
  realmId: Scalars['String'];
  sandbox: Scalars['Boolean'];
  startDate: Scalars['String'];
};

export type Recipe = {
  __typename?: 'Recipe';
  dataSourceType: DataSourceType;
  description: Scalars['String'];
  displayName: Scalars['String'];
  name: Scalars['String'];
  outputTable: Scalars['String'];
};

export type RedshiftDataWarehouse = DataWarehouse & {
  __typename?: 'RedshiftDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  ssh?: Maybe<Ssh>;
  type: DataWarehouseType;
};


export type RedshiftDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type RedshiftDataWarehouseInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  ssh?: Maybe<SshInput>;
  username: Scalars['String'];
};

export type RedshiftDataWarehouseUpdateInput = {
  database?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  ssh?: Maybe<SshInput>;
  username?: Maybe<Scalars['String']>;
};

export type Report = {
  __typename?: 'Report';
  accessLevel: ShareAccessLevel;
  alationObjectId?: Maybe<Scalars['Int']>;
  dataWarehouse?: Maybe<DataWarehouse>;
  definition: ReportDefinition;
  id: Scalars['Int'];
  metadata?: Maybe<AlationMetadata>;
  name: Scalars['String'];
  owner: User;
  run: ReportRun;
  sharedWith: SharedWith;
  tags?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['String'];
};

export type ReportDefinition = ReportSql | ReportTable;

export type ReportInput = {
  dataWarehouseId: Scalars['Int'];
  definition: ReportTableInput;
  name: Scalars['String'];
  shareWithUsers?: Maybe<Array<ShareholderInput>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type ReportParamsInput = {
  name: Scalars['String'];
  val: Scalars['String'];
};

export type ReportRun = {
  __typename?: 'ReportRun';
  count: Scalars['Int'];
  grid: Grid;
  sql: Scalars['String'];
};


export type ReportRunGridArgs = {
  distinct?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ReportSql = {
  __typename?: 'ReportSQL';
  alationObjectId?: Maybe<Scalars['Int']>;
  alationQueryFilters?: Maybe<Array<AlationQueryFilter>>;
  query: Scalars['String'];
  template?: Maybe<Array<ReportTemplateVar>>;
};

export type ReportSqlInput = {
  alationObjectId?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
  template?: Maybe<Array<ReportTemplateVarInput>>;
};

export type ReportTable = {
  __typename?: 'ReportTable';
  alationObjectId?: Maybe<Scalars['Int']>;
  conditions?: Maybe<Array<QueryCondition>>;
  filters?: Maybe<Array<QueryFilter>>;
  joins?: Maybe<Array<QueryJoin>>;
  order?: Maybe<Array<Scalars['String']>>;
  schema: Scalars['String'];
  sortBy?: Maybe<Array<SortBy>>;
  table: QueryTable;
};

export type ReportTableInput = {
  alationObjectId?: Maybe<Scalars['Int']>;
  conditions?: Maybe<Array<QueryConditionInput>>;
  filters?: Maybe<Array<QueryFilterInput>>;
  joins?: Maybe<Array<QueryJoinInput>>;
  order?: Maybe<Array<Scalars['String']>>;
  schema: Scalars['String'];
  sortBy?: Maybe<Array<SortByInput>>;
  table: QueryTableInput;
};

export type ReportTemplateVar = {
  __typename?: 'ReportTemplateVar';
  dataType?: Maybe<ColumnType>;
  format?: Maybe<Scalars['String']>;
  inputType?: Maybe<TemplateVarInputType>;
  multiSelectOptions?: Maybe<Array<MultiSelectOption>>;
  multiSelectQuery?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ReportTemplateVarInput = {
  dataType?: Maybe<ColumnType>;
  format?: Maybe<Scalars['String']>;
  inputType?: Maybe<TemplateVarInputType>;
  multiSelectQuery?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ResetPasswordInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type RetentlyAirbyteConfig = {
  apiKey: Scalars['String'];
};

export enum RunStatus {
  Both = 'both',
  Failure = 'failure',
  None = 'none',
  Success = 'success'
}

export type S3AbDataSource = DataSource & {
  __typename?: 'S3AbDataSource';
  batchSize?: Maybe<Scalars['Int']>;
  bucket: Scalars['String'];
  bufferSize?: Maybe<Scalars['Int']>;
  columns?: Maybe<Array<Scalars['String']>>;
  dataWarehouse: DataWarehouse;
  delimiter?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  escapeChar?: Maybe<Scalars['String']>;
  fileType: S3AbFileType;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  pathPattern?: Maybe<Scalars['String']>;
  pathPrefix?: Maybe<Scalars['String']>;
  provider: Provider;
  quoteChar?: Maybe<Scalars['String']>;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  tableName: Scalars['String'];
  type: DataSourceType;
};

export enum S3AbFileType {
  Csv = 'csv',
  Parquet = 'parquet'
}

export type S3AirbyteConfig = {
  awsAccessKeyId?: Maybe<Scalars['String']>;
  awsSecretAccessKey?: Maybe<Scalars['String']>;
  batchSize?: Maybe<Scalars['Int']>;
  bucket: Scalars['String'];
  bufferSize?: Maybe<Scalars['Int']>;
  columns?: Maybe<Array<Scalars['String']>>;
  delimiter?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  escapeChar?: Maybe<Scalars['String']>;
  fileType: S3AbFileType;
  pathPattern?: Maybe<Scalars['String']>;
  pathPrefix?: Maybe<Scalars['String']>;
  quoteChar?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
};

export type S3Config = {
  table: Scalars['String'];
};

export type S3DataSource = DataSource & {
  __typename?: 'S3DataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  table: Scalars['String'];
  type: DataSourceType;
};

export type SqlReportInput = {
  dataWarehouseId: Scalars['Int'];
  definition: ReportSqlInput;
  name: Scalars['String'];
  shareWithUsers?: Maybe<Array<ShareholderInput>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type SqlServerDataWarehouse = DataWarehouse & {
  __typename?: 'SQLServerDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  role: Scalars['String'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  type: DataWarehouseType;
  warehouse: Scalars['String'];
};


export type SqlServerDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type SqlServerDataWarehouseInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type SqlServerDataWarehouseUpdateInput = {
  host?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Ssh = {
  __typename?: 'SSH';
  host: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type SshInput = {
  host: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
};

export type SageIntacctAirbyteConfig = {
  companyId: Scalars['String'];
  userId: Scalars['String'];
  userPassword: Scalars['String'];
};

export type SalesforceAbDataSource = DataSource & {
  __typename?: 'SalesforceAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type SalesforceAirbyteConfig = {
  authCode: Scalars['String'];
  startDate: Scalars['String'];
};

export type Schedule = {
  __typename?: 'Schedule';
  cronStr: Scalars['String'];
  destination: Destination;
  destinationConfig?: Maybe<DestinationConfig>;
  id: Scalars['Int'];
  latestRun?: Maybe<ScheduleRun>;
  name: Scalars['String'];
  nextRunAt: Scalars['String'];
  notifyOn?: Maybe<RunStatus>;
  owner: User;
  report: Report;
  reportTemplate?: Maybe<Array<ReportTemplateVar>>;
  runs?: Maybe<Array<Maybe<ScheduleRun>>>;
  status: ScheduleStatus;
  timeZone: Scalars['String'];
};


export type ScheduleRunsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type ScheduleFilterInput = {
  destinationConfig?: Maybe<DestinationConfigFilterInput>;
  excludeOnDemand?: Maybe<Scalars['Boolean']>;
  includeSharedReports?: Maybe<Scalars['Boolean']>;
  scheduleIds?: Maybe<Array<Scalars['Int']>>;
};

export type ScheduleInput = {
  cronStr: Scalars['String'];
  destinationConfig: DestinationConfigInput;
  destinationId: Scalars['Int'];
  name: Scalars['String'];
  notifyOn?: Maybe<RunStatus>;
  reportId: Scalars['Int'];
  reportTemplate?: Maybe<Array<ReportTemplateVarInput>>;
  timeZone: Scalars['String'];
};

export type ScheduleRun = {
  __typename?: 'ScheduleRun';
  endTime?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  runId: Scalars['Int'];
  startTime?: Maybe<Scalars['String']>;
  status: ScheduleRunStatus;
};

export enum ScheduleRunStatus {
  Failed = 'failed',
  Queued = 'queued',
  Running = 'running',
  Succeeded = 'succeeded'
}

export enum ScheduleStatus {
  Paused = 'paused',
  Scheduled = 'scheduled',
  Stopped = 'stopped'
}

export type ScheduleUpdateInput = {
  cronStr?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  notifyOn?: Maybe<RunStatus>;
  reportTemplate?: Maybe<Array<ReportTemplateVarInput>>;
  status?: Maybe<ScheduleStatus>;
  timeZone?: Maybe<Scalars['String']>;
};

export type Schema = {
  __typename?: 'Schema';
  name: Scalars['String'];
  tables: Array<Table>;
};

export enum SetupState {
  Broken = 'broken',
  Connected = 'connected',
  Incomplete = 'incomplete'
}

export type Share = {
  __typename?: 'Share';
  accessLevel: ShareAccessLevel;
  id: Scalars['Int'];
  shareableId: Scalars['Int'];
  shareableType: ShareableType;
  sharedBy: Scalars['Int'];
  shareholderId: Scalars['Int'];
  shareholderType: ShareHolderType;
};

/**
 * Defines the access level for Shared Objects.
 *
 * Access levels starting with CS_ to be used only for Connected Sheets
 */
export enum ShareAccessLevel {
  /** Allows to refresh the sheet using the Owner's DataSource */
  CsRefresh = 'CS_REFRESH',
  /** Same as CS_REFRESH but using own DataSource */
  CsRefreshWithCreds = 'CS_REFRESH_WITH_CREDS',
  /** Allows only to view the sheet and prevents it from running reports or schedules. */
  CsView = 'CS_VIEW',
  Edit = 'EDIT',
  Read = 'READ'
}

export enum ShareHolderType {
  Email = 'email',
  Team = 'team',
  User = 'user'
}

export type ShareInput = {
  extraOptions?: Maybe<ShareOptions>;
  shareableIds: Array<Scalars['Int']>;
  shareableType: ShareableType;
  shareholderType: ShareHolderType;
  shareholders: Array<ShareholderInput>;
};

export type ShareOptions = {
  destinationId: Scalars['Int'];
  spreadSheetName: Scalars['String'];
  spreadSheetUrl: Scalars['String'];
};

export enum ShareableType {
  DataWarehouse = 'dataWarehouse',
  Report = 'report'
}

export type SharedWith = {
  __typename?: 'SharedWith';
  emails: Array<ShareholderEmail>;
  teams: Array<ShareholderTeam>;
  users: Array<ShareholderUser>;
};

export type ShareholderEmail = {
  __typename?: 'ShareholderEmail';
  accessLevel: ShareAccessLevel;
  email: Email;
};

export type ShareholderInput = {
  accessLevel: ShareAccessLevel;
  id: Scalars['Int'];
};

export type ShareholderTeam = {
  __typename?: 'ShareholderTeam';
  accessLevel: ShareAccessLevel;
  team: Team;
};

export type ShareholderUser = {
  __typename?: 'ShareholderUser';
  accessLevel: ShareAccessLevel;
  user: User;
};

export type Sheet = {
  __typename?: 'Sheet';
  alationObjectId?: Maybe<Scalars['Int']>;
  alationObjectName: Scalars['String'];
  alationObjectType: AlationObjectType;
  dataSource?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastUpdated: Scalars['String'];
  name: Scalars['String'];
  refreshSchedule: Scalars['String'];
};

export type ShopifyAbDataSource = DataSource & {
  __typename?: 'ShopifyAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  shop: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type ShopifyAirbyteConfig = {
  authCode: Scalars['String'];
  shop: Scalars['String'];
  startDate: Scalars['String'];
};

export type SignUpInput = {
  companyName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username: Scalars['String'];
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SlackDestination = Destination & {
  __typename?: 'SlackDestination';
  channels: Array<SlackChannel>;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  type: DestinationType;
};

export type SlackDestinationAccountInput = {
  code: Scalars['String'];
  redirectURI: Scalars['String'];
};

export type SlackDestinationConfig = {
  __typename?: 'SlackDestinationConfig';
  channel: Scalars['String'];
};

export type SlackDestinationConfigInput = {
  channel: Scalars['String'];
};

export enum SnowflakeAuthType {
  Basic = 'BASIC',
  Sso = 'SSO'
}

export type SnowflakeDataWarehouse = DataWarehouse & {
  __typename?: 'SnowflakeDataWarehouse';
  database: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  port: Scalars['Int'];
  role: Scalars['String'];
  schemas: Array<Schema>;
  sharedWith: SharedWith;
  type: DataWarehouseType;
  warehouse: Scalars['String'];
};


export type SnowflakeDataWarehouseSchemasArgs = {
  reportId?: Maybe<Scalars['Int']>;
  schemaNames?: Maybe<Array<Scalars['String']>>;
  tableNames?: Maybe<Array<Scalars['String']>>;
};

export type SnowflakeDataWarehouseInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  port: Scalars['Int'];
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  warehouse: Scalars['String'];
};

export type SnowflakeDataWarehouseUpdateInput = {
  authCode?: Maybe<Scalars['String']>;
  database?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  warehouse?: Maybe<Scalars['String']>;
};

export type SortBy = {
  __typename?: 'SortBy';
  colName: Scalars['String'];
  type: SortByType;
};

export type SortByInput = {
  colName: Scalars['String'];
  type: SortByType;
};

export enum SortByType {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortConnectedSheetsInput = {
  field: ConnectedSheetsColumnType;
  /** Defaults to ASC */
  sortType?: Maybe<SortByType>;
};

export type SourceSchema = {
  __typename?: 'SourceSchema';
  name: Scalars['String'];
  namespace?: Maybe<Scalars['String']>;
  selected: Scalars['Boolean'];
};

export type SourceSchemaInput = {
  name: Scalars['String'];
  namespace?: Maybe<Scalars['String']>;
};

export enum SpreadSheetType {
  Excel = 'Excel',
  Gsheet = 'Gsheet'
}

export type StripeAbDataSource = DataSource & {
  __typename?: 'StripeAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type StripeAirbyteConfig = {
  accountId: Scalars['String'];
  clientSecret: Scalars['String'];
  startDate: Scalars['String'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  amount: Scalars['Int'];
  billingCycle: Scalars['String'];
  card?: Maybe<CreditCard>;
  nextBillingDate: Scalars['String'];
};

export enum SyncFrequency {
  Hr_1 = 'hr_1',
  Hr_2 = 'hr_2',
  Hr_3 = 'hr_3',
  Hr_6 = 'hr_6',
  Hr_8 = 'hr_8',
  Hr_12 = 'hr_12',
  Hr_24 = 'hr_24',
  Min_15 = 'min_15',
  Min_30 = 'min_30'
}

export enum SyncState {
  Failure = 'failure',
  Paused = 'paused',
  Pending = 'pending',
  Success = 'success',
  Syncing = 'syncing',
  Unknown = 'unknown'
}

export type Table = {
  __typename?: 'Table';
  columns?: Maybe<Array<Column>>;
  name: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  description: Scalars['String'];
  id: Scalars['Int'];
  members: Array<User>;
  name: Scalars['String'];
  owner: User;
};

export enum TemplateVarInputType {
  Csv = 'csv',
  MultiSelect = 'multiSelect',
  Text = 'text'
}

export type UpdateOnboardingStateInput = {
  answers: Scalars['JSONObject'];
  completed: Scalars['Boolean'];
  currentStep: Scalars['Int'];
};

export type UpdateReportInput = {
  dataWarehouseId?: Maybe<Scalars['Int']>;
  definition?: Maybe<ReportTableInput>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type UpdateSqlReportInput = {
  dataWarehouseId?: Maybe<Scalars['Int']>;
  definition?: Maybe<ReportSqlInput>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  onboardingState?: Maybe<UpdateOnboardingStateInput>;
};

export type UpdatedShareHolderInput = {
  shareableId: Array<Scalars['Int']>;
  shareableType: ShareableType;
  shareholders: Array<UpdatedShareHolders>;
};

export type UpdatedShareHolders = {
  accessLevel: ShareAccessLevel;
  id: Scalars['Int'];
  shareholderType: ShareHolderType;
};

export type User = {
  __typename?: 'User';
  account: Account;
  alationUserId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  hasAlationConnection: Scalars['Boolean'];
  id: Scalars['Int'];
  intercomHash: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  isSiteAdmin?: Maybe<Scalars['Boolean']>;
  lastLogin: Scalars['String'];
  lastName: Scalars['String'];
  onboardingState?: Maybe<OnboardingState>;
  teams: Array<Team>;
  username: Scalars['String'];
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  account: Account;
  email: Scalars['String'];
  id: Scalars['Int'];
  inviterId: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
};

export type UserInvitationInfo = {
  __typename?: 'UserInvitationInfo';
  alreadyRegistered: Scalars['Boolean'];
  companyName: Scalars['String'];
  inviterEmail: Scalars['String'];
  recipientEmail: Scalars['String'];
};

export type UserInvitationInput = {
  email: Scalars['String'];
  isAdmin: Scalars['Boolean'];
};

export type ZendeskAirbyteConfig = {
  apiToken: Scalars['String'];
  email: Scalars['String'];
  startDate: Scalars['String'];
  subdomain: Scalars['String'];
};

export type ZendeskChatAbDataSource = DataSource & {
  __typename?: 'ZendeskChatAbDataSource';
  dataWarehouse: DataWarehouse;
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: User;
  provider: Provider;
  schema: Scalars['String'];
  sourceSchema?: Maybe<Array<SourceSchema>>;
  startDate: Scalars['String'];
  status?: Maybe<DataSourceStatus>;
  syncFrequency: SyncFrequency;
  type: DataSourceType;
};

export type ZendeskChatAirbyteConfig = {
  authCode: Scalars['String'];
  startDate: Scalars['String'];
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { loggedInUser?: Maybe<(
    { __typename?: 'LoggedInUser' }
    & { user: (
      { __typename?: 'User' }
      & UserInfoFragment
    ) }
  )> }
);

export type LoginWithGoogleMutationVariables = Exact<{
  input: GoogleLoginInput;
}>;


export type LoginWithGoogleMutation = (
  { __typename?: 'Mutation' }
  & { loggedInUser?: Maybe<(
    { __typename?: 'LoggedInUser' }
    & { user: (
      { __typename?: 'User' }
      & UserInfoFragment
    ) }
  )> }
);

export type LoginWithAlationMutationVariables = Exact<{
  input: AlationLoginInput;
}>;


export type LoginWithAlationMutation = (
  { __typename?: 'Mutation' }
  & { loggedInUser?: Maybe<(
    { __typename?: 'LoggedInUser' }
    & Pick<LoggedInUser, 'token'>
    & { user: (
      { __typename?: 'User' }
      & UserInfoFragment
    ) }
  )> }
);

export type UserInfoFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'isAdmin' | 'email' | 'intercomHash'>
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'companyName' | 'expiresAt' | 'superAdmin'>
    & { stats: (
      { __typename?: 'AccountStats' }
      & Pick<AccountStats, 'userCount'>
    ), plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'id'>
    ) }
  ), onboardingState?: Maybe<(
    { __typename?: 'OnboardingState' }
    & Pick<OnboardingState, 'answers' | 'currentStep' | 'completed'>
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { success: Mutation['signUp'] }
);

export type SignUpWithGoogleMutationVariables = Exact<{
  input: GoogleSignUpInput;
}>;


export type SignUpWithGoogleMutation = (
  { __typename?: 'Mutation' }
  & { signUpWithGoogle: (
    { __typename?: 'LoggedInUser' }
    & { user: (
      { __typename?: 'User' }
      & UserInfoFragment
    ) }
  ) }
);

export type ResendActivationLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendActivationLinkMutation = (
  { __typename?: 'Mutation' }
  & { success: Mutation['resendActivationLink'] }
);

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestPasswordReset'>
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'LoggedInUser' }
    & { user: (
      { __typename?: 'User' }
      & UserInfoFragment
    ) }
  ) }
);

export type UpdateOnboardingMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateOnboardingMutation = (
  { __typename?: 'Mutation' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { onboardingState?: Maybe<(
      { __typename?: 'OnboardingState' }
      & Pick<OnboardingState, 'completed' | 'answers' | 'currentStep'>
    )> }
  ) }
);

export type CreateDataWarehouseMutationVariables = Exact<{
  input: DataWarehouseInput;
}>;


export type CreateDataWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { warehouse: (
    { __typename?: 'AlationDataWarehouse' }
    & Pick<AlationDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & Pick<AzureSqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & Pick<AzureSynapseDataWarehouse, 'id'>
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & Pick<BigqueryDataWarehouse, 'id'>
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & Pick<DatabricksDataWarehouse, 'id'>
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & Pick<MysqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & Pick<OracleDataWarehouse, 'id'>
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & Pick<PostgresDataWarehouse, 'id'>
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & Pick<RedshiftDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & Pick<SqlServerDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & Pick<SnowflakeDataWarehouse, 'id'>
  ) }
);

export type UpdateDataWarehouseMutationVariables = Exact<{
  input: DataWarehouseUpdateInput;
}>;


export type UpdateDataWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { warehouse: (
    { __typename?: 'AlationDataWarehouse' }
    & Pick<AlationDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & Pick<AzureSqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & Pick<AzureSynapseDataWarehouse, 'id'>
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & Pick<BigqueryDataWarehouse, 'id'>
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & Pick<DatabricksDataWarehouse, 'id'>
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & Pick<MysqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & Pick<OracleDataWarehouse, 'id'>
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & Pick<PostgresDataWarehouse, 'id'>
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & Pick<RedshiftDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & Pick<SqlServerDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & Pick<SnowflakeDataWarehouse, 'id'>
  ) }
);

export type DeleteDataWarehouseMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDataWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { deleteDataWarehouse: (
    { __typename?: 'AlationDataWarehouse' }
    & Pick<AlationDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & Pick<AzureSqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & Pick<AzureSynapseDataWarehouse, 'id'>
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & Pick<BigqueryDataWarehouse, 'id'>
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & Pick<DatabricksDataWarehouse, 'id'>
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & Pick<MysqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & Pick<OracleDataWarehouse, 'id'>
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & Pick<PostgresDataWarehouse, 'id'>
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & Pick<RedshiftDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & Pick<SqlServerDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & Pick<SnowflakeDataWarehouse, 'id'>
  ) }
);

export type CopyDataWarehouseMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CopyDataWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { copyDataWarehouse: (
    { __typename?: 'AlationDataWarehouse' }
    & Pick<AlationDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & Pick<AzureSqlDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & Pick<AzureSynapseDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & Pick<BigqueryDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & Pick<DatabricksDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & Pick<MysqlDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & Pick<OracleDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & Pick<PostgresDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & Pick<RedshiftDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & Pick<SqlServerDataWarehouse, 'id' | 'name'>
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & Pick<SnowflakeDataWarehouse, 'id' | 'name'>
  ) }
);

export type TestWarehouseConnectionMutationVariables = Exact<{
  input: DataWarehouseInput;
}>;


export type TestWarehouseConnectionMutation = (
  { __typename?: 'Mutation' }
  & { dataWarehouseStatus: (
    { __typename?: 'DataWarehouseStatus' }
    & Pick<DataWarehouseStatus, 'connected' | 'errorMessage'>
  ) }
);

export type GetDefaultDataWarehouseMutationVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultDataWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { warehouse: (
    { __typename?: 'AlationDataWarehouse' }
    & Pick<AlationDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & Pick<AzureSqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & Pick<AzureSynapseDataWarehouse, 'id'>
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & Pick<BigqueryDataWarehouse, 'id'>
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & Pick<DatabricksDataWarehouse, 'id'>
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & Pick<MysqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & Pick<OracleDataWarehouse, 'id'>
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & Pick<PostgresDataWarehouse, 'id'>
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & Pick<RedshiftDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & Pick<SqlServerDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & Pick<SnowflakeDataWarehouse, 'id'>
  ) }
);

export type ForceSyncDataSourceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ForceSyncDataSourceMutation = (
  { __typename?: 'Mutation' }
  & { source: (
    { __typename?: 'AirtableDataSource' }
    & Pick<AirtableDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & Pick<AmplitudeAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & Pick<BraintreeAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & Pick<DoubleClickDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'DropboxDataSource' }
    & Pick<DropboxDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & Pick<FacebookAdsAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'FacebookDataSource' }
    & Pick<FacebookDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & Pick<FacebookPagesAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'GenericDataSource' }
    & Pick<GenericDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & Pick<GoogleAdsAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & Pick<GoogleAnalyticsAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & Pick<GoogleSheetsDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & Pick<HubspotAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & Pick<IntercomAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & Pick<JiraAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & Pick<MicrosoftAdsAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'MssqlDataSource' }
    & Pick<MssqlDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'MysqlDataSource' }
    & Pick<MysqlDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & Pick<OutreachAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & Pick<PaypalAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'PostgresDataSource' }
    & Pick<PostgresDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'S3AbDataSource' }
    & Pick<S3AbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'S3DataSource' }
    & Pick<S3DataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & Pick<SalesforceAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & Pick<ShopifyAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & Pick<StripeAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & Pick<ZendeskChatAbDataSource, 'id'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )> }
  ) }
);

export type CreateDataSourceMutationVariables = Exact<{
  input: DataSourceInput;
}>;


export type CreateDataSourceMutation = (
  { __typename?: 'Mutation' }
  & { source: (
    { __typename?: 'AirtableDataSource' }
    & Pick<AirtableDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & Pick<AmplitudeAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & Pick<BraintreeAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & Pick<DoubleClickDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'DropboxDataSource' }
    & Pick<DropboxDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & Pick<FacebookAdsAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'FacebookDataSource' }
    & Pick<FacebookDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & Pick<FacebookPagesAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'GenericDataSource' }
    & Pick<GenericDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & Pick<GoogleAdsAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & Pick<GoogleAnalyticsAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & Pick<GoogleSheetsDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & Pick<HubspotAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & Pick<IntercomAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & Pick<JiraAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & Pick<MicrosoftAdsAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'MssqlDataSource' }
    & Pick<MssqlDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'MysqlDataSource' }
    & Pick<MysqlDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & Pick<OutreachAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & Pick<PaypalAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'PostgresDataSource' }
    & Pick<PostgresDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'S3AbDataSource' }
    & Pick<S3AbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'S3DataSource' }
    & Pick<S3DataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & Pick<SalesforceAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & Pick<ShopifyAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & Pick<StripeAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & Pick<ZendeskChatAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace'>
    )>> }
  ) }
);

export type UpdateDataSourceMutationVariables = Exact<{
  input: DataSourceUpdateInput;
}>;


export type UpdateDataSourceMutation = (
  { __typename?: 'Mutation' }
  & { source: (
    { __typename?: 'AirtableDataSource' }
    & Pick<AirtableDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & Pick<AmplitudeAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & Pick<BraintreeAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & Pick<DoubleClickDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'DropboxDataSource' }
    & Pick<DropboxDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & Pick<FacebookAdsAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'FacebookDataSource' }
    & Pick<FacebookDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & Pick<FacebookPagesAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GenericDataSource' }
    & Pick<GenericDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & Pick<GoogleAdsAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & Pick<GoogleAnalyticsAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & Pick<GoogleSheetsDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & Pick<HubspotAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & Pick<IntercomAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & Pick<JiraAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & Pick<MicrosoftAdsAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'MssqlDataSource' }
    & Pick<MssqlDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'MysqlDataSource' }
    & Pick<MysqlDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & Pick<OutreachAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & Pick<PaypalAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'PostgresDataSource' }
    & Pick<PostgresDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'S3AbDataSource' }
    & Pick<S3AbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'S3DataSource' }
    & Pick<S3DataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & Pick<SalesforceAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & Pick<ShopifyAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & Pick<StripeAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & Pick<ZendeskChatAbDataSource, 'id' | 'syncFrequency'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>>, status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) }
);

export type PauseDataSourceMutationVariables = Exact<{
  id: Scalars['Int'];
  pause: Scalars['Boolean'];
}>;


export type PauseDataSourceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pauseDataSource'>
);

export type DeleteDataSourceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDataSourceMutation = (
  { __typename?: 'Mutation' }
  & { deleteDataSource: (
    { __typename?: 'AirtableDataSource' }
    & Pick<AirtableDataSource, 'id'>
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & Pick<AmplitudeAbDataSource, 'id'>
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & Pick<BraintreeAbDataSource, 'id'>
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & Pick<DoubleClickDataSource, 'id'>
  ) | (
    { __typename?: 'DropboxDataSource' }
    & Pick<DropboxDataSource, 'id'>
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & Pick<FacebookAdsAbDataSource, 'id'>
  ) | (
    { __typename?: 'FacebookDataSource' }
    & Pick<FacebookDataSource, 'id'>
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & Pick<FacebookPagesAbDataSource, 'id'>
  ) | (
    { __typename?: 'GenericDataSource' }
    & Pick<GenericDataSource, 'id'>
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & Pick<GoogleAdsAbDataSource, 'id'>
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & Pick<GoogleAnalyticsAbDataSource, 'id'>
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & Pick<GoogleSheetsDataSource, 'id'>
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & Pick<HubspotAbDataSource, 'id'>
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & Pick<IntercomAbDataSource, 'id'>
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & Pick<JiraAbDataSource, 'id'>
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & Pick<MicrosoftAdsAbDataSource, 'id'>
  ) | (
    { __typename?: 'MssqlDataSource' }
    & Pick<MssqlDataSource, 'id'>
  ) | (
    { __typename?: 'MysqlDataSource' }
    & Pick<MysqlDataSource, 'id'>
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & Pick<OutreachAbDataSource, 'id'>
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & Pick<PaypalAbDataSource, 'id'>
  ) | (
    { __typename?: 'PostgresDataSource' }
    & Pick<PostgresDataSource, 'id'>
  ) | (
    { __typename?: 'S3AbDataSource' }
    & Pick<S3AbDataSource, 'id'>
  ) | (
    { __typename?: 'S3DataSource' }
    & Pick<S3DataSource, 'id'>
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & Pick<SalesforceAbDataSource, 'id'>
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & Pick<ShopifyAbDataSource, 'id'>
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & Pick<StripeAbDataSource, 'id'>
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & Pick<ZendeskChatAbDataSource, 'id'>
  ) }
);

export type CreateDataSetMutationVariables = Exact<{
  input: DataSetCreateInput;
}>;


export type CreateDataSetMutation = (
  { __typename?: 'Mutation' }
  & { createDataSet: (
    { __typename?: 'DataSet' }
    & Pick<DataSet, 'id'>
  ) }
);

export type RunDataSetMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RunDataSetMutation = (
  { __typename?: 'Mutation' }
  & { runDataSet: (
    { __typename?: 'DataSetRun' }
    & Pick<DataSetRun, 'awsJobId'>
  ) }
);

export type AcceptInvitationMutationVariables = Exact<{
  input: AcceptInviteInput;
}>;


export type AcceptInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptUserInvitation: (
    { __typename?: 'LoggedInUser' }
    & { user: (
      { __typename?: 'User' }
      & UserInfoFragment
    ) }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: AdminUpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { adminUpdateUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isAdmin' | 'email' | 'firstName' | 'lastName'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUser'>
);

export type UninviteUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UninviteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uninviteUser'>
);

export type InviteUsersMutationVariables = Exact<{
  input: Array<UserInvitationInput> | UserInvitationInput;
}>;


export type InviteUsersMutation = (
  { __typename?: 'Mutation' }
  & { inviteUsers: Array<(
    { __typename?: 'UserInvitation' }
    & Pick<UserInvitation, 'id' | 'email' | 'isAdmin'>
  )> }
);

export type CreateReportMutationVariables = Exact<{
  input: ReportInput;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'name' | 'id'>
  ) }
);

export type CreateDestinationMutationVariables = Exact<{
  input: DestinationInput;
}>;


export type CreateDestinationMutation = (
  { __typename?: 'Mutation' }
  & { createDestination: (
    { __typename?: 'EmailDestination' }
    & Pick<EmailDestination, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'username'>
    ) }
  ) | (
    { __typename?: 'ExcelDestination' }
    & Pick<ExcelDestination, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'username'>
    ) }
  ) | (
    { __typename?: 'GSheetDestination' }
    & Pick<GSheetDestination, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'username'>
    ) }
  ) | (
    { __typename?: 'SlackDestination' }
    & Pick<SlackDestination, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'username'>
    ) }
  ) }
);

export type DeleteDestinationMutationVariables = Exact<{
  input: Scalars['Int'];
}>;


export type DeleteDestinationMutation = (
  { __typename?: 'Mutation' }
  & { deleteDestination: (
    { __typename?: 'EmailDestination' }
    & Pick<EmailDestination, 'name'>
  ) | (
    { __typename?: 'ExcelDestination' }
    & Pick<ExcelDestination, 'name'>
  ) | (
    { __typename?: 'GSheetDestination' }
    & Pick<GSheetDestination, 'name'>
  ) | (
    { __typename?: 'SlackDestination' }
    & Pick<SlackDestination, 'name'>
  ) }
);

export type CreateScheduleMutationVariables = Exact<{
  input: ScheduleInput;
}>;


export type CreateScheduleMutation = (
  { __typename?: 'Mutation' }
  & { createSchedule: (
    { __typename?: 'Schedule' }
    & Pick<Schedule, 'name'>
  ) }
);

export type UpdateScheduleMutationVariables = Exact<{
  input: ScheduleUpdateInput;
}>;


export type UpdateScheduleMutation = (
  { __typename?: 'Mutation' }
  & { updateSchedule: (
    { __typename?: 'Schedule' }
    & Pick<Schedule, 'id' | 'name' | 'status' | 'cronStr' | 'timeZone' | 'notifyOn' | 'nextRunAt'>
    & { report: (
      { __typename?: 'Report' }
      & Pick<Report, 'name'>
    ) }
  ) }
);

export type RunScheduleMutationVariables = Exact<{
  input: Scalars['Int'];
}>;


export type RunScheduleMutation = (
  { __typename?: 'Mutation' }
  & { runSchedule: (
    { __typename?: 'ScheduleRun' }
    & Pick<ScheduleRun, 'runId' | 'startTime' | 'endTime' | 'status' | 'errorMessage'>
  ) }
);

export type DeleteScheduleMutationVariables = Exact<{
  input: Scalars['Int'];
}>;


export type DeleteScheduleMutation = (
  { __typename?: 'Mutation' }
  & { deleteSchedule: (
    { __typename?: 'Schedule' }
    & Pick<Schedule, 'id' | 'name'>
  ) }
);

export type UpdateReportMutationVariables = Exact<{
  input: UpdateReportInput;
}>;


export type UpdateReportMutation = (
  { __typename?: 'Mutation' }
  & { updateReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'name' | 'id'>
  ) }
);

export type DeleteReportMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & { deleteReport: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'name'>
  )> }
);

export type CreateSqlReportMutationVariables = Exact<{
  input: SqlReportInput;
}>;


export type CreateSqlReportMutation = (
  { __typename?: 'Mutation' }
  & { createSQLReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'name' | 'id'>
  ) }
);

export type UpdateSqlReportMutationVariables = Exact<{
  input: UpdateSqlReportInput;
}>;


export type UpdateSqlReportMutation = (
  { __typename?: 'Mutation' }
  & { updateSQLReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'name' | 'id'>
  ) }
);

export type SwitchPlanMutationVariables = Exact<{
  accountId: Scalars['Int'];
  plan: PlanType;
}>;


export type SwitchPlanMutation = (
  { __typename?: 'Mutation' }
  & { switchPlan: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'companyName'>
    & { plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'name'>
    ) }
  ) }
);

export type SetSuperAdminMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
}>;


export type SetSuperAdminMutation = (
  { __typename?: 'Mutation' }
  & { setSuperAdmin: (
    { __typename?: 'Account' }
    & Pick<Account, 'superAdmin'>
  ) }
);

export type ShareReportMutationVariables = Exact<{
  input: ShareInput;
}>;


export type ShareReportMutation = (
  { __typename?: 'Mutation' }
  & { share: Array<(
    { __typename?: 'Share' }
    & Pick<Share, 'id'>
  )> }
);

export type UnshareReportMutationVariables = Exact<{
  input: ShareInput;
}>;


export type UnshareReportMutation = (
  { __typename?: 'Mutation' }
  & { unshare: Array<(
    { __typename?: 'Share' }
    & Pick<Share, 'id'>
  )> }
);

export type CreateTeamMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam: (
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
  ) }
);

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateTeam: (
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
  ) }
);

export type AddMembersMutationVariables = Exact<{
  id: Scalars['Int'];
  members: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddMembersMutation = (
  { __typename?: 'Mutation' }
  & { member: Mutation['addMembers'] }
);

export type RemoveMembersMutationVariables = Exact<{
  id: Scalars['Int'];
  members: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveMembersMutation = (
  { __typename?: 'Mutation' }
  & { member: Mutation['removeMembers'] }
);

export type DeleteTeamMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTeamMutation = (
  { __typename?: 'Mutation' }
  & { deleted: Mutation['deleteTeam'] }
);

export type ShareWithEmailMutationVariables = Exact<{
  input: EmailShareInput;
}>;


export type ShareWithEmailMutation = (
  { __typename?: 'Mutation' }
  & { shareWithEmail: Array<(
    { __typename?: 'Share' }
    & Pick<Share, 'id'>
  )> }
);

export type UpgradePlanMutationVariables = Exact<{
  clientSecret: Scalars['String'];
  subscriptionId: Scalars['String'];
}>;


export type UpgradePlanMutation = (
  { __typename?: 'Mutation' }
  & { upgradePlan: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name'>
    ) }
  ) }
);

export type SubscribePlanMutationVariables = Exact<{
  planId: Scalars['String'];
  address: BillingAddress;
}>;


export type SubscribePlanMutation = (
  { __typename?: 'Mutation' }
  & { subscribePlan?: Maybe<(
    { __typename?: 'PlanSubscription' }
    & Pick<PlanSubscription, 'customerId' | 'subscriptionId' | 'clientSecret'>
  )> }
);

export type UpdatePaymentDetailsMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;


export type UpdatePaymentDetailsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePayment'>
);

export type CloneReportMutationVariables = Exact<{
  id: Scalars['Int'];
  dwId: Scalars['Int'];
  name: Scalars['String'];
}>;


export type CloneReportMutation = (
  { __typename?: 'Mutation' }
  & { cloneReport: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'name'>
  ) }
);

export type OverridePlanLimitsMutationVariables = Exact<{
  accountId: Scalars['Int'];
  planOverride?: Maybe<PlanOverrideInput>;
}>;


export type OverridePlanLimitsMutation = (
  { __typename?: 'Mutation' }
  & { overridePlanLimit?: Maybe<(
    { __typename?: 'PlanOverride' }
    & Pick<PlanOverride, 'id'>
  )> }
);

export type UpdateShareAccessLevelMutationVariables = Exact<{
  input: UpdatedShareHolderInput;
}>;


export type UpdateShareAccessLevelMutation = (
  { __typename?: 'Mutation' }
  & { updateShareAccess: Array<(
    { __typename?: 'Share' }
    & Pick<Share, 'id'>
  )> }
);

export type GetDataSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDataSourcesQuery = (
  { __typename?: 'Query' }
  & { dataSources: Array<(
    { __typename?: 'AirtableDataSource' }
    & Pick<AirtableDataSource, 'apiKey' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & Pick<AmplitudeAbDataSource, 'startDate' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & Pick<BraintreeAbDataSource, 'startDate' | 'environment' | 'merchantId' | 'publicKey' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & Pick<DoubleClickDataSource, 'table' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'DropboxDataSource' }
    & Pick<DropboxDataSource, 'table' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & Pick<FacebookAdsAbDataSource, 'startDate' | 'accountId' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'FacebookDataSource' }
    & Pick<FacebookDataSource, 'table' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & Pick<FacebookPagesAbDataSource, 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'GenericDataSource' }
    & Pick<GenericDataSource, 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & Pick<GoogleAdsAbDataSource, 'startDate' | 'customerIds' | 'loginCustomerId' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & Pick<GoogleAnalyticsAbDataSource, 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & Pick<GoogleSheetsDataSource, 'table' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & Pick<HubspotAbDataSource, 'startDate' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & Pick<IntercomAbDataSource, 'startDate' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & Pick<JiraAbDataSource, 'startDate' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & Pick<MicrosoftAdsAbDataSource, 'startDate' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'MssqlDataSource' }
    & Pick<MssqlDataSource, 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'MysqlDataSource' }
    & Pick<MysqlDataSource, 'host' | 'port' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & Pick<OutreachAbDataSource, 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & Pick<PaypalAbDataSource, 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'PostgresDataSource' }
    & Pick<PostgresDataSource, 'host' | 'port' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'S3AbDataSource' }
    & Pick<S3AbDataSource, 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'S3DataSource' }
    & Pick<S3DataSource, 'table' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & Pick<SalesforceAbDataSource, 'startDate' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & Pick<ShopifyAbDataSource, 'shop' | 'startDate' | 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & Pick<StripeAbDataSource, 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & Pick<ZendeskChatAbDataSource, 'id' | 'name' | 'schema' | 'type' | 'provider' | 'syncFrequency'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'syncState' | 'setupState'>
    )>, dataWarehouse: (
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    ), owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  )>, me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ), myAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { stats: (
      { __typename?: 'AccountStats' }
      & Pick<AccountStats, 'dataSourceCount'>
    ), plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'dataSourceLimit'>
    ) }
  ) }
);

export type GetSourceSchemaQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSourceSchemaQuery = (
  { __typename?: 'Query' }
  & { dataSource: (
    { __typename?: 'AirtableDataSource' }
    & Pick<AirtableDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & Pick<AmplitudeAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & Pick<BraintreeAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & Pick<DoubleClickDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'DropboxDataSource' }
    & Pick<DropboxDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & Pick<FacebookAdsAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'FacebookDataSource' }
    & Pick<FacebookDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & Pick<FacebookPagesAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'GenericDataSource' }
    & Pick<GenericDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & Pick<GoogleAdsAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & Pick<GoogleAnalyticsAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & Pick<GoogleSheetsDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & Pick<HubspotAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & Pick<IntercomAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & Pick<JiraAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & Pick<MicrosoftAdsAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'MssqlDataSource' }
    & Pick<MssqlDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'MysqlDataSource' }
    & Pick<MysqlDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & Pick<OutreachAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & Pick<PaypalAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'PostgresDataSource' }
    & Pick<PostgresDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'S3AbDataSource' }
    & Pick<S3AbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'S3DataSource' }
    & Pick<S3DataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & Pick<SalesforceAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & Pick<ShopifyAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & Pick<StripeAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & Pick<ZendeskChatAbDataSource, 'id'>
    & { sourceSchema?: Maybe<Array<(
      { __typename?: 'SourceSchema' }
      & Pick<SourceSchema, 'name' | 'namespace' | 'selected'>
    )>> }
  ) }
);

export type GetUserNameQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserNameQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'lastName'>
  ) }
);

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & UserInfoFragment
  ) }
);

export type GetUserOnboardingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserOnboardingQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { onboardingState?: Maybe<(
      { __typename?: 'OnboardingState' }
      & Pick<OnboardingState, 'currentStep' | 'answers' | 'completed'>
    )> }
  ) }
);

export type GetSourceTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSourceTypesQuery = (
  { __typename?: 'Query' }
  & { sources: Array<(
    { __typename?: 'DataSourceTypeInfo' }
    & Pick<DataSourceTypeInfo, 'id' | 'name' | 'iconURL'>
  )> }
);

export type GetSourceStatusQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSourceStatusQuery = (
  { __typename?: 'Query' }
  & { dataSource: (
    { __typename?: 'AirtableDataSource' }
    & Pick<AirtableDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & Pick<AmplitudeAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & Pick<BraintreeAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & Pick<DoubleClickDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'DropboxDataSource' }
    & Pick<DropboxDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & Pick<FacebookAdsAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'FacebookDataSource' }
    & Pick<FacebookDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & Pick<FacebookPagesAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GenericDataSource' }
    & Pick<GenericDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & Pick<GoogleAdsAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & Pick<GoogleAnalyticsAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & Pick<GoogleSheetsDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & Pick<HubspotAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & Pick<IntercomAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & Pick<JiraAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & Pick<MicrosoftAdsAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'MssqlDataSource' }
    & Pick<MssqlDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'MysqlDataSource' }
    & Pick<MysqlDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & Pick<OutreachAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & Pick<PaypalAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'PostgresDataSource' }
    & Pick<PostgresDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'S3AbDataSource' }
    & Pick<S3AbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'S3DataSource' }
    & Pick<S3DataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & Pick<SalesforceAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & Pick<ShopifyAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & Pick<StripeAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & Pick<ZendeskChatAbDataSource, 'id' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'setupState' | 'syncState'>
    )> }
  ) }
);

export type GetWarehousesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWarehousesQuery = (
  { __typename?: 'Query' }
  & { dataWarehouses: Array<(
    { __typename?: 'AlationDataWarehouse' }
    & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ) }
  )>, me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ), myAccount: (
    { __typename?: 'Account' }
    & { stats: (
      { __typename?: 'AccountStats' }
      & Pick<AccountStats, 'dataWarehouseCount'>
    ), plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'dataWarehouseLimit'>
    ) }
  ) }
);

export type GetWarehouseQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetWarehouseQuery = (
  { __typename?: 'Query' }
  & { warehouse: (
    { __typename?: 'AlationDataWarehouse' }
    & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & Pick<BigqueryDataWarehouse, 'projectId' | 'id' | 'name' | 'type'>
    & { projects: Array<(
      { __typename?: 'BigqueryProject' }
      & Pick<BigqueryProject, 'id' | 'name'>
    )> }
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & Pick<MysqlDataWarehouse, 'host' | 'database' | 'port' | 'id' | 'name' | 'type'>
    & { ssh?: Maybe<(
      { __typename?: 'SSH' }
      & Pick<Ssh, 'port' | 'host' | 'username'>
    )> }
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & Pick<PostgresDataWarehouse, 'host' | 'database' | 'port' | 'id' | 'name' | 'type'>
    & { ssh?: Maybe<(
      { __typename?: 'SSH' }
      & Pick<Ssh, 'port' | 'host' | 'username'>
    )> }
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & Pick<RedshiftDataWarehouse, 'host' | 'database' | 'port' | 'id' | 'name' | 'type'>
    & { ssh?: Maybe<(
      { __typename?: 'SSH' }
      & Pick<Ssh, 'port' | 'host' | 'username'>
    )> }
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & Pick<SnowflakeDataWarehouse, 'host' | 'database' | 'port' | 'warehouse' | 'role' | 'id' | 'name' | 'type'>
  ) }
);

export type GetSourceTypesForRecipesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSourceTypesForRecipesQuery = (
  { __typename?: 'Query' }
  & { dataSources: Array<(
    { __typename?: 'AirtableDataSource' }
    & Pick<AirtableDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & Pick<AmplitudeAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & Pick<BraintreeAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & Pick<DoubleClickDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'DropboxDataSource' }
    & Pick<DropboxDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & Pick<FacebookAdsAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'FacebookDataSource' }
    & Pick<FacebookDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & Pick<FacebookPagesAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'GenericDataSource' }
    & Pick<GenericDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & Pick<GoogleAdsAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & Pick<GoogleAnalyticsAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & Pick<GoogleSheetsDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & Pick<HubspotAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & Pick<IntercomAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & Pick<JiraAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & Pick<MicrosoftAdsAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'MssqlDataSource' }
    & Pick<MssqlDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'MysqlDataSource' }
    & Pick<MysqlDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & Pick<OutreachAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & Pick<PaypalAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'PostgresDataSource' }
    & Pick<PostgresDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'S3AbDataSource' }
    & Pick<S3AbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'S3DataSource' }
    & Pick<S3DataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & Pick<SalesforceAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & Pick<ShopifyAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & Pick<StripeAbDataSource, 'id' | 'type' | 'name'>
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & Pick<ZendeskChatAbDataSource, 'id' | 'type' | 'name'>
  )> }
);

export type GetRecipesQueryVariables = Exact<{
  dataSourceType?: Maybe<Array<Maybe<DataSourceType>> | Maybe<DataSourceType>>;
}>;


export type GetRecipesQuery = (
  { __typename?: 'Query' }
  & { recipes: Array<(
    { __typename?: 'Recipe' }
    & Pick<Recipe, 'name' | 'displayName' | 'description' | 'dataSourceType'>
  )> }
);

export type GetDatasetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDatasetsQuery = (
  { __typename?: 'Query' }
  & { dataSets: Array<(
    { __typename?: 'DataSet' }
    & Pick<DataSet, 'id'>
    & { dataSource: (
      { __typename?: 'AirtableDataSource' }
      & Pick<AirtableDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'AmplitudeAbDataSource' }
      & Pick<AmplitudeAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'BraintreeAbDataSource' }
      & Pick<BraintreeAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'DoubleClickDataSource' }
      & Pick<DoubleClickDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'DropboxDataSource' }
      & Pick<DropboxDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'FacebookAdsAbDataSource' }
      & Pick<FacebookAdsAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'FacebookDataSource' }
      & Pick<FacebookDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'FacebookPagesAbDataSource' }
      & Pick<FacebookPagesAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'GenericDataSource' }
      & Pick<GenericDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'GoogleAdsAbDataSource' }
      & Pick<GoogleAdsAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'GoogleAnalyticsAbDataSource' }
      & Pick<GoogleAnalyticsAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'GoogleSheetsDataSource' }
      & Pick<GoogleSheetsDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'HubspotAbDataSource' }
      & Pick<HubspotAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'IntercomAbDataSource' }
      & Pick<IntercomAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'JiraAbDataSource' }
      & Pick<JiraAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'MicrosoftAdsAbDataSource' }
      & Pick<MicrosoftAdsAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'MssqlDataSource' }
      & Pick<MssqlDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'MysqlDataSource' }
      & Pick<MysqlDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'OutreachAbDataSource' }
      & Pick<OutreachAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'PaypalAbDataSource' }
      & Pick<PaypalAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'PostgresDataSource' }
      & Pick<PostgresDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'S3AbDataSource' }
      & Pick<S3AbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'S3DataSource' }
      & Pick<S3DataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'SalesforceAbDataSource' }
      & Pick<SalesforceAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'ShopifyAbDataSource' }
      & Pick<ShopifyAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'StripeAbDataSource' }
      & Pick<StripeAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ) | (
      { __typename?: 'ZendeskChatAbDataSource' }
      & Pick<ZendeskChatAbDataSource, 'id' | 'name' | 'type' | 'schema'>
      & { dataWarehouse: (
        { __typename?: 'AlationDataWarehouse' }
        & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSQLDataWarehouse' }
        & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'AzureSynapseDataWarehouse' }
        & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'BigqueryDataWarehouse' }
        & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'DatabricksDataWarehouse' }
        & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'MysqlDataWarehouse' }
        & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'OracleDataWarehouse' }
        & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'PostgresDataWarehouse' }
        & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'RedshiftDataWarehouse' }
        & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SQLServerDataWarehouse' }
        & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
      ) | (
        { __typename?: 'SnowflakeDataWarehouse' }
        & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
      ) }
    ), runs: Array<(
      { __typename?: 'DataSetRun' }
      & Pick<DataSetRun, 'awsJobStatus'>
    )>, recipe: (
      { __typename?: 'Recipe' }
      & Pick<Recipe, 'name' | 'displayName' | 'outputTable'>
    ) }
  )> }
);

export type GetDataSetQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDataSetQuery = (
  { __typename?: 'Query' }
  & { dataSet: (
    { __typename?: 'DataSet' }
    & Pick<DataSet, 'id'>
    & { runs: Array<(
      { __typename?: 'DataSetRun' }
      & Pick<DataSetRun, 'statusReason' | 'awsJobId' | 'awsJobStatus' | 'startTime' | 'endTime'>
    )> }
  ) }
);

export type GetDataSetLogsQueryVariables = Exact<{
  id: Scalars['Int'];
  runId: Scalars['String'];
}>;


export type GetDataSetLogsQuery = (
  { __typename?: 'Query' }
  & { dataSet: (
    { __typename?: 'DataSet' }
    & Pick<DataSet, 'id'>
    & { runs: Array<(
      { __typename?: 'DataSetRun' }
      & { logs: Array<Maybe<(
        { __typename?: 'Log' }
        & Pick<Log, 'timestamp' | 'message'>
      )>> }
    )> }
  ) }
);

export type GetConnectCardUrlQueryVariables = Exact<{
  input: Scalars['Int'];
}>;


export type GetConnectCardUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'connectCardUrl'>
);

export type GetLicenseLimitQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLicenseLimitQuery = (
  { __typename?: 'Query' }
  & { myAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { stats: (
      { __typename?: 'AccountStats' }
      & Pick<AccountStats, 'userCount' | 'adminCount'>
    ), plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'userLimit' | 'adminLimit'>
    ) }
  ) }
);

export type GetUserRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRoleQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isAdmin' | 'isSiteAdmin'>
  ) }
);

export type GetPlanQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlanQuery = (
  { __typename?: 'Query' }
  & { myAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'expiresAt'>
    & { plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name' | 'reportLimit' | 'dataSourceLimit' | 'dataWarehouseLimit' | 'userLimit' | 'adminLimit' | 'scheduleLimit'>
      & { upgradableTo: Array<(
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'reportLimit' | 'dataWarehouseLimit' | 'dataSourceLimit' | 'userLimit' | 'adminLimit' | 'description'>
      )> }
    ) }
  ) }
);

export type GetMyAccountUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyAccountUsersQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { account: (
      { __typename?: 'Account' }
      & { users: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'isAdmin' | 'email' | 'firstName' | 'lastName' | 'lastLogin' | 'createdAt'>
      )> }
    ) }
  ) }
);

export type GetAllAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAccountsQuery = (
  { __typename?: 'Query' }
  & { accounts: Array<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'companyName' | 'expiresAt'>
    & { overrideLimit?: Maybe<(
      { __typename?: 'PlanOverride' }
      & Pick<PlanOverride, 'id' | 'accountId' | 'reportLimit' | 'dataWarehouseLimit' | 'dataSourceLimit' | 'userLimit' | 'adminLimit' | 'scheduleLimit'>
    )>, plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name'>
    ), users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'isAdmin' | 'email' | 'firstName' | 'lastName' | 'isSiteAdmin'>
    )> }
  )> }
);

export type GetInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvitationsQuery = (
  { __typename?: 'Query' }
  & { pendingUserInvitations: Array<(
    { __typename?: 'UserInvitation' }
    & Pick<UserInvitation, 'id' | 'email' | 'isAdmin'>
  )> }
);

export type GetWarehousesReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWarehousesReportsQuery = (
  { __typename?: 'Query' }
  & { dataWarehouses: Array<(
    { __typename?: 'AlationDataWarehouse' }
    & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
  )> }
);

export type PreviewReportQueryVariables = Exact<{
  input: ReportInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  reportId?: Maybe<Scalars['Int']>;
}>;


export type PreviewReportQuery = (
  { __typename?: 'Query' }
  & { runReport: (
    { __typename?: 'ReportRun' }
    & Pick<ReportRun, 'sql' | 'count'>
    & { grid: (
      { __typename?: 'Grid' }
      & Pick<Grid, 'rows'>
      & { columns: Array<(
        { __typename?: 'GridColumn' }
        & Pick<GridColumn, 'name' | 'table' | 'schema' | 'label'>
      )> }
    ) }
  ) }
);

export type BuilderReportCountQueryVariables = Exact<{
  input: ReportInput;
}>;


export type BuilderReportCountQuery = (
  { __typename?: 'Query' }
  & { runReport: (
    { __typename?: 'ReportRun' }
    & Pick<ReportRun, 'count'>
  ) }
);

export type SqlReportCountQueryVariables = Exact<{
  input: SqlReportInput;
}>;


export type SqlReportCountQuery = (
  { __typename?: 'Query' }
  & { runSQLReport: (
    { __typename?: 'ReportRun' }
    & Pick<ReportRun, 'count'>
  ) }
);

export type ViewReportQueryVariables = Exact<{
  id: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type ViewReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & { run: (
      { __typename?: 'ReportRun' }
      & Pick<ReportRun, 'sql' | 'count'>
      & { grid: (
        { __typename?: 'Grid' }
        & Pick<Grid, 'rows'>
        & { columns: Array<(
          { __typename?: 'GridColumn' }
          & Pick<GridColumn, 'name' | 'table' | 'schema'>
        )> }
      ) }
    ) }
  ) }
);

export type PreviewReportSqlQueryVariables = Exact<{
  input: SqlReportInput;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  reportId?: Maybe<Scalars['Int']>;
}>;


export type PreviewReportSqlQuery = (
  { __typename?: 'Query' }
  & { runSQLReport: (
    { __typename?: 'ReportRun' }
    & Pick<ReportRun, 'sql' | 'count'>
    & { grid: (
      { __typename?: 'Grid' }
      & Pick<Grid, 'rows'>
      & { columns: Array<(
        { __typename?: 'GridColumn' }
        & Pick<GridColumn, 'name' | 'table' | 'schema'>
      )> }
    ) }
  ) }
);

export type GetSchemasQueryVariables = Exact<{
  id: Scalars['Int'];
  reportId?: Maybe<Scalars['Int']>;
}>;


export type GetSchemasQuery = (
  { __typename?: 'Query' }
  & { dataWarehouse: (
    { __typename?: 'AlationDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
      )> }
    )> }
  ) }
);

export type GetColumnsQueryVariables = Exact<{
  id: Scalars['Int'];
  schema: Scalars['String'];
  table: Scalars['String'];
  reportId?: Maybe<Scalars['Int']>;
}>;


export type GetColumnsQuery = (
  { __typename?: 'Query' }
  & { dataWarehouse: (
    { __typename?: 'AlationDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) }
);

export type GetUserInvitationQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetUserInvitationQuery = (
  { __typename?: 'Query' }
  & { userInvitation: (
    { __typename?: 'UserInvitationInfo' }
    & Pick<UserInvitationInfo, 'alreadyRegistered' | 'recipientEmail' | 'inviterEmail' | 'companyName'>
  ) }
);

export type GetReportsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReportsListQuery = (
  { __typename?: 'Query' }
  & { reports: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'name' | 'accessLevel' | 'updatedAt' | 'tags'>
    & { dataWarehouse?: Maybe<(
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'name' | 'id'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'name' | 'id'>
    )>, owner: (
      { __typename?: 'User' }
      & Pick<User, 'email' | 'id'>
    ), sharedWith: (
      { __typename?: 'SharedWith' }
      & { users: Array<(
        { __typename?: 'ShareholderUser' }
        & Pick<ShareholderUser, 'accessLevel'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'username' | 'id' | 'firstName' | 'lastName' | 'email'>
        ) }
      )>, teams: Array<(
        { __typename?: 'ShareholderTeam' }
        & Pick<ShareholderTeam, 'accessLevel'>
        & { team: (
          { __typename?: 'Team' }
          & Pick<Team, 'id' | 'name'>
        ) }
      )>, emails: Array<(
        { __typename?: 'ShareholderEmail' }
        & Pick<ShareholderEmail, 'accessLevel'>
        & { email: (
          { __typename?: 'Email' }
          & Pick<Email, 'id' | 'emailAddress'>
        ) }
      )> }
    ), definition: { __typename: 'ReportSQL' } | { __typename: 'ReportTable' } }
  )>, myAccount: (
    { __typename?: 'Account' }
    & { stats: (
      { __typename?: 'AccountStats' }
      & Pick<AccountStats, 'reportCount'>
    ), plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'reportLimit'>
    ) }
  ), dataWarehouses: Array<(
    { __typename?: 'AlationDataWarehouse' }
    & Pick<AlationDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & Pick<AzureSqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & Pick<AzureSynapseDataWarehouse, 'id'>
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & Pick<BigqueryDataWarehouse, 'id'>
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & Pick<DatabricksDataWarehouse, 'id'>
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & Pick<MysqlDataWarehouse, 'id'>
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & Pick<OracleDataWarehouse, 'id'>
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & Pick<PostgresDataWarehouse, 'id'>
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & Pick<RedshiftDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & Pick<SqlServerDataWarehouse, 'id'>
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & Pick<SnowflakeDataWarehouse, 'id'>
  )> }
);

export type ListSchedulesQueryVariables = Exact<{
  filter?: Maybe<ScheduleFilterInput>;
}>;


export type ListSchedulesQuery = (
  { __typename?: 'Query' }
  & { schedules: Array<(
    { __typename?: 'Schedule' }
    & Pick<Schedule, 'id' | 'name' | 'cronStr' | 'timeZone' | 'notifyOn' | 'status' | 'nextRunAt'>
    & { report: (
      { __typename?: 'Report' }
      & Pick<Report, 'name' | 'id'>
    ), destination: (
      { __typename?: 'EmailDestination' }
      & Pick<EmailDestination, 'type'>
    ) | (
      { __typename?: 'ExcelDestination' }
      & Pick<ExcelDestination, 'type'>
    ) | (
      { __typename?: 'GSheetDestination' }
      & Pick<GSheetDestination, 'type'>
    ) | (
      { __typename?: 'SlackDestination' }
      & Pick<SlackDestination, 'type'>
    ), destinationConfig?: Maybe<{ __typename?: 'ExcelDestinationConfig' } | (
      { __typename?: 'GSheetsDestinationConfig' }
      & Pick<GSheetsDestinationConfig, 'spreadsheetId' | 'sheetId'>
    ) | { __typename?: 'SlackDestinationConfig' }>, reportTemplate?: Maybe<Array<(
      { __typename?: 'ReportTemplateVar' }
      & Pick<ReportTemplateVar, 'name' | 'value'>
    )>>, latestRun?: Maybe<(
      { __typename?: 'ScheduleRun' }
      & Pick<ScheduleRun, 'endTime' | 'status'>
    )>, owner: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )>, me: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ), myAccount: (
    { __typename?: 'Account' }
    & { plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'scheduleLimit'>
    ) }
  ) }
);

export type ListScheduledReportsQueryVariables = Exact<{
  filter?: Maybe<ScheduleFilterInput>;
}>;


export type ListScheduledReportsQuery = (
  { __typename?: 'Query' }
  & { schedules: Array<(
    { __typename?: 'Schedule' }
    & { report: (
      { __typename?: 'Report' }
      & Pick<Report, 'id'>
    ) }
  )> }
);

export type GetScheduleRunStatusListQueryVariables = Exact<{
  id: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetScheduleRunStatusListQuery = (
  { __typename?: 'Query' }
  & { schedule: (
    { __typename?: 'Schedule' }
    & Pick<Schedule, 'nextRunAt'>
    & { latestRun?: Maybe<(
      { __typename?: 'ScheduleRun' }
      & Pick<ScheduleRun, 'status' | 'errorMessage' | 'startTime' | 'endTime'>
    )>, runs?: Maybe<Array<Maybe<(
      { __typename?: 'ScheduleRun' }
      & Pick<ScheduleRun, 'runId' | 'status' | 'errorMessage' | 'startTime' | 'endTime'>
    )>>> }
  ) }
);

export type ListAllReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAllReportsQuery = (
  { __typename?: 'Query' }
  & { reports: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'name'>
  )> }
);

export type GetDestinationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDestinationsQuery = (
  { __typename?: 'Query' }
  & { destinations: Array<(
    { __typename?: 'EmailDestination' }
    & Pick<EmailDestination, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'username'>
    ) }
  ) | (
    { __typename?: 'ExcelDestination' }
    & Pick<ExcelDestination, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'username'>
    ) }
  ) | (
    { __typename?: 'GSheetDestination' }
    & Pick<GSheetDestination, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'username'>
    ) }
  ) | (
    { __typename?: 'SlackDestination' }
    & Pick<SlackDestination, 'id' | 'name' | 'type'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'username'>
    ) }
  )> }
);

export type GetSpreadsheetsQueryVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
}>;


export type GetSpreadsheetsQuery = (
  { __typename?: 'Query' }
  & { destination: (
    { __typename: 'EmailDestination' }
    & Pick<EmailDestination, 'email' | 'id' | 'type' | 'name'>
  ) | (
    { __typename: 'ExcelDestination' }
    & Pick<ExcelDestination, 'id' | 'type' | 'name'>
  ) | (
    { __typename: 'GSheetDestination' }
    & Pick<GSheetDestination, 'id' | 'type' | 'name'>
    & { spreadsheets: Array<(
      { __typename?: 'GSheetSpreadsheet' }
      & Pick<GSheetSpreadsheet, 'id' | 'name'>
    )> }
  ) | (
    { __typename: 'SlackDestination' }
    & Pick<SlackDestination, 'id' | 'type' | 'name'>
    & { channels: Array<(
      { __typename?: 'SlackChannel' }
      & Pick<SlackChannel, 'id' | 'name'>
    )> }
  ) }
);

export type GetSheetIdsQueryVariables = Exact<{
  destinationId: Scalars['Int'];
  spreadSheetId: Scalars['String'];
}>;


export type GetSheetIdsQuery = (
  { __typename?: 'Query' }
  & { destination: (
    { __typename: 'EmailDestination' }
    & Pick<EmailDestination, 'id' | 'type' | 'name'>
  ) | (
    { __typename: 'ExcelDestination' }
    & Pick<ExcelDestination, 'id' | 'type' | 'name'>
  ) | (
    { __typename: 'GSheetDestination' }
    & Pick<GSheetDestination, 'id' | 'type' | 'name'>
    & { spreadsheets: Array<(
      { __typename?: 'GSheetSpreadsheet' }
      & { sheets: Array<(
        { __typename?: 'GSheetSheet' }
        & Pick<GSheetSheet, 'id' | 'name'>
      )> }
    )> }
  ) | (
    { __typename: 'SlackDestination' }
    & Pick<SlackDestination, 'id' | 'type' | 'name'>
  ) }
);

export type ConnectionsAndReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConnectionsAndReportsQuery = (
  { __typename?: 'Query' }
  & { dataSources: Array<(
    { __typename?: 'AirtableDataSource' }
    & Pick<AirtableDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & Pick<AmplitudeAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & Pick<BraintreeAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & Pick<DoubleClickDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'DropboxDataSource' }
    & Pick<DropboxDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & Pick<FacebookAdsAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'FacebookDataSource' }
    & Pick<FacebookDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & Pick<FacebookPagesAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GenericDataSource' }
    & Pick<GenericDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & Pick<GoogleAdsAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & Pick<GoogleAnalyticsAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & Pick<GoogleSheetsDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & Pick<HubspotAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & Pick<IntercomAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & Pick<JiraAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & Pick<MicrosoftAdsAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'MssqlDataSource' }
    & Pick<MssqlDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'MysqlDataSource' }
    & Pick<MysqlDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & Pick<OutreachAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & Pick<PaypalAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'PostgresDataSource' }
    & Pick<PostgresDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'S3AbDataSource' }
    & Pick<S3AbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'S3DataSource' }
    & Pick<S3DataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & Pick<SalesforceAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & Pick<ShopifyAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & Pick<StripeAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & Pick<ZendeskChatAbDataSource, 'id' | 'name' | 'type'>
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'lastRunAt' | 'setupState' | 'syncState'>
    )> }
  )>, reports: Array<(
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'name' | 'updatedAt'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ) }
  )> }
);

export type MultiSelectQueryVariables = Exact<{
  input: ReportInput;
  reportId?: Maybe<Scalars['Int']>;
}>;


export type MultiSelectQuery = (
  { __typename?: 'Query' }
  & { runReport: (
    { __typename?: 'ReportRun' }
    & Pick<ReportRun, 'sql' | 'count'>
    & { grid: (
      { __typename?: 'Grid' }
      & Pick<Grid, 'rows'>
      & { columns: Array<(
        { __typename?: 'GridColumn' }
        & Pick<GridColumn, 'name' | 'table' | 'schema'>
      )> }
    ) }
  ) }
);

export type GetReportDataQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetReportDataQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'name' | 'accessLevel'>
    & { dataWarehouse?: Maybe<(
      { __typename?: 'AlationDataWarehouse' }
      & Pick<AlationDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSQLDataWarehouse' }
      & Pick<AzureSqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'AzureSynapseDataWarehouse' }
      & Pick<AzureSynapseDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'BigqueryDataWarehouse' }
      & Pick<BigqueryDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'DatabricksDataWarehouse' }
      & Pick<DatabricksDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'MysqlDataWarehouse' }
      & Pick<MysqlDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'OracleDataWarehouse' }
      & Pick<OracleDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'PostgresDataWarehouse' }
      & Pick<PostgresDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'RedshiftDataWarehouse' }
      & Pick<RedshiftDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SQLServerDataWarehouse' }
      & Pick<SqlServerDataWarehouse, 'id' | 'name' | 'type'>
    ) | (
      { __typename?: 'SnowflakeDataWarehouse' }
      & Pick<SnowflakeDataWarehouse, 'id' | 'name' | 'type'>
    )>, owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    ), definition: (
      { __typename?: 'ReportSQL' }
      & Pick<ReportSql, 'query'>
      & { template?: Maybe<Array<(
        { __typename?: 'ReportTemplateVar' }
        & Pick<ReportTemplateVar, 'name' | 'value' | 'dataType' | 'inputType' | 'format' | 'multiSelectQuery'>
        & { multiSelectOptions?: Maybe<Array<(
          { __typename?: 'MultiSelectOption' }
          & Pick<MultiSelectOption, 'display' | 'value'>
        )>> }
      )>> }
    ) | (
      { __typename?: 'ReportTable' }
      & Pick<ReportTable, 'schema'>
      & { table: (
        { __typename?: 'QueryTable' }
        & Pick<QueryTable, 'name'>
        & { cols: Array<(
          { __typename?: 'QueryColumn' }
          & Pick<QueryColumn, 'name' | 'label'>
        )>, groupBy?: Maybe<Array<(
          { __typename?: 'QueryColumn' }
          & Pick<QueryColumn, 'name'>
        )>>, aggregations?: Maybe<Array<(
          { __typename?: 'QueryAggregation' }
          & Pick<QueryAggregation, 'func'>
          & { col: (
            { __typename?: 'QueryColumn' }
            & Pick<QueryColumn, 'name'>
          ) }
        )>> }
      ), conditions?: Maybe<Array<(
        { __typename?: 'QueryCondition' }
        & Pick<QueryCondition, 'op' | 'val'>
        & { col: (
          { __typename?: 'QueryColumn' }
          & Pick<QueryColumn, 'name' | 'label'>
        ) }
      )>>, filters?: Maybe<Array<(
        { __typename?: 'QueryFilter' }
        & Pick<QueryFilter, 'op' | 'vals'>
        & { col: (
          { __typename?: 'QueryColumn' }
          & Pick<QueryColumn, 'name' | 'label'>
        ) }
      )>>, joins?: Maybe<Array<(
        { __typename?: 'QueryJoin' }
        & Pick<QueryJoin, 'type'>
        & { join: (
          { __typename?: 'ReportTable' }
          & Pick<ReportTable, 'schema'>
          & { table: (
            { __typename?: 'QueryTable' }
            & Pick<QueryTable, 'name'>
            & { cols: Array<(
              { __typename?: 'QueryColumn' }
              & Pick<QueryColumn, 'name'>
            )>, groupBy?: Maybe<Array<(
              { __typename?: 'QueryColumn' }
              & Pick<QueryColumn, 'name'>
            )>>, aggregations?: Maybe<Array<(
              { __typename?: 'QueryAggregation' }
              & Pick<QueryAggregation, 'func'>
              & { col: (
                { __typename?: 'QueryColumn' }
                & Pick<QueryColumn, 'name'>
              ) }
            )>> }
          ), conditions?: Maybe<Array<(
            { __typename?: 'QueryCondition' }
            & Pick<QueryCondition, 'op' | 'val'>
            & { col: (
              { __typename?: 'QueryColumn' }
              & Pick<QueryColumn, 'name' | 'label'>
            ) }
          )>>, filters?: Maybe<Array<(
            { __typename?: 'QueryFilter' }
            & Pick<QueryFilter, 'op' | 'vals'>
            & { col: (
              { __typename?: 'QueryColumn' }
              & Pick<QueryColumn, 'name' | 'label'>
            ) }
          )>>, joins?: Maybe<Array<(
            { __typename?: 'QueryJoin' }
            & Pick<QueryJoin, 'type'>
            & { join: (
              { __typename?: 'ReportTable' }
              & Pick<ReportTable, 'schema'>
              & { table: (
                { __typename?: 'QueryTable' }
                & Pick<QueryTable, 'name'>
                & { cols: Array<(
                  { __typename?: 'QueryColumn' }
                  & Pick<QueryColumn, 'name'>
                )>, groupBy?: Maybe<Array<(
                  { __typename?: 'QueryColumn' }
                  & Pick<QueryColumn, 'name'>
                )>>, aggregations?: Maybe<Array<(
                  { __typename?: 'QueryAggregation' }
                  & Pick<QueryAggregation, 'func'>
                  & { col: (
                    { __typename?: 'QueryColumn' }
                    & Pick<QueryColumn, 'name'>
                  ) }
                )>> }
              ), conditions?: Maybe<Array<(
                { __typename?: 'QueryCondition' }
                & Pick<QueryCondition, 'op' | 'val'>
                & { col: (
                  { __typename?: 'QueryColumn' }
                  & Pick<QueryColumn, 'name' | 'label'>
                ) }
              )>>, filters?: Maybe<Array<(
                { __typename?: 'QueryFilter' }
                & Pick<QueryFilter, 'op' | 'vals'>
                & { col: (
                  { __typename?: 'QueryColumn' }
                  & Pick<QueryColumn, 'name' | 'label'>
                ) }
              )>>, joins?: Maybe<Array<(
                { __typename?: 'QueryJoin' }
                & Pick<QueryJoin, 'type'>
                & { join: (
                  { __typename?: 'ReportTable' }
                  & Pick<ReportTable, 'schema'>
                  & { table: (
                    { __typename?: 'QueryTable' }
                    & Pick<QueryTable, 'name'>
                    & { cols: Array<(
                      { __typename?: 'QueryColumn' }
                      & Pick<QueryColumn, 'name'>
                    )>, groupBy?: Maybe<Array<(
                      { __typename?: 'QueryColumn' }
                      & Pick<QueryColumn, 'name'>
                    )>>, aggregations?: Maybe<Array<(
                      { __typename?: 'QueryAggregation' }
                      & Pick<QueryAggregation, 'func'>
                      & { col: (
                        { __typename?: 'QueryColumn' }
                        & Pick<QueryColumn, 'name'>
                      ) }
                    )>> }
                  ), conditions?: Maybe<Array<(
                    { __typename?: 'QueryCondition' }
                    & Pick<QueryCondition, 'op' | 'val'>
                    & { col: (
                      { __typename?: 'QueryColumn' }
                      & Pick<QueryColumn, 'name' | 'label'>
                    ) }
                  )>>, filters?: Maybe<Array<(
                    { __typename?: 'QueryFilter' }
                    & Pick<QueryFilter, 'op' | 'vals'>
                    & { col: (
                      { __typename?: 'QueryColumn' }
                      & Pick<QueryColumn, 'name' | 'label'>
                    ) }
                  )>>, joins?: Maybe<Array<(
                    { __typename?: 'QueryJoin' }
                    & Pick<QueryJoin, 'type'>
                    & { join: (
                      { __typename?: 'ReportTable' }
                      & Pick<ReportTable, 'schema'>
                      & { table: (
                        { __typename?: 'QueryTable' }
                        & Pick<QueryTable, 'name'>
                        & { cols: Array<(
                          { __typename?: 'QueryColumn' }
                          & Pick<QueryColumn, 'name'>
                        )>, groupBy?: Maybe<Array<(
                          { __typename?: 'QueryColumn' }
                          & Pick<QueryColumn, 'name'>
                        )>>, aggregations?: Maybe<Array<(
                          { __typename?: 'QueryAggregation' }
                          & Pick<QueryAggregation, 'func'>
                          & { col: (
                            { __typename?: 'QueryColumn' }
                            & Pick<QueryColumn, 'name'>
                          ) }
                        )>> }
                      ), conditions?: Maybe<Array<(
                        { __typename?: 'QueryCondition' }
                        & Pick<QueryCondition, 'op' | 'val'>
                        & { col: (
                          { __typename?: 'QueryColumn' }
                          & Pick<QueryColumn, 'name' | 'label'>
                        ) }
                      )>>, filters?: Maybe<Array<(
                        { __typename?: 'QueryFilter' }
                        & Pick<QueryFilter, 'op' | 'vals'>
                        & { col: (
                          { __typename?: 'QueryColumn' }
                          & Pick<QueryColumn, 'name' | 'label'>
                        ) }
                      )>>, joins?: Maybe<Array<(
                        { __typename?: 'QueryJoin' }
                        & Pick<QueryJoin, 'type'>
                        & { on: Array<(
                          { __typename?: 'QueryJoinCondition' }
                          & Pick<QueryJoinCondition, 'op'>
                          & { parentCol: (
                            { __typename?: 'QueryColumn' }
                            & Pick<QueryColumn, 'name'>
                          ), childCol: (
                            { __typename?: 'QueryColumn' }
                            & Pick<QueryColumn, 'name'>
                          ) }
                        )> }
                      )>> }
                    ), on: Array<(
                      { __typename?: 'QueryJoinCondition' }
                      & Pick<QueryJoinCondition, 'op'>
                      & { parentCol: (
                        { __typename?: 'QueryColumn' }
                        & Pick<QueryColumn, 'name'>
                      ), childCol: (
                        { __typename?: 'QueryColumn' }
                        & Pick<QueryColumn, 'name'>
                      ) }
                    )> }
                  )>> }
                ), on: Array<(
                  { __typename?: 'QueryJoinCondition' }
                  & Pick<QueryJoinCondition, 'op'>
                  & { parentCol: (
                    { __typename?: 'QueryColumn' }
                    & Pick<QueryColumn, 'name'>
                  ), childCol: (
                    { __typename?: 'QueryColumn' }
                    & Pick<QueryColumn, 'name'>
                  ) }
                )> }
              )>> }
            ), on: Array<(
              { __typename?: 'QueryJoinCondition' }
              & Pick<QueryJoinCondition, 'op'>
              & { parentCol: (
                { __typename?: 'QueryColumn' }
                & Pick<QueryColumn, 'name'>
              ), childCol: (
                { __typename?: 'QueryColumn' }
                & Pick<QueryColumn, 'name'>
              ) }
            )> }
          )>> }
        ), on: Array<(
          { __typename?: 'QueryJoinCondition' }
          & Pick<QueryJoinCondition, 'op'>
          & { parentCol: (
            { __typename?: 'QueryColumn' }
            & Pick<QueryColumn, 'name'>
          ), childCol: (
            { __typename?: 'QueryColumn' }
            & Pick<QueryColumn, 'name'>
          ) }
        )> }
      )>> }
    ) }
  ) }
);

export type GetFilterColsQueryVariables = Exact<{
  id: Scalars['Int'];
  schemas?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  tables?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  reportId?: Maybe<Scalars['Int']>;
}>;


export type GetFilterColsQuery = (
  { __typename?: 'Query' }
  & { dataWarehouse: (
    { __typename?: 'AlationDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'AzureSQLDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'AzureSynapseDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'BigqueryDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'DatabricksDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'MysqlDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'OracleDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'PostgresDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'RedshiftDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'SQLServerDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) | (
    { __typename?: 'SnowflakeDataWarehouse' }
    & { schemas: Array<(
      { __typename?: 'Schema' }
      & Pick<Schema, 'name'>
      & { tables: Array<(
        { __typename?: 'Table' }
        & Pick<Table, 'name'>
        & { columns?: Maybe<Array<(
          { __typename?: 'Column' }
          & Pick<Column, 'name' | 'type'>
        )>> }
      )> }
    )> }
  ) }
);

export type PreviewReportRefetchQueryVariables = Exact<{
  input: ReportInput;
}>;


export type PreviewReportRefetchQuery = (
  { __typename?: 'Query' }
  & { runReport: (
    { __typename?: 'ReportRun' }
    & Pick<ReportRun, 'sql'>
  ) }
);

export type GetUserTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserTeamsQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { teams: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name' | 'description'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
      )> }
    )> }
  ) }
);

export type GetTeamsInAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamsInAccountQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
  ), teams: Array<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name' | 'description'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )>, owner: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    ) }
  )> }
);

export type GetAllPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPlansQuery = (
  { __typename?: 'Query' }
  & { plans: Array<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'description' | 'reportLimit' | 'dataWarehouseLimit' | 'dataSourceLimit' | 'userLimit' | 'adminLimit'>
    & { price?: Maybe<(
      { __typename?: 'PlanPrice' }
      & Pick<PlanPrice, 'monthly' | 'yearly'>
    )> }
  )>, myAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { subscription?: Maybe<(
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'billingCycle' | 'amount' | 'nextBillingDate'>
      & { card?: Maybe<(
        { __typename?: 'CreditCard' }
        & Pick<CreditCard, 'brand' | 'last4' | 'expiryMonth' | 'expiryYear'>
      )> }
    )> }
  ) }
);

export type GetDataSourceErrorLogsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDataSourceErrorLogsQuery = (
  { __typename?: 'Query' }
  & { dataSource: (
    { __typename?: 'AirtableDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'AmplitudeAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'BraintreeAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'DoubleClickDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'DropboxDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'FacebookAdsAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'FacebookDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'FacebookPagesAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'GenericDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'GoogleAdsAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'GoogleAnalyticsAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'GoogleSheetsDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'HubspotAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'IntercomAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'JiraAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'MicrosoftAdsAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'MssqlDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'MysqlDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'OutreachAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'PaypalAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'PostgresDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'S3AbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'S3DataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'SalesforceAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'ShopifyAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'StripeAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) | (
    { __typename?: 'ZendeskChatAbDataSource' }
    & { status?: Maybe<(
      { __typename?: 'DataSourceStatus' }
      & Pick<DataSourceStatus, 'syncErrorLogs'>
    )> }
  ) }
);

export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
  id
  firstName
  lastName
  isAdmin
  email
  intercomHash
  account {
    id
    stats {
      userCount
    }
    companyName
    expiresAt
    superAdmin
    plan {
      id
    }
  }
  onboardingState {
    answers
    currentStep
    completed
  }
}
    `;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  loggedInUser: login(input: $input) {
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginWithGoogleDocument = gql`
    mutation LoginWithGoogle($input: GoogleLoginInput!) {
  loggedInUser: loginWithGoogle(input: $input) {
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;
export type LoginWithGoogleMutationFn = Apollo.MutationFunction<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>;

/**
 * __useLoginWithGoogleMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleMutation, { data, loading, error }] = useLoginWithGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithGoogleMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>(LoginWithGoogleDocument, options);
      }
export type LoginWithGoogleMutationHookResult = ReturnType<typeof useLoginWithGoogleMutation>;
export type LoginWithGoogleMutationResult = Apollo.MutationResult<LoginWithGoogleMutation>;
export type LoginWithGoogleMutationOptions = Apollo.BaseMutationOptions<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>;
export const LoginWithAlationDocument = gql`
    mutation LoginWithAlation($input: AlationLoginInput!) {
  loggedInUser: loginWithAlation(input: $input) {
    token
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;
export type LoginWithAlationMutationFn = Apollo.MutationFunction<LoginWithAlationMutation, LoginWithAlationMutationVariables>;

/**
 * __useLoginWithAlationMutation__
 *
 * To run a mutation, you first call `useLoginWithAlationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithAlationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithAlationMutation, { data, loading, error }] = useLoginWithAlationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithAlationMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithAlationMutation, LoginWithAlationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithAlationMutation, LoginWithAlationMutationVariables>(LoginWithAlationDocument, options);
      }
export type LoginWithAlationMutationHookResult = ReturnType<typeof useLoginWithAlationMutation>;
export type LoginWithAlationMutationResult = Apollo.MutationResult<LoginWithAlationMutation>;
export type LoginWithAlationMutationOptions = Apollo.BaseMutationOptions<LoginWithAlationMutation, LoginWithAlationMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  success: signUp(input: $input)
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SignUpWithGoogleDocument = gql`
    mutation SignUpWithGoogle($input: GoogleSignUpInput!) {
  signUpWithGoogle(input: $input) {
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;
export type SignUpWithGoogleMutationFn = Apollo.MutationFunction<SignUpWithGoogleMutation, SignUpWithGoogleMutationVariables>;

/**
 * __useSignUpWithGoogleMutation__
 *
 * To run a mutation, you first call `useSignUpWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpWithGoogleMutation, { data, loading, error }] = useSignUpWithGoogleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpWithGoogleMutation(baseOptions?: Apollo.MutationHookOptions<SignUpWithGoogleMutation, SignUpWithGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpWithGoogleMutation, SignUpWithGoogleMutationVariables>(SignUpWithGoogleDocument, options);
      }
export type SignUpWithGoogleMutationHookResult = ReturnType<typeof useSignUpWithGoogleMutation>;
export type SignUpWithGoogleMutationResult = Apollo.MutationResult<SignUpWithGoogleMutation>;
export type SignUpWithGoogleMutationOptions = Apollo.BaseMutationOptions<SignUpWithGoogleMutation, SignUpWithGoogleMutationVariables>;
export const ResendActivationLinkDocument = gql`
    mutation ResendActivationLink($email: String!) {
  success: resendActivationLink(email: $email)
}
    `;
export type ResendActivationLinkMutationFn = Apollo.MutationFunction<ResendActivationLinkMutation, ResendActivationLinkMutationVariables>;

/**
 * __useResendActivationLinkMutation__
 *
 * To run a mutation, you first call `useResendActivationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationLinkMutation, { data, loading, error }] = useResendActivationLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendActivationLinkMutation(baseOptions?: Apollo.MutationHookOptions<ResendActivationLinkMutation, ResendActivationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendActivationLinkMutation, ResendActivationLinkMutationVariables>(ResendActivationLinkDocument, options);
      }
export type ResendActivationLinkMutationHookResult = ReturnType<typeof useResendActivationLinkMutation>;
export type ResendActivationLinkMutationResult = Apollo.MutationResult<ResendActivationLinkMutation>;
export type ResendActivationLinkMutationOptions = Apollo.BaseMutationOptions<ResendActivationLinkMutation, ResendActivationLinkMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(email: $email)
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateOnboardingDocument = gql`
    mutation UpdateOnboarding($input: UpdateUserInput!) {
  user: updateUser(input: $input) {
    id
    onboardingState {
      completed
      answers
      currentStep
    }
  }
}
    `;
export type UpdateOnboardingMutationFn = Apollo.MutationFunction<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>;

/**
 * __useUpdateOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingMutation, { data, loading, error }] = useUpdateOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>(UpdateOnboardingDocument, options);
      }
export type UpdateOnboardingMutationHookResult = ReturnType<typeof useUpdateOnboardingMutation>;
export type UpdateOnboardingMutationResult = Apollo.MutationResult<UpdateOnboardingMutation>;
export type UpdateOnboardingMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>;
export const CreateDataWarehouseDocument = gql`
    mutation CreateDataWarehouse($input: DataWarehouseInput!) {
  warehouse: createDataWarehouse(input: $input) {
    id
  }
}
    `;
export type CreateDataWarehouseMutationFn = Apollo.MutationFunction<CreateDataWarehouseMutation, CreateDataWarehouseMutationVariables>;

/**
 * __useCreateDataWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateDataWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataWarehouseMutation, { data, loading, error }] = useCreateDataWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataWarehouseMutation, CreateDataWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataWarehouseMutation, CreateDataWarehouseMutationVariables>(CreateDataWarehouseDocument, options);
      }
export type CreateDataWarehouseMutationHookResult = ReturnType<typeof useCreateDataWarehouseMutation>;
export type CreateDataWarehouseMutationResult = Apollo.MutationResult<CreateDataWarehouseMutation>;
export type CreateDataWarehouseMutationOptions = Apollo.BaseMutationOptions<CreateDataWarehouseMutation, CreateDataWarehouseMutationVariables>;
export const UpdateDataWarehouseDocument = gql`
    mutation UpdateDataWarehouse($input: DataWarehouseUpdateInput!) {
  warehouse: updateDataWarehouse(input: $input) {
    id
  }
}
    `;
export type UpdateDataWarehouseMutationFn = Apollo.MutationFunction<UpdateDataWarehouseMutation, UpdateDataWarehouseMutationVariables>;

/**
 * __useUpdateDataWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateDataWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataWarehouseMutation, { data, loading, error }] = useUpdateDataWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataWarehouseMutation, UpdateDataWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataWarehouseMutation, UpdateDataWarehouseMutationVariables>(UpdateDataWarehouseDocument, options);
      }
export type UpdateDataWarehouseMutationHookResult = ReturnType<typeof useUpdateDataWarehouseMutation>;
export type UpdateDataWarehouseMutationResult = Apollo.MutationResult<UpdateDataWarehouseMutation>;
export type UpdateDataWarehouseMutationOptions = Apollo.BaseMutationOptions<UpdateDataWarehouseMutation, UpdateDataWarehouseMutationVariables>;
export const DeleteDataWarehouseDocument = gql`
    mutation DeleteDataWarehouse($id: Int!) {
  deleteDataWarehouse(id: $id) {
    id
  }
}
    `;
export type DeleteDataWarehouseMutationFn = Apollo.MutationFunction<DeleteDataWarehouseMutation, DeleteDataWarehouseMutationVariables>;

/**
 * __useDeleteDataWarehouseMutation__
 *
 * To run a mutation, you first call `useDeleteDataWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataWarehouseMutation, { data, loading, error }] = useDeleteDataWarehouseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDataWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataWarehouseMutation, DeleteDataWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataWarehouseMutation, DeleteDataWarehouseMutationVariables>(DeleteDataWarehouseDocument, options);
      }
export type DeleteDataWarehouseMutationHookResult = ReturnType<typeof useDeleteDataWarehouseMutation>;
export type DeleteDataWarehouseMutationResult = Apollo.MutationResult<DeleteDataWarehouseMutation>;
export type DeleteDataWarehouseMutationOptions = Apollo.BaseMutationOptions<DeleteDataWarehouseMutation, DeleteDataWarehouseMutationVariables>;
export const CopyDataWarehouseDocument = gql`
    mutation CopyDataWarehouse($id: Int!) {
  copyDataWarehouse(id: $id) {
    id
    name
  }
}
    `;
export type CopyDataWarehouseMutationFn = Apollo.MutationFunction<CopyDataWarehouseMutation, CopyDataWarehouseMutationVariables>;

/**
 * __useCopyDataWarehouseMutation__
 *
 * To run a mutation, you first call `useCopyDataWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyDataWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyDataWarehouseMutation, { data, loading, error }] = useCopyDataWarehouseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyDataWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<CopyDataWarehouseMutation, CopyDataWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyDataWarehouseMutation, CopyDataWarehouseMutationVariables>(CopyDataWarehouseDocument, options);
      }
export type CopyDataWarehouseMutationHookResult = ReturnType<typeof useCopyDataWarehouseMutation>;
export type CopyDataWarehouseMutationResult = Apollo.MutationResult<CopyDataWarehouseMutation>;
export type CopyDataWarehouseMutationOptions = Apollo.BaseMutationOptions<CopyDataWarehouseMutation, CopyDataWarehouseMutationVariables>;
export const TestWarehouseConnectionDocument = gql`
    mutation TestWarehouseConnection($input: DataWarehouseInput!) {
  dataWarehouseStatus(input: $input) {
    connected
    errorMessage
  }
}
    `;
export type TestWarehouseConnectionMutationFn = Apollo.MutationFunction<TestWarehouseConnectionMutation, TestWarehouseConnectionMutationVariables>;

/**
 * __useTestWarehouseConnectionMutation__
 *
 * To run a mutation, you first call `useTestWarehouseConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestWarehouseConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testWarehouseConnectionMutation, { data, loading, error }] = useTestWarehouseConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestWarehouseConnectionMutation(baseOptions?: Apollo.MutationHookOptions<TestWarehouseConnectionMutation, TestWarehouseConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestWarehouseConnectionMutation, TestWarehouseConnectionMutationVariables>(TestWarehouseConnectionDocument, options);
      }
export type TestWarehouseConnectionMutationHookResult = ReturnType<typeof useTestWarehouseConnectionMutation>;
export type TestWarehouseConnectionMutationResult = Apollo.MutationResult<TestWarehouseConnectionMutation>;
export type TestWarehouseConnectionMutationOptions = Apollo.BaseMutationOptions<TestWarehouseConnectionMutation, TestWarehouseConnectionMutationVariables>;
export const GetDefaultDataWarehouseDocument = gql`
    mutation GetDefaultDataWarehouse {
  warehouse: getDefaultDataWarehouse {
    id
  }
}
    `;
export type GetDefaultDataWarehouseMutationFn = Apollo.MutationFunction<GetDefaultDataWarehouseMutation, GetDefaultDataWarehouseMutationVariables>;

/**
 * __useGetDefaultDataWarehouseMutation__
 *
 * To run a mutation, you first call `useGetDefaultDataWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultDataWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDefaultDataWarehouseMutation, { data, loading, error }] = useGetDefaultDataWarehouseMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultDataWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<GetDefaultDataWarehouseMutation, GetDefaultDataWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDefaultDataWarehouseMutation, GetDefaultDataWarehouseMutationVariables>(GetDefaultDataWarehouseDocument, options);
      }
export type GetDefaultDataWarehouseMutationHookResult = ReturnType<typeof useGetDefaultDataWarehouseMutation>;
export type GetDefaultDataWarehouseMutationResult = Apollo.MutationResult<GetDefaultDataWarehouseMutation>;
export type GetDefaultDataWarehouseMutationOptions = Apollo.BaseMutationOptions<GetDefaultDataWarehouseMutation, GetDefaultDataWarehouseMutationVariables>;
export const ForceSyncDataSourceDocument = gql`
    mutation ForceSyncDataSource($id: Int!) {
  source: forceSyncDataSource(id: $id) {
    id
    status {
      lastRunAt
      syncState
      setupState
    }
  }
}
    `;
export type ForceSyncDataSourceMutationFn = Apollo.MutationFunction<ForceSyncDataSourceMutation, ForceSyncDataSourceMutationVariables>;

/**
 * __useForceSyncDataSourceMutation__
 *
 * To run a mutation, you first call `useForceSyncDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceSyncDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceSyncDataSourceMutation, { data, loading, error }] = useForceSyncDataSourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForceSyncDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<ForceSyncDataSourceMutation, ForceSyncDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForceSyncDataSourceMutation, ForceSyncDataSourceMutationVariables>(ForceSyncDataSourceDocument, options);
      }
export type ForceSyncDataSourceMutationHookResult = ReturnType<typeof useForceSyncDataSourceMutation>;
export type ForceSyncDataSourceMutationResult = Apollo.MutationResult<ForceSyncDataSourceMutation>;
export type ForceSyncDataSourceMutationOptions = Apollo.BaseMutationOptions<ForceSyncDataSourceMutation, ForceSyncDataSourceMutationVariables>;
export const CreateDataSourceDocument = gql`
    mutation CreateDataSource($input: DataSourceInput!) {
  source: createDataSource(input: $input) {
    id
    sourceSchema {
      name
      namespace
    }
  }
}
    `;
export type CreateDataSourceMutationFn = Apollo.MutationFunction<CreateDataSourceMutation, CreateDataSourceMutationVariables>;

/**
 * __useCreateDataSourceMutation__
 *
 * To run a mutation, you first call `useCreateDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataSourceMutation, { data, loading, error }] = useCreateDataSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataSourceMutation, CreateDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataSourceMutation, CreateDataSourceMutationVariables>(CreateDataSourceDocument, options);
      }
export type CreateDataSourceMutationHookResult = ReturnType<typeof useCreateDataSourceMutation>;
export type CreateDataSourceMutationResult = Apollo.MutationResult<CreateDataSourceMutation>;
export type CreateDataSourceMutationOptions = Apollo.BaseMutationOptions<CreateDataSourceMutation, CreateDataSourceMutationVariables>;
export const UpdateDataSourceDocument = gql`
    mutation UpdateDataSource($input: DataSourceUpdateInput!) {
  source: updateDataSource(input: $input) {
    id
    syncFrequency
    sourceSchema {
      name
      namespace
      selected
    }
    status {
      setupState
      syncState
    }
  }
}
    `;
export type UpdateDataSourceMutationFn = Apollo.MutationFunction<UpdateDataSourceMutation, UpdateDataSourceMutationVariables>;

/**
 * __useUpdateDataSourceMutation__
 *
 * To run a mutation, you first call `useUpdateDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataSourceMutation, { data, loading, error }] = useUpdateDataSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataSourceMutation, UpdateDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataSourceMutation, UpdateDataSourceMutationVariables>(UpdateDataSourceDocument, options);
      }
export type UpdateDataSourceMutationHookResult = ReturnType<typeof useUpdateDataSourceMutation>;
export type UpdateDataSourceMutationResult = Apollo.MutationResult<UpdateDataSourceMutation>;
export type UpdateDataSourceMutationOptions = Apollo.BaseMutationOptions<UpdateDataSourceMutation, UpdateDataSourceMutationVariables>;
export const PauseDataSourceDocument = gql`
    mutation PauseDataSource($id: Int!, $pause: Boolean!) {
  pauseDataSource(id: $id, pause: $pause)
}
    `;
export type PauseDataSourceMutationFn = Apollo.MutationFunction<PauseDataSourceMutation, PauseDataSourceMutationVariables>;

/**
 * __usePauseDataSourceMutation__
 *
 * To run a mutation, you first call `usePauseDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseDataSourceMutation, { data, loading, error }] = usePauseDataSourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pause: // value for 'pause'
 *   },
 * });
 */
export function usePauseDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<PauseDataSourceMutation, PauseDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PauseDataSourceMutation, PauseDataSourceMutationVariables>(PauseDataSourceDocument, options);
      }
export type PauseDataSourceMutationHookResult = ReturnType<typeof usePauseDataSourceMutation>;
export type PauseDataSourceMutationResult = Apollo.MutationResult<PauseDataSourceMutation>;
export type PauseDataSourceMutationOptions = Apollo.BaseMutationOptions<PauseDataSourceMutation, PauseDataSourceMutationVariables>;
export const DeleteDataSourceDocument = gql`
    mutation DeleteDataSource($id: Int!) {
  deleteDataSource(id: $id) {
    id
  }
}
    `;
export type DeleteDataSourceMutationFn = Apollo.MutationFunction<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>;

/**
 * __useDeleteDataSourceMutation__
 *
 * To run a mutation, you first call `useDeleteDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataSourceMutation, { data, loading, error }] = useDeleteDataSourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>(DeleteDataSourceDocument, options);
      }
export type DeleteDataSourceMutationHookResult = ReturnType<typeof useDeleteDataSourceMutation>;
export type DeleteDataSourceMutationResult = Apollo.MutationResult<DeleteDataSourceMutation>;
export type DeleteDataSourceMutationOptions = Apollo.BaseMutationOptions<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>;
export const CreateDataSetDocument = gql`
    mutation CreateDataSet($input: DataSetCreateInput!) {
  createDataSet(input: $input) {
    id
  }
}
    `;
export type CreateDataSetMutationFn = Apollo.MutationFunction<CreateDataSetMutation, CreateDataSetMutationVariables>;

/**
 * __useCreateDataSetMutation__
 *
 * To run a mutation, you first call `useCreateDataSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataSetMutation, { data, loading, error }] = useCreateDataSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataSetMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataSetMutation, CreateDataSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataSetMutation, CreateDataSetMutationVariables>(CreateDataSetDocument, options);
      }
export type CreateDataSetMutationHookResult = ReturnType<typeof useCreateDataSetMutation>;
export type CreateDataSetMutationResult = Apollo.MutationResult<CreateDataSetMutation>;
export type CreateDataSetMutationOptions = Apollo.BaseMutationOptions<CreateDataSetMutation, CreateDataSetMutationVariables>;
export const RunDataSetDocument = gql`
    mutation RunDataSet($id: Int!) {
  runDataSet(id: $id) {
    awsJobId
  }
}
    `;
export type RunDataSetMutationFn = Apollo.MutationFunction<RunDataSetMutation, RunDataSetMutationVariables>;

/**
 * __useRunDataSetMutation__
 *
 * To run a mutation, you first call `useRunDataSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunDataSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runDataSetMutation, { data, loading, error }] = useRunDataSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunDataSetMutation(baseOptions?: Apollo.MutationHookOptions<RunDataSetMutation, RunDataSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunDataSetMutation, RunDataSetMutationVariables>(RunDataSetDocument, options);
      }
export type RunDataSetMutationHookResult = ReturnType<typeof useRunDataSetMutation>;
export type RunDataSetMutationResult = Apollo.MutationResult<RunDataSetMutation>;
export type RunDataSetMutationOptions = Apollo.BaseMutationOptions<RunDataSetMutation, RunDataSetMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($input: AcceptInviteInput!) {
  acceptUserInvitation(input: $input) {
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: AdminUpdateUserInput!) {
  adminUpdateUser(input: $input) {
    id
    isAdmin
    email
    firstName
    lastName
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: Int!) {
  removeUser(userId: $userId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UninviteUserDocument = gql`
    mutation UninviteUser($id: Int!) {
  uninviteUser(id: $id)
}
    `;
export type UninviteUserMutationFn = Apollo.MutationFunction<UninviteUserMutation, UninviteUserMutationVariables>;

/**
 * __useUninviteUserMutation__
 *
 * To run a mutation, you first call `useUninviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninviteUserMutation, { data, loading, error }] = useUninviteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUninviteUserMutation(baseOptions?: Apollo.MutationHookOptions<UninviteUserMutation, UninviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UninviteUserMutation, UninviteUserMutationVariables>(UninviteUserDocument, options);
      }
export type UninviteUserMutationHookResult = ReturnType<typeof useUninviteUserMutation>;
export type UninviteUserMutationResult = Apollo.MutationResult<UninviteUserMutation>;
export type UninviteUserMutationOptions = Apollo.BaseMutationOptions<UninviteUserMutation, UninviteUserMutationVariables>;
export const InviteUsersDocument = gql`
    mutation InviteUsers($input: [UserInvitationInput!]!) {
  inviteUsers(invitations: $input) {
    id
    email
    isAdmin
  }
}
    `;
export type InviteUsersMutationFn = Apollo.MutationFunction<InviteUsersMutation, InviteUsersMutationVariables>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUsersMutation(baseOptions?: Apollo.MutationHookOptions<InviteUsersMutation, InviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument, options);
      }
export type InviteUsersMutationHookResult = ReturnType<typeof useInviteUsersMutation>;
export type InviteUsersMutationResult = Apollo.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<InviteUsersMutation, InviteUsersMutationVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($input: ReportInput!) {
  createReport(input: $input) {
    name
    id
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const CreateDestinationDocument = gql`
    mutation CreateDestination($input: DestinationInput!) {
  createDestination(input: $input) {
    id
    name
    type
    owner {
      username
    }
  }
}
    `;
export type CreateDestinationMutationFn = Apollo.MutationFunction<CreateDestinationMutation, CreateDestinationMutationVariables>;

/**
 * __useCreateDestinationMutation__
 *
 * To run a mutation, you first call `useCreateDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDestinationMutation, { data, loading, error }] = useCreateDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDestinationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDestinationMutation, CreateDestinationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDestinationMutation, CreateDestinationMutationVariables>(CreateDestinationDocument, options);
      }
export type CreateDestinationMutationHookResult = ReturnType<typeof useCreateDestinationMutation>;
export type CreateDestinationMutationResult = Apollo.MutationResult<CreateDestinationMutation>;
export type CreateDestinationMutationOptions = Apollo.BaseMutationOptions<CreateDestinationMutation, CreateDestinationMutationVariables>;
export const DeleteDestinationDocument = gql`
    mutation DeleteDestination($input: Int!) {
  deleteDestination(id: $input) {
    name
  }
}
    `;
export type DeleteDestinationMutationFn = Apollo.MutationFunction<DeleteDestinationMutation, DeleteDestinationMutationVariables>;

/**
 * __useDeleteDestinationMutation__
 *
 * To run a mutation, you first call `useDeleteDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDestinationMutation, { data, loading, error }] = useDeleteDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDestinationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDestinationMutation, DeleteDestinationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDestinationMutation, DeleteDestinationMutationVariables>(DeleteDestinationDocument, options);
      }
export type DeleteDestinationMutationHookResult = ReturnType<typeof useDeleteDestinationMutation>;
export type DeleteDestinationMutationResult = Apollo.MutationResult<DeleteDestinationMutation>;
export type DeleteDestinationMutationOptions = Apollo.BaseMutationOptions<DeleteDestinationMutation, DeleteDestinationMutationVariables>;
export const CreateScheduleDocument = gql`
    mutation CreateSchedule($input: ScheduleInput!) {
  createSchedule(input: $input) {
    name
  }
}
    `;
export type CreateScheduleMutationFn = Apollo.MutationFunction<CreateScheduleMutation, CreateScheduleMutationVariables>;

/**
 * __useCreateScheduleMutation__
 *
 * To run a mutation, you first call `useCreateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleMutation, { data, loading, error }] = useCreateScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduleMutation, CreateScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduleMutation, CreateScheduleMutationVariables>(CreateScheduleDocument, options);
      }
export type CreateScheduleMutationHookResult = ReturnType<typeof useCreateScheduleMutation>;
export type CreateScheduleMutationResult = Apollo.MutationResult<CreateScheduleMutation>;
export type CreateScheduleMutationOptions = Apollo.BaseMutationOptions<CreateScheduleMutation, CreateScheduleMutationVariables>;
export const UpdateScheduleDocument = gql`
    mutation UpdateSchedule($input: ScheduleUpdateInput!) {
  updateSchedule(input: $input) {
    id
    name
    status
    report {
      name
    }
    cronStr
    timeZone
    notifyOn
    nextRunAt
  }
}
    `;
export type UpdateScheduleMutationFn = Apollo.MutationFunction<UpdateScheduleMutation, UpdateScheduleMutationVariables>;

/**
 * __useUpdateScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleMutation, { data, loading, error }] = useUpdateScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduleMutation, UpdateScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduleMutation, UpdateScheduleMutationVariables>(UpdateScheduleDocument, options);
      }
export type UpdateScheduleMutationHookResult = ReturnType<typeof useUpdateScheduleMutation>;
export type UpdateScheduleMutationResult = Apollo.MutationResult<UpdateScheduleMutation>;
export type UpdateScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateScheduleMutation, UpdateScheduleMutationVariables>;
export const RunScheduleDocument = gql`
    mutation RunSchedule($input: Int!) {
  runSchedule(id: $input) {
    runId
    startTime
    endTime
    status
    errorMessage
  }
}
    `;
export type RunScheduleMutationFn = Apollo.MutationFunction<RunScheduleMutation, RunScheduleMutationVariables>;

/**
 * __useRunScheduleMutation__
 *
 * To run a mutation, you first call `useRunScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runScheduleMutation, { data, loading, error }] = useRunScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunScheduleMutation(baseOptions?: Apollo.MutationHookOptions<RunScheduleMutation, RunScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunScheduleMutation, RunScheduleMutationVariables>(RunScheduleDocument, options);
      }
export type RunScheduleMutationHookResult = ReturnType<typeof useRunScheduleMutation>;
export type RunScheduleMutationResult = Apollo.MutationResult<RunScheduleMutation>;
export type RunScheduleMutationOptions = Apollo.BaseMutationOptions<RunScheduleMutation, RunScheduleMutationVariables>;
export const DeleteScheduleDocument = gql`
    mutation DeleteSchedule($input: Int!) {
  deleteSchedule(id: $input) {
    id
    name
  }
}
    `;
export type DeleteScheduleMutationFn = Apollo.MutationFunction<DeleteScheduleMutation, DeleteScheduleMutationVariables>;

/**
 * __useDeleteScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleMutation, { data, loading, error }] = useDeleteScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScheduleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduleMutation, DeleteScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduleMutation, DeleteScheduleMutationVariables>(DeleteScheduleDocument, options);
      }
export type DeleteScheduleMutationHookResult = ReturnType<typeof useDeleteScheduleMutation>;
export type DeleteScheduleMutationResult = Apollo.MutationResult<DeleteScheduleMutation>;
export type DeleteScheduleMutationOptions = Apollo.BaseMutationOptions<DeleteScheduleMutation, DeleteScheduleMutationVariables>;
export const UpdateReportDocument = gql`
    mutation UpdateReport($input: UpdateReportInput!) {
  updateReport(input: $input) {
    name
    id
  }
}
    `;
export type UpdateReportMutationFn = Apollo.MutationFunction<UpdateReportMutation, UpdateReportMutationVariables>;

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportMutation, { data, loading, error }] = useUpdateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportMutation, UpdateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UpdateReportDocument, options);
      }
export type UpdateReportMutationHookResult = ReturnType<typeof useUpdateReportMutation>;
export type UpdateReportMutationResult = Apollo.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($ids: [Int!]!) {
  deleteReport(ids: $ids) {
    name
  }
}
    `;
export type DeleteReportMutationFn = Apollo.MutationFunction<DeleteReportMutation, DeleteReportMutationVariables>;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportMutation, { data, loading, error }] = useDeleteReportMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportMutation, DeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportMutation, DeleteReportMutationVariables>(DeleteReportDocument, options);
      }
export type DeleteReportMutationHookResult = ReturnType<typeof useDeleteReportMutation>;
export type DeleteReportMutationResult = Apollo.MutationResult<DeleteReportMutation>;
export type DeleteReportMutationOptions = Apollo.BaseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>;
export const CreateSqlReportDocument = gql`
    mutation CreateSqlReport($input: SQLReportInput!) {
  createSQLReport(input: $input) {
    name
    id
  }
}
    `;
export type CreateSqlReportMutationFn = Apollo.MutationFunction<CreateSqlReportMutation, CreateSqlReportMutationVariables>;

/**
 * __useCreateSqlReportMutation__
 *
 * To run a mutation, you first call `useCreateSqlReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSqlReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSqlReportMutation, { data, loading, error }] = useCreateSqlReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSqlReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateSqlReportMutation, CreateSqlReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSqlReportMutation, CreateSqlReportMutationVariables>(CreateSqlReportDocument, options);
      }
export type CreateSqlReportMutationHookResult = ReturnType<typeof useCreateSqlReportMutation>;
export type CreateSqlReportMutationResult = Apollo.MutationResult<CreateSqlReportMutation>;
export type CreateSqlReportMutationOptions = Apollo.BaseMutationOptions<CreateSqlReportMutation, CreateSqlReportMutationVariables>;
export const UpdateSqlReportDocument = gql`
    mutation UpdateSqlReport($input: UpdateSQLReportInput!) {
  updateSQLReport(input: $input) {
    name
    id
  }
}
    `;
export type UpdateSqlReportMutationFn = Apollo.MutationFunction<UpdateSqlReportMutation, UpdateSqlReportMutationVariables>;

/**
 * __useUpdateSqlReportMutation__
 *
 * To run a mutation, you first call `useUpdateSqlReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSqlReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSqlReportMutation, { data, loading, error }] = useUpdateSqlReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSqlReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSqlReportMutation, UpdateSqlReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSqlReportMutation, UpdateSqlReportMutationVariables>(UpdateSqlReportDocument, options);
      }
export type UpdateSqlReportMutationHookResult = ReturnType<typeof useUpdateSqlReportMutation>;
export type UpdateSqlReportMutationResult = Apollo.MutationResult<UpdateSqlReportMutation>;
export type UpdateSqlReportMutationOptions = Apollo.BaseMutationOptions<UpdateSqlReportMutation, UpdateSqlReportMutationVariables>;
export const SwitchPlanDocument = gql`
    mutation switchPlan($accountId: Int!, $plan: PlanType!) {
  switchPlan(accountId: $accountId, planType: $plan) {
    id
    companyName
    plan {
      name
    }
  }
}
    `;
export type SwitchPlanMutationFn = Apollo.MutationFunction<SwitchPlanMutation, SwitchPlanMutationVariables>;

/**
 * __useSwitchPlanMutation__
 *
 * To run a mutation, you first call `useSwitchPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchPlanMutation, { data, loading, error }] = useSwitchPlanMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function useSwitchPlanMutation(baseOptions?: Apollo.MutationHookOptions<SwitchPlanMutation, SwitchPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchPlanMutation, SwitchPlanMutationVariables>(SwitchPlanDocument, options);
      }
export type SwitchPlanMutationHookResult = ReturnType<typeof useSwitchPlanMutation>;
export type SwitchPlanMutationResult = Apollo.MutationResult<SwitchPlanMutation>;
export type SwitchPlanMutationOptions = Apollo.BaseMutationOptions<SwitchPlanMutation, SwitchPlanMutationVariables>;
export const SetSuperAdminDocument = gql`
    mutation setSuperAdmin($enabled: Boolean!) {
  setSuperAdmin(enabled: $enabled) {
    superAdmin
  }
}
    `;
export type SetSuperAdminMutationFn = Apollo.MutationFunction<SetSuperAdminMutation, SetSuperAdminMutationVariables>;

/**
 * __useSetSuperAdminMutation__
 *
 * To run a mutation, you first call `useSetSuperAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSuperAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSuperAdminMutation, { data, loading, error }] = useSetSuperAdminMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetSuperAdminMutation(baseOptions?: Apollo.MutationHookOptions<SetSuperAdminMutation, SetSuperAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSuperAdminMutation, SetSuperAdminMutationVariables>(SetSuperAdminDocument, options);
      }
export type SetSuperAdminMutationHookResult = ReturnType<typeof useSetSuperAdminMutation>;
export type SetSuperAdminMutationResult = Apollo.MutationResult<SetSuperAdminMutation>;
export type SetSuperAdminMutationOptions = Apollo.BaseMutationOptions<SetSuperAdminMutation, SetSuperAdminMutationVariables>;
export const ShareReportDocument = gql`
    mutation shareReport($input: ShareInput!) {
  share(input: $input) {
    id
  }
}
    `;
export type ShareReportMutationFn = Apollo.MutationFunction<ShareReportMutation, ShareReportMutationVariables>;

/**
 * __useShareReportMutation__
 *
 * To run a mutation, you first call `useShareReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareReportMutation, { data, loading, error }] = useShareReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareReportMutation(baseOptions?: Apollo.MutationHookOptions<ShareReportMutation, ShareReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareReportMutation, ShareReportMutationVariables>(ShareReportDocument, options);
      }
export type ShareReportMutationHookResult = ReturnType<typeof useShareReportMutation>;
export type ShareReportMutationResult = Apollo.MutationResult<ShareReportMutation>;
export type ShareReportMutationOptions = Apollo.BaseMutationOptions<ShareReportMutation, ShareReportMutationVariables>;
export const UnshareReportDocument = gql`
    mutation unshareReport($input: ShareInput!) {
  unshare(input: $input) {
    id
  }
}
    `;
export type UnshareReportMutationFn = Apollo.MutationFunction<UnshareReportMutation, UnshareReportMutationVariables>;

/**
 * __useUnshareReportMutation__
 *
 * To run a mutation, you first call `useUnshareReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnshareReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unshareReportMutation, { data, loading, error }] = useUnshareReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnshareReportMutation(baseOptions?: Apollo.MutationHookOptions<UnshareReportMutation, UnshareReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnshareReportMutation, UnshareReportMutationVariables>(UnshareReportDocument, options);
      }
export type UnshareReportMutationHookResult = ReturnType<typeof useUnshareReportMutation>;
export type UnshareReportMutationResult = Apollo.MutationResult<UnshareReportMutation>;
export type UnshareReportMutationOptions = Apollo.BaseMutationOptions<UnshareReportMutation, UnshareReportMutationVariables>;
export const CreateTeamDocument = gql`
    mutation createTeam($name: String!, $description: String!) {
  createTeam(name: $name, description: $description) {
    id
    name
  }
}
    `;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation updateTeam($id: Int!, $name: String, $description: String) {
  updateTeam(id: $id, name: $name, description: $description) {
    id
    name
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const AddMembersDocument = gql`
    mutation addMembers($id: Int!, $members: [Int!]!) {
  member: addMembers(id: $id, members: $members)
}
    `;
export type AddMembersMutationFn = Apollo.MutationFunction<AddMembersMutation, AddMembersMutationVariables>;

/**
 * __useAddMembersMutation__
 *
 * To run a mutation, you first call `useAddMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembersMutation, { data, loading, error }] = useAddMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      members: // value for 'members'
 *   },
 * });
 */
export function useAddMembersMutation(baseOptions?: Apollo.MutationHookOptions<AddMembersMutation, AddMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMembersMutation, AddMembersMutationVariables>(AddMembersDocument, options);
      }
export type AddMembersMutationHookResult = ReturnType<typeof useAddMembersMutation>;
export type AddMembersMutationResult = Apollo.MutationResult<AddMembersMutation>;
export type AddMembersMutationOptions = Apollo.BaseMutationOptions<AddMembersMutation, AddMembersMutationVariables>;
export const RemoveMembersDocument = gql`
    mutation removeMembers($id: Int!, $members: [Int!]!) {
  member: removeMembers(id: $id, members: $members)
}
    `;
export type RemoveMembersMutationFn = Apollo.MutationFunction<RemoveMembersMutation, RemoveMembersMutationVariables>;

/**
 * __useRemoveMembersMutation__
 *
 * To run a mutation, you first call `useRemoveMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMembersMutation, { data, loading, error }] = useRemoveMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      members: // value for 'members'
 *   },
 * });
 */
export function useRemoveMembersMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMembersMutation, RemoveMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMembersMutation, RemoveMembersMutationVariables>(RemoveMembersDocument, options);
      }
export type RemoveMembersMutationHookResult = ReturnType<typeof useRemoveMembersMutation>;
export type RemoveMembersMutationResult = Apollo.MutationResult<RemoveMembersMutation>;
export type RemoveMembersMutationOptions = Apollo.BaseMutationOptions<RemoveMembersMutation, RemoveMembersMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation deleteTeam($id: Int!) {
  deleted: deleteTeam(id: $id)
}
    `;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, options);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const ShareWithEmailDocument = gql`
    mutation shareWithEmail($input: EmailShareInput!) {
  shareWithEmail(input: $input) {
    id
  }
}
    `;
export type ShareWithEmailMutationFn = Apollo.MutationFunction<ShareWithEmailMutation, ShareWithEmailMutationVariables>;

/**
 * __useShareWithEmailMutation__
 *
 * To run a mutation, you first call `useShareWithEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareWithEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareWithEmailMutation, { data, loading, error }] = useShareWithEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareWithEmailMutation(baseOptions?: Apollo.MutationHookOptions<ShareWithEmailMutation, ShareWithEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareWithEmailMutation, ShareWithEmailMutationVariables>(ShareWithEmailDocument, options);
      }
export type ShareWithEmailMutationHookResult = ReturnType<typeof useShareWithEmailMutation>;
export type ShareWithEmailMutationResult = Apollo.MutationResult<ShareWithEmailMutation>;
export type ShareWithEmailMutationOptions = Apollo.BaseMutationOptions<ShareWithEmailMutation, ShareWithEmailMutationVariables>;
export const UpgradePlanDocument = gql`
    mutation upgradePlan($clientSecret: String!, $subscriptionId: String!) {
  upgradePlan(clientSecret: $clientSecret, subscriptionId: $subscriptionId) {
    id
    plan {
      id
      name
    }
  }
}
    `;
export type UpgradePlanMutationFn = Apollo.MutationFunction<UpgradePlanMutation, UpgradePlanMutationVariables>;

/**
 * __useUpgradePlanMutation__
 *
 * To run a mutation, you first call `useUpgradePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradePlanMutation, { data, loading, error }] = useUpgradePlanMutation({
 *   variables: {
 *      clientSecret: // value for 'clientSecret'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useUpgradePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpgradePlanMutation, UpgradePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpgradePlanMutation, UpgradePlanMutationVariables>(UpgradePlanDocument, options);
      }
export type UpgradePlanMutationHookResult = ReturnType<typeof useUpgradePlanMutation>;
export type UpgradePlanMutationResult = Apollo.MutationResult<UpgradePlanMutation>;
export type UpgradePlanMutationOptions = Apollo.BaseMutationOptions<UpgradePlanMutation, UpgradePlanMutationVariables>;
export const SubscribePlanDocument = gql`
    mutation SubscribePlan($planId: String!, $address: BillingAddress!) {
  subscribePlan(planId: $planId, address: $address) {
    customerId
    subscriptionId
    clientSecret
  }
}
    `;
export type SubscribePlanMutationFn = Apollo.MutationFunction<SubscribePlanMutation, SubscribePlanMutationVariables>;

/**
 * __useSubscribePlanMutation__
 *
 * To run a mutation, you first call `useSubscribePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribePlanMutation, { data, loading, error }] = useSubscribePlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useSubscribePlanMutation(baseOptions?: Apollo.MutationHookOptions<SubscribePlanMutation, SubscribePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribePlanMutation, SubscribePlanMutationVariables>(SubscribePlanDocument, options);
      }
export type SubscribePlanMutationHookResult = ReturnType<typeof useSubscribePlanMutation>;
export type SubscribePlanMutationResult = Apollo.MutationResult<SubscribePlanMutation>;
export type SubscribePlanMutationOptions = Apollo.BaseMutationOptions<SubscribePlanMutation, SubscribePlanMutationVariables>;
export const UpdatePaymentDetailsDocument = gql`
    mutation updatePaymentDetails($paymentMethodId: String!) {
  updatePayment(paymentMethodId: $paymentMethodId)
}
    `;
export type UpdatePaymentDetailsMutationFn = Apollo.MutationFunction<UpdatePaymentDetailsMutation, UpdatePaymentDetailsMutationVariables>;

/**
 * __useUpdatePaymentDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentDetailsMutation, { data, loading, error }] = useUpdatePaymentDetailsMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdatePaymentDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentDetailsMutation, UpdatePaymentDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentDetailsMutation, UpdatePaymentDetailsMutationVariables>(UpdatePaymentDetailsDocument, options);
      }
export type UpdatePaymentDetailsMutationHookResult = ReturnType<typeof useUpdatePaymentDetailsMutation>;
export type UpdatePaymentDetailsMutationResult = Apollo.MutationResult<UpdatePaymentDetailsMutation>;
export type UpdatePaymentDetailsMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentDetailsMutation, UpdatePaymentDetailsMutationVariables>;
export const CloneReportDocument = gql`
    mutation cloneReport($id: Int!, $dwId: Int!, $name: String!) {
  cloneReport(id: $id, dwId: $dwId, name: $name) {
    id
    name
  }
}
    `;
export type CloneReportMutationFn = Apollo.MutationFunction<CloneReportMutation, CloneReportMutationVariables>;

/**
 * __useCloneReportMutation__
 *
 * To run a mutation, you first call `useCloneReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneReportMutation, { data, loading, error }] = useCloneReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dwId: // value for 'dwId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCloneReportMutation(baseOptions?: Apollo.MutationHookOptions<CloneReportMutation, CloneReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneReportMutation, CloneReportMutationVariables>(CloneReportDocument, options);
      }
export type CloneReportMutationHookResult = ReturnType<typeof useCloneReportMutation>;
export type CloneReportMutationResult = Apollo.MutationResult<CloneReportMutation>;
export type CloneReportMutationOptions = Apollo.BaseMutationOptions<CloneReportMutation, CloneReportMutationVariables>;
export const OverridePlanLimitsDocument = gql`
    mutation OverridePlanLimits($accountId: Int!, $planOverride: PlanOverrideInput) {
  overridePlanLimit(accountId: $accountId, planOverride: $planOverride) {
    id
  }
}
    `;
export type OverridePlanLimitsMutationFn = Apollo.MutationFunction<OverridePlanLimitsMutation, OverridePlanLimitsMutationVariables>;

/**
 * __useOverridePlanLimitsMutation__
 *
 * To run a mutation, you first call `useOverridePlanLimitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverridePlanLimitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overridePlanLimitsMutation, { data, loading, error }] = useOverridePlanLimitsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      planOverride: // value for 'planOverride'
 *   },
 * });
 */
export function useOverridePlanLimitsMutation(baseOptions?: Apollo.MutationHookOptions<OverridePlanLimitsMutation, OverridePlanLimitsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OverridePlanLimitsMutation, OverridePlanLimitsMutationVariables>(OverridePlanLimitsDocument, options);
      }
export type OverridePlanLimitsMutationHookResult = ReturnType<typeof useOverridePlanLimitsMutation>;
export type OverridePlanLimitsMutationResult = Apollo.MutationResult<OverridePlanLimitsMutation>;
export type OverridePlanLimitsMutationOptions = Apollo.BaseMutationOptions<OverridePlanLimitsMutation, OverridePlanLimitsMutationVariables>;
export const UpdateShareAccessLevelDocument = gql`
    mutation UpdateShareAccessLevel($input: UpdatedShareHolderInput!) {
  updateShareAccess(input: $input) {
    id
  }
}
    `;
export type UpdateShareAccessLevelMutationFn = Apollo.MutationFunction<UpdateShareAccessLevelMutation, UpdateShareAccessLevelMutationVariables>;

/**
 * __useUpdateShareAccessLevelMutation__
 *
 * To run a mutation, you first call `useUpdateShareAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShareAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShareAccessLevelMutation, { data, loading, error }] = useUpdateShareAccessLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShareAccessLevelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShareAccessLevelMutation, UpdateShareAccessLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShareAccessLevelMutation, UpdateShareAccessLevelMutationVariables>(UpdateShareAccessLevelDocument, options);
      }
export type UpdateShareAccessLevelMutationHookResult = ReturnType<typeof useUpdateShareAccessLevelMutation>;
export type UpdateShareAccessLevelMutationResult = Apollo.MutationResult<UpdateShareAccessLevelMutation>;
export type UpdateShareAccessLevelMutationOptions = Apollo.BaseMutationOptions<UpdateShareAccessLevelMutation, UpdateShareAccessLevelMutationVariables>;
export const GetDataSourcesDocument = gql`
    query GetDataSources {
  dataSources {
    id
    name
    schema
    type
    provider
    syncFrequency
    ... on DoubleClickDataSource {
      table
    }
    ... on GoogleSheetsDataSource {
      table
    }
    ... on DropboxDataSource {
      table
    }
    ... on S3DataSource {
      table
    }
    ... on FacebookDataSource {
      table
    }
    ... on AirtableDataSource {
      apiKey
    }
    ... on MysqlDataSource {
      host
      port
    }
    ... on PostgresDataSource {
      host
      port
    }
    ... on GoogleAdsAbDataSource {
      startDate
      customerIds
      loginCustomerId
    }
    ... on BraintreeAbDataSource {
      startDate
      environment
      merchantId
      publicKey
    }
    ... on SalesforceAbDataSource {
      startDate
    }
    ... on IntercomAbDataSource {
      startDate
    }
    ... on HubspotAbDataSource {
      startDate
    }
    ... on JiraAbDataSource {
      startDate
    }
    ... on AmplitudeAbDataSource {
      startDate
    }
    ... on FacebookAdsAbDataSource {
      startDate
      accountId
    }
    ... on MicrosoftAdsAbDataSource {
      startDate
    }
    ... on ShopifyAbDataSource {
      shop
      startDate
    }
    status {
      lastRunAt
      syncState
      setupState
    }
    dataWarehouse {
      id
      name
      type
    }
    owner {
      id
      firstName
      lastName
    }
  }
  me {
    id
  }
  myAccount {
    id
    stats {
      dataSourceCount
    }
    plan {
      dataSourceLimit
    }
  }
}
    `;

/**
 * __useGetDataSourcesQuery__
 *
 * To run a query within a React component, call `useGetDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataSourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetDataSourcesQuery, GetDataSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataSourcesQuery, GetDataSourcesQueryVariables>(GetDataSourcesDocument, options);
      }
export function useGetDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataSourcesQuery, GetDataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataSourcesQuery, GetDataSourcesQueryVariables>(GetDataSourcesDocument, options);
        }
export type GetDataSourcesQueryHookResult = ReturnType<typeof useGetDataSourcesQuery>;
export type GetDataSourcesLazyQueryHookResult = ReturnType<typeof useGetDataSourcesLazyQuery>;
export type GetDataSourcesQueryResult = Apollo.QueryResult<GetDataSourcesQuery, GetDataSourcesQueryVariables>;
export function refetchGetDataSourcesQuery(variables?: GetDataSourcesQueryVariables) {
      return { query: GetDataSourcesDocument, variables: variables }
    }
export const GetSourceSchemaDocument = gql`
    query GetSourceSchema($id: Int!) {
  dataSource(id: $id) {
    id
    sourceSchema {
      name
      namespace
      selected
    }
  }
}
    `;

/**
 * __useGetSourceSchemaQuery__
 *
 * To run a query within a React component, call `useGetSourceSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceSchemaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSourceSchemaQuery(baseOptions: Apollo.QueryHookOptions<GetSourceSchemaQuery, GetSourceSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSourceSchemaQuery, GetSourceSchemaQueryVariables>(GetSourceSchemaDocument, options);
      }
export function useGetSourceSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSourceSchemaQuery, GetSourceSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSourceSchemaQuery, GetSourceSchemaQueryVariables>(GetSourceSchemaDocument, options);
        }
export type GetSourceSchemaQueryHookResult = ReturnType<typeof useGetSourceSchemaQuery>;
export type GetSourceSchemaLazyQueryHookResult = ReturnType<typeof useGetSourceSchemaLazyQuery>;
export type GetSourceSchemaQueryResult = Apollo.QueryResult<GetSourceSchemaQuery, GetSourceSchemaQueryVariables>;
export function refetchGetSourceSchemaQuery(variables?: GetSourceSchemaQueryVariables) {
      return { query: GetSourceSchemaDocument, variables: variables }
    }
export const GetUserNameDocument = gql`
    query GetUserName {
  me {
    id
    username
    firstName
    lastName
  }
}
    `;

/**
 * __useGetUserNameQuery__
 *
 * To run a query within a React component, call `useGetUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNameQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNameQuery, GetUserNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNameQuery, GetUserNameQueryVariables>(GetUserNameDocument, options);
      }
export function useGetUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNameQuery, GetUserNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNameQuery, GetUserNameQueryVariables>(GetUserNameDocument, options);
        }
export type GetUserNameQueryHookResult = ReturnType<typeof useGetUserNameQuery>;
export type GetUserNameLazyQueryHookResult = ReturnType<typeof useGetUserNameLazyQuery>;
export type GetUserNameQueryResult = Apollo.QueryResult<GetUserNameQuery, GetUserNameQueryVariables>;
export function refetchGetUserNameQuery(variables?: GetUserNameQueryVariables) {
      return { query: GetUserNameDocument, variables: variables }
    }
export const GetUserInfoDocument = gql`
    query GetUserInfo {
  me {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export function refetchGetUserInfoQuery(variables?: GetUserInfoQueryVariables) {
      return { query: GetUserInfoDocument, variables: variables }
    }
export const GetUserOnboardingDocument = gql`
    query GetUserOnboarding {
  me {
    id
    onboardingState {
      currentStep
      answers
      completed
    }
  }
}
    `;

/**
 * __useGetUserOnboardingQuery__
 *
 * To run a query within a React component, call `useGetUserOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<GetUserOnboardingQuery, GetUserOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOnboardingQuery, GetUserOnboardingQueryVariables>(GetUserOnboardingDocument, options);
      }
export function useGetUserOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOnboardingQuery, GetUserOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOnboardingQuery, GetUserOnboardingQueryVariables>(GetUserOnboardingDocument, options);
        }
export type GetUserOnboardingQueryHookResult = ReturnType<typeof useGetUserOnboardingQuery>;
export type GetUserOnboardingLazyQueryHookResult = ReturnType<typeof useGetUserOnboardingLazyQuery>;
export type GetUserOnboardingQueryResult = Apollo.QueryResult<GetUserOnboardingQuery, GetUserOnboardingQueryVariables>;
export function refetchGetUserOnboardingQuery(variables?: GetUserOnboardingQueryVariables) {
      return { query: GetUserOnboardingDocument, variables: variables }
    }
export const GetSourceTypesDocument = gql`
    query GetSourceTypes {
  sources: dataSourceTypeInfo {
    id
    name
    iconURL
  }
}
    `;

/**
 * __useGetSourceTypesQuery__
 *
 * To run a query within a React component, call `useGetSourceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSourceTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetSourceTypesQuery, GetSourceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSourceTypesQuery, GetSourceTypesQueryVariables>(GetSourceTypesDocument, options);
      }
export function useGetSourceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSourceTypesQuery, GetSourceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSourceTypesQuery, GetSourceTypesQueryVariables>(GetSourceTypesDocument, options);
        }
export type GetSourceTypesQueryHookResult = ReturnType<typeof useGetSourceTypesQuery>;
export type GetSourceTypesLazyQueryHookResult = ReturnType<typeof useGetSourceTypesLazyQuery>;
export type GetSourceTypesQueryResult = Apollo.QueryResult<GetSourceTypesQuery, GetSourceTypesQueryVariables>;
export function refetchGetSourceTypesQuery(variables?: GetSourceTypesQueryVariables) {
      return { query: GetSourceTypesDocument, variables: variables }
    }
export const GetSourceStatusDocument = gql`
    query GetSourceStatus($id: Int!) {
  dataSource(id: $id) {
    id
    type
    status {
      setupState
      syncState
    }
  }
}
    `;

/**
 * __useGetSourceStatusQuery__
 *
 * To run a query within a React component, call `useGetSourceStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSourceStatusQuery(baseOptions: Apollo.QueryHookOptions<GetSourceStatusQuery, GetSourceStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSourceStatusQuery, GetSourceStatusQueryVariables>(GetSourceStatusDocument, options);
      }
export function useGetSourceStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSourceStatusQuery, GetSourceStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSourceStatusQuery, GetSourceStatusQueryVariables>(GetSourceStatusDocument, options);
        }
export type GetSourceStatusQueryHookResult = ReturnType<typeof useGetSourceStatusQuery>;
export type GetSourceStatusLazyQueryHookResult = ReturnType<typeof useGetSourceStatusLazyQuery>;
export type GetSourceStatusQueryResult = Apollo.QueryResult<GetSourceStatusQuery, GetSourceStatusQueryVariables>;
export function refetchGetSourceStatusQuery(variables?: GetSourceStatusQueryVariables) {
      return { query: GetSourceStatusDocument, variables: variables }
    }
export const GetWarehousesDocument = gql`
    query GetWarehouses {
  dataWarehouses {
    id
    name
    type
    owner {
      id
      firstName
      lastName
    }
    sharedWith {
      users {
        user {
          username
          id
          firstName
          lastName
        }
        accessLevel
      }
      teams {
        team {
          id
          name
        }
        accessLevel
      }
      emails {
        email {
          id
          emailAddress
        }
        accessLevel
      }
    }
  }
  me {
    id
  }
  myAccount {
    stats {
      dataWarehouseCount
    }
    plan {
      dataWarehouseLimit
    }
  }
}
    `;

/**
 * __useGetWarehousesQuery__
 *
 * To run a query within a React component, call `useGetWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWarehousesQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehousesQuery, GetWarehousesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(GetWarehousesDocument, options);
      }
export function useGetWarehousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehousesQuery, GetWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(GetWarehousesDocument, options);
        }
export type GetWarehousesQueryHookResult = ReturnType<typeof useGetWarehousesQuery>;
export type GetWarehousesLazyQueryHookResult = ReturnType<typeof useGetWarehousesLazyQuery>;
export type GetWarehousesQueryResult = Apollo.QueryResult<GetWarehousesQuery, GetWarehousesQueryVariables>;
export function refetchGetWarehousesQuery(variables?: GetWarehousesQueryVariables) {
      return { query: GetWarehousesDocument, variables: variables }
    }
export const GetWarehouseDocument = gql`
    query GetWarehouse($id: Int!) {
  warehouse: dataWarehouse(id: $id) {
    id
    name
    type
    ... on SnowflakeDataWarehouse {
      host
      database
      port
      warehouse
      role
    }
    ... on MysqlDataWarehouse {
      host
      database
      port
      ssh {
        port
        host
        username
      }
    }
    ... on RedshiftDataWarehouse {
      host
      database
      port
      ssh {
        port
        host
        username
      }
    }
    ... on PostgresDataWarehouse {
      host
      database
      port
      ssh {
        port
        host
        username
      }
    }
    ... on BigqueryDataWarehouse {
      projectId
      projects {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetWarehouseQuery__
 *
 * To run a query within a React component, call `useGetWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWarehouseQuery(baseOptions: Apollo.QueryHookOptions<GetWarehouseQuery, GetWarehouseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseQuery, GetWarehouseQueryVariables>(GetWarehouseDocument, options);
      }
export function useGetWarehouseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseQuery, GetWarehouseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseQuery, GetWarehouseQueryVariables>(GetWarehouseDocument, options);
        }
export type GetWarehouseQueryHookResult = ReturnType<typeof useGetWarehouseQuery>;
export type GetWarehouseLazyQueryHookResult = ReturnType<typeof useGetWarehouseLazyQuery>;
export type GetWarehouseQueryResult = Apollo.QueryResult<GetWarehouseQuery, GetWarehouseQueryVariables>;
export function refetchGetWarehouseQuery(variables?: GetWarehouseQueryVariables) {
      return { query: GetWarehouseDocument, variables: variables }
    }
export const GetSourceTypesForRecipesDocument = gql`
    query GetSourceTypesForRecipes {
  dataSources {
    id
    type
    name
  }
}
    `;

/**
 * __useGetSourceTypesForRecipesQuery__
 *
 * To run a query within a React component, call `useGetSourceTypesForRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceTypesForRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceTypesForRecipesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSourceTypesForRecipesQuery(baseOptions?: Apollo.QueryHookOptions<GetSourceTypesForRecipesQuery, GetSourceTypesForRecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSourceTypesForRecipesQuery, GetSourceTypesForRecipesQueryVariables>(GetSourceTypesForRecipesDocument, options);
      }
export function useGetSourceTypesForRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSourceTypesForRecipesQuery, GetSourceTypesForRecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSourceTypesForRecipesQuery, GetSourceTypesForRecipesQueryVariables>(GetSourceTypesForRecipesDocument, options);
        }
export type GetSourceTypesForRecipesQueryHookResult = ReturnType<typeof useGetSourceTypesForRecipesQuery>;
export type GetSourceTypesForRecipesLazyQueryHookResult = ReturnType<typeof useGetSourceTypesForRecipesLazyQuery>;
export type GetSourceTypesForRecipesQueryResult = Apollo.QueryResult<GetSourceTypesForRecipesQuery, GetSourceTypesForRecipesQueryVariables>;
export function refetchGetSourceTypesForRecipesQuery(variables?: GetSourceTypesForRecipesQueryVariables) {
      return { query: GetSourceTypesForRecipesDocument, variables: variables }
    }
export const GetRecipesDocument = gql`
    query GetRecipes($dataSourceType: [DataSourceType]) {
  recipes(dataSourceTypes: $dataSourceType) {
    name
    displayName
    description
    dataSourceType
  }
}
    `;

/**
 * __useGetRecipesQuery__
 *
 * To run a query within a React component, call `useGetRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesQuery({
 *   variables: {
 *      dataSourceType: // value for 'dataSourceType'
 *   },
 * });
 */
export function useGetRecipesQuery(baseOptions?: Apollo.QueryHookOptions<GetRecipesQuery, GetRecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecipesQuery, GetRecipesQueryVariables>(GetRecipesDocument, options);
      }
export function useGetRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecipesQuery, GetRecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecipesQuery, GetRecipesQueryVariables>(GetRecipesDocument, options);
        }
export type GetRecipesQueryHookResult = ReturnType<typeof useGetRecipesQuery>;
export type GetRecipesLazyQueryHookResult = ReturnType<typeof useGetRecipesLazyQuery>;
export type GetRecipesQueryResult = Apollo.QueryResult<GetRecipesQuery, GetRecipesQueryVariables>;
export function refetchGetRecipesQuery(variables?: GetRecipesQueryVariables) {
      return { query: GetRecipesDocument, variables: variables }
    }
export const GetDatasetsDocument = gql`
    query GetDatasets {
  dataSets {
    id
    dataSource {
      id
      name
      type
      schema
      dataWarehouse {
        id
        name
        type
      }
    }
    runs(limit: 1) {
      awsJobStatus
    }
    recipe {
      name
      displayName
      outputTable
    }
  }
}
    `;

/**
 * __useGetDatasetsQuery__
 *
 * To run a query within a React component, call `useGetDatasetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDatasetsQuery(baseOptions?: Apollo.QueryHookOptions<GetDatasetsQuery, GetDatasetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetsQuery, GetDatasetsQueryVariables>(GetDatasetsDocument, options);
      }
export function useGetDatasetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetsQuery, GetDatasetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetsQuery, GetDatasetsQueryVariables>(GetDatasetsDocument, options);
        }
export type GetDatasetsQueryHookResult = ReturnType<typeof useGetDatasetsQuery>;
export type GetDatasetsLazyQueryHookResult = ReturnType<typeof useGetDatasetsLazyQuery>;
export type GetDatasetsQueryResult = Apollo.QueryResult<GetDatasetsQuery, GetDatasetsQueryVariables>;
export function refetchGetDatasetsQuery(variables?: GetDatasetsQueryVariables) {
      return { query: GetDatasetsDocument, variables: variables }
    }
export const GetDataSetDocument = gql`
    query GetDataSet($id: Int!) {
  dataSet(id: $id) {
    id
    runs {
      statusReason
      awsJobId
      awsJobStatus
      startTime
      endTime
    }
  }
}
    `;

/**
 * __useGetDataSetQuery__
 *
 * To run a query within a React component, call `useGetDataSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataSetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataSetQuery(baseOptions: Apollo.QueryHookOptions<GetDataSetQuery, GetDataSetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataSetQuery, GetDataSetQueryVariables>(GetDataSetDocument, options);
      }
export function useGetDataSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataSetQuery, GetDataSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataSetQuery, GetDataSetQueryVariables>(GetDataSetDocument, options);
        }
export type GetDataSetQueryHookResult = ReturnType<typeof useGetDataSetQuery>;
export type GetDataSetLazyQueryHookResult = ReturnType<typeof useGetDataSetLazyQuery>;
export type GetDataSetQueryResult = Apollo.QueryResult<GetDataSetQuery, GetDataSetQueryVariables>;
export function refetchGetDataSetQuery(variables?: GetDataSetQueryVariables) {
      return { query: GetDataSetDocument, variables: variables }
    }
export const GetDataSetLogsDocument = gql`
    query GetDataSetLogs($id: Int!, $runId: String!) {
  dataSet(id: $id) {
    id
    runs(id: $runId, limit: 10) {
      logs {
        timestamp
        message
      }
    }
  }
}
    `;

/**
 * __useGetDataSetLogsQuery__
 *
 * To run a query within a React component, call `useGetDataSetLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataSetLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataSetLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useGetDataSetLogsQuery(baseOptions: Apollo.QueryHookOptions<GetDataSetLogsQuery, GetDataSetLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataSetLogsQuery, GetDataSetLogsQueryVariables>(GetDataSetLogsDocument, options);
      }
export function useGetDataSetLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataSetLogsQuery, GetDataSetLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataSetLogsQuery, GetDataSetLogsQueryVariables>(GetDataSetLogsDocument, options);
        }
export type GetDataSetLogsQueryHookResult = ReturnType<typeof useGetDataSetLogsQuery>;
export type GetDataSetLogsLazyQueryHookResult = ReturnType<typeof useGetDataSetLogsLazyQuery>;
export type GetDataSetLogsQueryResult = Apollo.QueryResult<GetDataSetLogsQuery, GetDataSetLogsQueryVariables>;
export function refetchGetDataSetLogsQuery(variables?: GetDataSetLogsQueryVariables) {
      return { query: GetDataSetLogsDocument, variables: variables }
    }
export const GetConnectCardUrlDocument = gql`
    query GetConnectCardUrl($input: Int!) {
  connectCardUrl(id: $input)
}
    `;

/**
 * __useGetConnectCardUrlQuery__
 *
 * To run a query within a React component, call `useGetConnectCardUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectCardUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectCardUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetConnectCardUrlQuery(baseOptions: Apollo.QueryHookOptions<GetConnectCardUrlQuery, GetConnectCardUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConnectCardUrlQuery, GetConnectCardUrlQueryVariables>(GetConnectCardUrlDocument, options);
      }
export function useGetConnectCardUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConnectCardUrlQuery, GetConnectCardUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConnectCardUrlQuery, GetConnectCardUrlQueryVariables>(GetConnectCardUrlDocument, options);
        }
export type GetConnectCardUrlQueryHookResult = ReturnType<typeof useGetConnectCardUrlQuery>;
export type GetConnectCardUrlLazyQueryHookResult = ReturnType<typeof useGetConnectCardUrlLazyQuery>;
export type GetConnectCardUrlQueryResult = Apollo.QueryResult<GetConnectCardUrlQuery, GetConnectCardUrlQueryVariables>;
export function refetchGetConnectCardUrlQuery(variables?: GetConnectCardUrlQueryVariables) {
      return { query: GetConnectCardUrlDocument, variables: variables }
    }
export const GetLicenseLimitDocument = gql`
    query GetLicenseLimit {
  myAccount {
    id
    stats {
      userCount
      adminCount
    }
    plan {
      userLimit
      adminLimit
    }
  }
}
    `;

/**
 * __useGetLicenseLimitQuery__
 *
 * To run a query within a React component, call `useGetLicenseLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicenseLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicenseLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLicenseLimitQuery(baseOptions?: Apollo.QueryHookOptions<GetLicenseLimitQuery, GetLicenseLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLicenseLimitQuery, GetLicenseLimitQueryVariables>(GetLicenseLimitDocument, options);
      }
export function useGetLicenseLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLicenseLimitQuery, GetLicenseLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLicenseLimitQuery, GetLicenseLimitQueryVariables>(GetLicenseLimitDocument, options);
        }
export type GetLicenseLimitQueryHookResult = ReturnType<typeof useGetLicenseLimitQuery>;
export type GetLicenseLimitLazyQueryHookResult = ReturnType<typeof useGetLicenseLimitLazyQuery>;
export type GetLicenseLimitQueryResult = Apollo.QueryResult<GetLicenseLimitQuery, GetLicenseLimitQueryVariables>;
export function refetchGetLicenseLimitQuery(variables?: GetLicenseLimitQueryVariables) {
      return { query: GetLicenseLimitDocument, variables: variables }
    }
export const GetUserRoleDocument = gql`
    query GetUserRole {
  me {
    id
    isAdmin
    isSiteAdmin
  }
}
    `;

/**
 * __useGetUserRoleQuery__
 *
 * To run a query within a React component, call `useGetUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, options);
      }
export function useGetUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, options);
        }
export type GetUserRoleQueryHookResult = ReturnType<typeof useGetUserRoleQuery>;
export type GetUserRoleLazyQueryHookResult = ReturnType<typeof useGetUserRoleLazyQuery>;
export type GetUserRoleQueryResult = Apollo.QueryResult<GetUserRoleQuery, GetUserRoleQueryVariables>;
export function refetchGetUserRoleQuery(variables?: GetUserRoleQueryVariables) {
      return { query: GetUserRoleDocument, variables: variables }
    }
export const GetPlanDocument = gql`
    query GetPlan {
  myAccount {
    id
    expiresAt
    plan {
      id
      name
      reportLimit
      dataSourceLimit
      dataWarehouseLimit
      userLimit
      adminLimit
      scheduleLimit
      upgradableTo {
        id
        name
        reportLimit
        dataWarehouseLimit
        dataSourceLimit
        userLimit
        adminLimit
        description
      }
    }
  }
}
    `;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions?: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
      }
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
        }
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export function refetchGetPlanQuery(variables?: GetPlanQueryVariables) {
      return { query: GetPlanDocument, variables: variables }
    }
export const GetMyAccountUsersDocument = gql`
    query GetMyAccountUsers {
  me {
    id
    account {
      users {
        id
        isAdmin
        email
        firstName
        lastName
        lastLogin
        createdAt
      }
    }
  }
}
    `;

/**
 * __useGetMyAccountUsersQuery__
 *
 * To run a query within a React component, call `useGetMyAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAccountUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAccountUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetMyAccountUsersQuery, GetMyAccountUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAccountUsersQuery, GetMyAccountUsersQueryVariables>(GetMyAccountUsersDocument, options);
      }
export function useGetMyAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAccountUsersQuery, GetMyAccountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAccountUsersQuery, GetMyAccountUsersQueryVariables>(GetMyAccountUsersDocument, options);
        }
export type GetMyAccountUsersQueryHookResult = ReturnType<typeof useGetMyAccountUsersQuery>;
export type GetMyAccountUsersLazyQueryHookResult = ReturnType<typeof useGetMyAccountUsersLazyQuery>;
export type GetMyAccountUsersQueryResult = Apollo.QueryResult<GetMyAccountUsersQuery, GetMyAccountUsersQueryVariables>;
export function refetchGetMyAccountUsersQuery(variables?: GetMyAccountUsersQueryVariables) {
      return { query: GetMyAccountUsersDocument, variables: variables }
    }
export const GetAllAccountsDocument = gql`
    query GetAllAccounts {
  accounts {
    id
    companyName
    expiresAt
    overrideLimit {
      id
      accountId
      reportLimit
      dataWarehouseLimit
      dataSourceLimit
      userLimit
      adminLimit
      scheduleLimit
    }
    plan {
      id
      name
    }
    users {
      id
      isAdmin
      email
      firstName
      lastName
      isSiteAdmin
    }
  }
}
    `;

/**
 * __useGetAllAccountsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAccountsQuery, GetAllAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAccountsQuery, GetAllAccountsQueryVariables>(GetAllAccountsDocument, options);
      }
export function useGetAllAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccountsQuery, GetAllAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAccountsQuery, GetAllAccountsQueryVariables>(GetAllAccountsDocument, options);
        }
export type GetAllAccountsQueryHookResult = ReturnType<typeof useGetAllAccountsQuery>;
export type GetAllAccountsLazyQueryHookResult = ReturnType<typeof useGetAllAccountsLazyQuery>;
export type GetAllAccountsQueryResult = Apollo.QueryResult<GetAllAccountsQuery, GetAllAccountsQueryVariables>;
export function refetchGetAllAccountsQuery(variables?: GetAllAccountsQueryVariables) {
      return { query: GetAllAccountsDocument, variables: variables }
    }
export const GetInvitationsDocument = gql`
    query GetInvitations {
  pendingUserInvitations {
    id
    email
    isAdmin
  }
}
    `;

/**
 * __useGetInvitationsQuery__
 *
 * To run a query within a React component, call `useGetInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<GetInvitationsQuery, GetInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitationsQuery, GetInvitationsQueryVariables>(GetInvitationsDocument, options);
      }
export function useGetInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationsQuery, GetInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitationsQuery, GetInvitationsQueryVariables>(GetInvitationsDocument, options);
        }
export type GetInvitationsQueryHookResult = ReturnType<typeof useGetInvitationsQuery>;
export type GetInvitationsLazyQueryHookResult = ReturnType<typeof useGetInvitationsLazyQuery>;
export type GetInvitationsQueryResult = Apollo.QueryResult<GetInvitationsQuery, GetInvitationsQueryVariables>;
export function refetchGetInvitationsQuery(variables?: GetInvitationsQueryVariables) {
      return { query: GetInvitationsDocument, variables: variables }
    }
export const GetWarehousesReportsDocument = gql`
    query getWarehousesReports {
  dataWarehouses {
    id
    name
    type
  }
}
    `;

/**
 * __useGetWarehousesReportsQuery__
 *
 * To run a query within a React component, call `useGetWarehousesReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehousesReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehousesReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWarehousesReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehousesReportsQuery, GetWarehousesReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehousesReportsQuery, GetWarehousesReportsQueryVariables>(GetWarehousesReportsDocument, options);
      }
export function useGetWarehousesReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehousesReportsQuery, GetWarehousesReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehousesReportsQuery, GetWarehousesReportsQueryVariables>(GetWarehousesReportsDocument, options);
        }
export type GetWarehousesReportsQueryHookResult = ReturnType<typeof useGetWarehousesReportsQuery>;
export type GetWarehousesReportsLazyQueryHookResult = ReturnType<typeof useGetWarehousesReportsLazyQuery>;
export type GetWarehousesReportsQueryResult = Apollo.QueryResult<GetWarehousesReportsQuery, GetWarehousesReportsQueryVariables>;
export function refetchGetWarehousesReportsQuery(variables?: GetWarehousesReportsQueryVariables) {
      return { query: GetWarehousesReportsDocument, variables: variables }
    }
export const PreviewReportDocument = gql`
    query PreviewReport($input: ReportInput!, $limit: Int, $offset: Int, $reportId: Int) {
  runReport(input: $input, reportId: $reportId) {
    sql
    grid(limit: $limit, offset: $offset) {
      columns {
        name
        table
        schema
        label
      }
      rows
    }
    count
  }
}
    `;

/**
 * __usePreviewReportQuery__
 *
 * To run a query within a React component, call `usePreviewReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function usePreviewReportQuery(baseOptions: Apollo.QueryHookOptions<PreviewReportQuery, PreviewReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewReportQuery, PreviewReportQueryVariables>(PreviewReportDocument, options);
      }
export function usePreviewReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewReportQuery, PreviewReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewReportQuery, PreviewReportQueryVariables>(PreviewReportDocument, options);
        }
export type PreviewReportQueryHookResult = ReturnType<typeof usePreviewReportQuery>;
export type PreviewReportLazyQueryHookResult = ReturnType<typeof usePreviewReportLazyQuery>;
export type PreviewReportQueryResult = Apollo.QueryResult<PreviewReportQuery, PreviewReportQueryVariables>;
export function refetchPreviewReportQuery(variables?: PreviewReportQueryVariables) {
      return { query: PreviewReportDocument, variables: variables }
    }
export const BuilderReportCountDocument = gql`
    query BuilderReportCount($input: ReportInput!) {
  runReport(input: $input) {
    count
  }
}
    `;

/**
 * __useBuilderReportCountQuery__
 *
 * To run a query within a React component, call `useBuilderReportCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuilderReportCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuilderReportCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuilderReportCountQuery(baseOptions: Apollo.QueryHookOptions<BuilderReportCountQuery, BuilderReportCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuilderReportCountQuery, BuilderReportCountQueryVariables>(BuilderReportCountDocument, options);
      }
export function useBuilderReportCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuilderReportCountQuery, BuilderReportCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuilderReportCountQuery, BuilderReportCountQueryVariables>(BuilderReportCountDocument, options);
        }
export type BuilderReportCountQueryHookResult = ReturnType<typeof useBuilderReportCountQuery>;
export type BuilderReportCountLazyQueryHookResult = ReturnType<typeof useBuilderReportCountLazyQuery>;
export type BuilderReportCountQueryResult = Apollo.QueryResult<BuilderReportCountQuery, BuilderReportCountQueryVariables>;
export function refetchBuilderReportCountQuery(variables?: BuilderReportCountQueryVariables) {
      return { query: BuilderReportCountDocument, variables: variables }
    }
export const SqlReportCountDocument = gql`
    query SqlReportCount($input: SQLReportInput!) {
  runSQLReport(input: $input) {
    count
  }
}
    `;

/**
 * __useSqlReportCountQuery__
 *
 * To run a query within a React component, call `useSqlReportCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSqlReportCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSqlReportCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSqlReportCountQuery(baseOptions: Apollo.QueryHookOptions<SqlReportCountQuery, SqlReportCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SqlReportCountQuery, SqlReportCountQueryVariables>(SqlReportCountDocument, options);
      }
export function useSqlReportCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SqlReportCountQuery, SqlReportCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SqlReportCountQuery, SqlReportCountQueryVariables>(SqlReportCountDocument, options);
        }
export type SqlReportCountQueryHookResult = ReturnType<typeof useSqlReportCountQuery>;
export type SqlReportCountLazyQueryHookResult = ReturnType<typeof useSqlReportCountLazyQuery>;
export type SqlReportCountQueryResult = Apollo.QueryResult<SqlReportCountQuery, SqlReportCountQueryVariables>;
export function refetchSqlReportCountQuery(variables?: SqlReportCountQueryVariables) {
      return { query: SqlReportCountDocument, variables: variables }
    }
export const ViewReportDocument = gql`
    query ViewReport($id: Int!, $limit: Int) {
  report(id: $id) {
    run {
      sql
      grid(limit: $limit) {
        columns {
          name
          table
          schema
        }
        rows
      }
      count
    }
  }
}
    `;

/**
 * __useViewReportQuery__
 *
 * To run a query within a React component, call `useViewReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useViewReportQuery(baseOptions: Apollo.QueryHookOptions<ViewReportQuery, ViewReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewReportQuery, ViewReportQueryVariables>(ViewReportDocument, options);
      }
export function useViewReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewReportQuery, ViewReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewReportQuery, ViewReportQueryVariables>(ViewReportDocument, options);
        }
export type ViewReportQueryHookResult = ReturnType<typeof useViewReportQuery>;
export type ViewReportLazyQueryHookResult = ReturnType<typeof useViewReportLazyQuery>;
export type ViewReportQueryResult = Apollo.QueryResult<ViewReportQuery, ViewReportQueryVariables>;
export function refetchViewReportQuery(variables?: ViewReportQueryVariables) {
      return { query: ViewReportDocument, variables: variables }
    }
export const PreviewReportSqlDocument = gql`
    query PreviewReportSQL($input: SQLReportInput!, $limit: Int, $offset: Int, $reportId: Int) {
  runSQLReport(input: $input, reportId: $reportId) {
    sql
    grid(limit: $limit, offset: $offset) {
      columns {
        name
        table
        schema
      }
      rows
    }
    count
  }
}
    `;

/**
 * __usePreviewReportSqlQuery__
 *
 * To run a query within a React component, call `usePreviewReportSqlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewReportSqlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewReportSqlQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function usePreviewReportSqlQuery(baseOptions: Apollo.QueryHookOptions<PreviewReportSqlQuery, PreviewReportSqlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewReportSqlQuery, PreviewReportSqlQueryVariables>(PreviewReportSqlDocument, options);
      }
export function usePreviewReportSqlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewReportSqlQuery, PreviewReportSqlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewReportSqlQuery, PreviewReportSqlQueryVariables>(PreviewReportSqlDocument, options);
        }
export type PreviewReportSqlQueryHookResult = ReturnType<typeof usePreviewReportSqlQuery>;
export type PreviewReportSqlLazyQueryHookResult = ReturnType<typeof usePreviewReportSqlLazyQuery>;
export type PreviewReportSqlQueryResult = Apollo.QueryResult<PreviewReportSqlQuery, PreviewReportSqlQueryVariables>;
export function refetchPreviewReportSqlQuery(variables?: PreviewReportSqlQueryVariables) {
      return { query: PreviewReportSqlDocument, variables: variables }
    }
export const GetSchemasDocument = gql`
    query GetSchemas($id: Int!, $reportId: Int) {
  dataWarehouse(id: $id, reportId: $reportId) {
    schemas {
      name
      tables {
        name
      }
    }
  }
}
    `;

/**
 * __useGetSchemasQuery__
 *
 * To run a query within a React component, call `useGetSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemasQuery({
 *   variables: {
 *      id: // value for 'id'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetSchemasQuery(baseOptions: Apollo.QueryHookOptions<GetSchemasQuery, GetSchemasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchemasQuery, GetSchemasQueryVariables>(GetSchemasDocument, options);
      }
export function useGetSchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchemasQuery, GetSchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchemasQuery, GetSchemasQueryVariables>(GetSchemasDocument, options);
        }
export type GetSchemasQueryHookResult = ReturnType<typeof useGetSchemasQuery>;
export type GetSchemasLazyQueryHookResult = ReturnType<typeof useGetSchemasLazyQuery>;
export type GetSchemasQueryResult = Apollo.QueryResult<GetSchemasQuery, GetSchemasQueryVariables>;
export function refetchGetSchemasQuery(variables?: GetSchemasQueryVariables) {
      return { query: GetSchemasDocument, variables: variables }
    }
export const GetColumnsDocument = gql`
    query GetColumns($id: Int!, $schema: String!, $table: String!, $reportId: Int) {
  dataWarehouse(id: $id, reportId: $reportId) {
    schemas(schemaNames: [$schema], tableNames: [$table], reportId: $reportId) {
      name
      tables {
        name
        columns {
          name
          type
        }
      }
    }
  }
}
    `;

/**
 * __useGetColumnsQuery__
 *
 * To run a query within a React component, call `useGetColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColumnsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      schema: // value for 'schema'
 *      table: // value for 'table'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetColumnsQuery(baseOptions: Apollo.QueryHookOptions<GetColumnsQuery, GetColumnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetColumnsQuery, GetColumnsQueryVariables>(GetColumnsDocument, options);
      }
export function useGetColumnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetColumnsQuery, GetColumnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetColumnsQuery, GetColumnsQueryVariables>(GetColumnsDocument, options);
        }
export type GetColumnsQueryHookResult = ReturnType<typeof useGetColumnsQuery>;
export type GetColumnsLazyQueryHookResult = ReturnType<typeof useGetColumnsLazyQuery>;
export type GetColumnsQueryResult = Apollo.QueryResult<GetColumnsQuery, GetColumnsQueryVariables>;
export function refetchGetColumnsQuery(variables?: GetColumnsQueryVariables) {
      return { query: GetColumnsDocument, variables: variables }
    }
export const GetUserInvitationDocument = gql`
    query GetUserInvitation($token: String!) {
  userInvitation(token: $token) {
    alreadyRegistered
    recipientEmail
    inviterEmail
    companyName
  }
}
    `;

/**
 * __useGetUserInvitationQuery__
 *
 * To run a query within a React component, call `useGetUserInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInvitationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetUserInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetUserInvitationQuery, GetUserInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInvitationQuery, GetUserInvitationQueryVariables>(GetUserInvitationDocument, options);
      }
export function useGetUserInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInvitationQuery, GetUserInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInvitationQuery, GetUserInvitationQueryVariables>(GetUserInvitationDocument, options);
        }
export type GetUserInvitationQueryHookResult = ReturnType<typeof useGetUserInvitationQuery>;
export type GetUserInvitationLazyQueryHookResult = ReturnType<typeof useGetUserInvitationLazyQuery>;
export type GetUserInvitationQueryResult = Apollo.QueryResult<GetUserInvitationQuery, GetUserInvitationQueryVariables>;
export function refetchGetUserInvitationQuery(variables?: GetUserInvitationQueryVariables) {
      return { query: GetUserInvitationDocument, variables: variables }
    }
export const GetReportsListDocument = gql`
    query GetReportsList {
  reports {
    id
    name
    accessLevel
    dataWarehouse {
      name
      id
    }
    owner {
      email
      id
    }
    sharedWith {
      users {
        user {
          username
          id
          firstName
          lastName
          email
        }
        accessLevel
      }
      teams {
        team {
          id
          name
        }
        accessLevel
      }
      emails {
        email {
          id
          emailAddress
        }
        accessLevel
      }
    }
    updatedAt
    tags
    definition {
      __typename
    }
  }
  myAccount {
    stats {
      reportCount
    }
    plan {
      reportLimit
    }
  }
  dataWarehouses {
    id
  }
}
    `;

/**
 * __useGetReportsListQuery__
 *
 * To run a query within a React component, call `useGetReportsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReportsListQuery(baseOptions?: Apollo.QueryHookOptions<GetReportsListQuery, GetReportsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsListQuery, GetReportsListQueryVariables>(GetReportsListDocument, options);
      }
export function useGetReportsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsListQuery, GetReportsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsListQuery, GetReportsListQueryVariables>(GetReportsListDocument, options);
        }
export type GetReportsListQueryHookResult = ReturnType<typeof useGetReportsListQuery>;
export type GetReportsListLazyQueryHookResult = ReturnType<typeof useGetReportsListLazyQuery>;
export type GetReportsListQueryResult = Apollo.QueryResult<GetReportsListQuery, GetReportsListQueryVariables>;
export function refetchGetReportsListQuery(variables?: GetReportsListQueryVariables) {
      return { query: GetReportsListDocument, variables: variables }
    }
export const ListSchedulesDocument = gql`
    query ListSchedules($filter: ScheduleFilterInput) {
  schedules(filter: $filter) {
    id
    name
    report {
      name
      id
    }
    destination {
      type
    }
    destinationConfig {
      ... on GSheetsDestinationConfig {
        spreadsheetId
        sheetId
      }
    }
    reportTemplate {
      name
      value
    }
    latestRun {
      endTime
      status
    }
    cronStr
    timeZone
    notifyOn
    status
    nextRunAt
    owner {
      id
    }
  }
  me {
    id
  }
  myAccount {
    plan {
      scheduleLimit
    }
  }
}
    `;

/**
 * __useListSchedulesQuery__
 *
 * To run a query within a React component, call `useListSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSchedulesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListSchedulesQuery(baseOptions?: Apollo.QueryHookOptions<ListSchedulesQuery, ListSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSchedulesQuery, ListSchedulesQueryVariables>(ListSchedulesDocument, options);
      }
export function useListSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSchedulesQuery, ListSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSchedulesQuery, ListSchedulesQueryVariables>(ListSchedulesDocument, options);
        }
export type ListSchedulesQueryHookResult = ReturnType<typeof useListSchedulesQuery>;
export type ListSchedulesLazyQueryHookResult = ReturnType<typeof useListSchedulesLazyQuery>;
export type ListSchedulesQueryResult = Apollo.QueryResult<ListSchedulesQuery, ListSchedulesQueryVariables>;
export function refetchListSchedulesQuery(variables?: ListSchedulesQueryVariables) {
      return { query: ListSchedulesDocument, variables: variables }
    }
export const ListScheduledReportsDocument = gql`
    query ListScheduledReports($filter: ScheduleFilterInput) {
  schedules(filter: $filter) {
    report {
      id
    }
  }
}
    `;

/**
 * __useListScheduledReportsQuery__
 *
 * To run a query within a React component, call `useListScheduledReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScheduledReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScheduledReportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListScheduledReportsQuery(baseOptions?: Apollo.QueryHookOptions<ListScheduledReportsQuery, ListScheduledReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListScheduledReportsQuery, ListScheduledReportsQueryVariables>(ListScheduledReportsDocument, options);
      }
export function useListScheduledReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListScheduledReportsQuery, ListScheduledReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListScheduledReportsQuery, ListScheduledReportsQueryVariables>(ListScheduledReportsDocument, options);
        }
export type ListScheduledReportsQueryHookResult = ReturnType<typeof useListScheduledReportsQuery>;
export type ListScheduledReportsLazyQueryHookResult = ReturnType<typeof useListScheduledReportsLazyQuery>;
export type ListScheduledReportsQueryResult = Apollo.QueryResult<ListScheduledReportsQuery, ListScheduledReportsQueryVariables>;
export function refetchListScheduledReportsQuery(variables?: ListScheduledReportsQueryVariables) {
      return { query: ListScheduledReportsDocument, variables: variables }
    }
export const GetScheduleRunStatusListDocument = gql`
    query GetScheduleRunStatusList($id: Int!, $limit: Int!) {
  schedule(id: $id) {
    nextRunAt
    latestRun {
      status
      errorMessage
      startTime
      endTime
    }
    runs(limit: $limit) {
      runId
      status
      errorMessage
      startTime
      endTime
    }
  }
}
    `;

/**
 * __useGetScheduleRunStatusListQuery__
 *
 * To run a query within a React component, call `useGetScheduleRunStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleRunStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleRunStatusListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetScheduleRunStatusListQuery(baseOptions: Apollo.QueryHookOptions<GetScheduleRunStatusListQuery, GetScheduleRunStatusListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduleRunStatusListQuery, GetScheduleRunStatusListQueryVariables>(GetScheduleRunStatusListDocument, options);
      }
export function useGetScheduleRunStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleRunStatusListQuery, GetScheduleRunStatusListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduleRunStatusListQuery, GetScheduleRunStatusListQueryVariables>(GetScheduleRunStatusListDocument, options);
        }
export type GetScheduleRunStatusListQueryHookResult = ReturnType<typeof useGetScheduleRunStatusListQuery>;
export type GetScheduleRunStatusListLazyQueryHookResult = ReturnType<typeof useGetScheduleRunStatusListLazyQuery>;
export type GetScheduleRunStatusListQueryResult = Apollo.QueryResult<GetScheduleRunStatusListQuery, GetScheduleRunStatusListQueryVariables>;
export function refetchGetScheduleRunStatusListQuery(variables?: GetScheduleRunStatusListQueryVariables) {
      return { query: GetScheduleRunStatusListDocument, variables: variables }
    }
export const ListAllReportsDocument = gql`
    query ListAllReports {
  reports {
    id
    name
  }
}
    `;

/**
 * __useListAllReportsQuery__
 *
 * To run a query within a React component, call `useListAllReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllReportsQuery(baseOptions?: Apollo.QueryHookOptions<ListAllReportsQuery, ListAllReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllReportsQuery, ListAllReportsQueryVariables>(ListAllReportsDocument, options);
      }
export function useListAllReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllReportsQuery, ListAllReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllReportsQuery, ListAllReportsQueryVariables>(ListAllReportsDocument, options);
        }
export type ListAllReportsQueryHookResult = ReturnType<typeof useListAllReportsQuery>;
export type ListAllReportsLazyQueryHookResult = ReturnType<typeof useListAllReportsLazyQuery>;
export type ListAllReportsQueryResult = Apollo.QueryResult<ListAllReportsQuery, ListAllReportsQueryVariables>;
export function refetchListAllReportsQuery(variables?: ListAllReportsQueryVariables) {
      return { query: ListAllReportsDocument, variables: variables }
    }
export const GetDestinationsDocument = gql`
    query GetDestinations {
  destinations {
    id
    name
    type
    owner {
      username
    }
  }
}
    `;

/**
 * __useGetDestinationsQuery__
 *
 * To run a query within a React component, call `useGetDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDestinationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDestinationsQuery(baseOptions?: Apollo.QueryHookOptions<GetDestinationsQuery, GetDestinationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDestinationsQuery, GetDestinationsQueryVariables>(GetDestinationsDocument, options);
      }
export function useGetDestinationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDestinationsQuery, GetDestinationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDestinationsQuery, GetDestinationsQueryVariables>(GetDestinationsDocument, options);
        }
export type GetDestinationsQueryHookResult = ReturnType<typeof useGetDestinationsQuery>;
export type GetDestinationsLazyQueryHookResult = ReturnType<typeof useGetDestinationsLazyQuery>;
export type GetDestinationsQueryResult = Apollo.QueryResult<GetDestinationsQuery, GetDestinationsQueryVariables>;
export function refetchGetDestinationsQuery(variables?: GetDestinationsQueryVariables) {
      return { query: GetDestinationsDocument, variables: variables }
    }
export const GetSpreadsheetsDocument = gql`
    query GetSpreadsheets($id: Int!, $name: String) {
  destination(id: $id) {
    id
    type
    name
    __typename
    ... on GSheetDestination {
      spreadsheets(name: $name) {
        id
        name
      }
    }
    ... on EmailDestination {
      email
    }
    ... on SlackDestination {
      channels {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetSpreadsheetsQuery__
 *
 * To run a query within a React component, call `useGetSpreadsheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpreadsheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpreadsheetsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetSpreadsheetsQuery(baseOptions: Apollo.QueryHookOptions<GetSpreadsheetsQuery, GetSpreadsheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpreadsheetsQuery, GetSpreadsheetsQueryVariables>(GetSpreadsheetsDocument, options);
      }
export function useGetSpreadsheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpreadsheetsQuery, GetSpreadsheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpreadsheetsQuery, GetSpreadsheetsQueryVariables>(GetSpreadsheetsDocument, options);
        }
export type GetSpreadsheetsQueryHookResult = ReturnType<typeof useGetSpreadsheetsQuery>;
export type GetSpreadsheetsLazyQueryHookResult = ReturnType<typeof useGetSpreadsheetsLazyQuery>;
export type GetSpreadsheetsQueryResult = Apollo.QueryResult<GetSpreadsheetsQuery, GetSpreadsheetsQueryVariables>;
export function refetchGetSpreadsheetsQuery(variables?: GetSpreadsheetsQueryVariables) {
      return { query: GetSpreadsheetsDocument, variables: variables }
    }
export const GetSheetIdsDocument = gql`
    query GetSheetIds($destinationId: Int!, $spreadSheetId: String!) {
  destination(id: $destinationId) {
    id
    type
    name
    __typename
    ... on GSheetDestination {
      spreadsheets(id: $spreadSheetId) {
        sheets {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetSheetIdsQuery__
 *
 * To run a query within a React component, call `useGetSheetIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSheetIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSheetIdsQuery({
 *   variables: {
 *      destinationId: // value for 'destinationId'
 *      spreadSheetId: // value for 'spreadSheetId'
 *   },
 * });
 */
export function useGetSheetIdsQuery(baseOptions: Apollo.QueryHookOptions<GetSheetIdsQuery, GetSheetIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSheetIdsQuery, GetSheetIdsQueryVariables>(GetSheetIdsDocument, options);
      }
export function useGetSheetIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSheetIdsQuery, GetSheetIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSheetIdsQuery, GetSheetIdsQueryVariables>(GetSheetIdsDocument, options);
        }
export type GetSheetIdsQueryHookResult = ReturnType<typeof useGetSheetIdsQuery>;
export type GetSheetIdsLazyQueryHookResult = ReturnType<typeof useGetSheetIdsLazyQuery>;
export type GetSheetIdsQueryResult = Apollo.QueryResult<GetSheetIdsQuery, GetSheetIdsQueryVariables>;
export function refetchGetSheetIdsQuery(variables?: GetSheetIdsQueryVariables) {
      return { query: GetSheetIdsDocument, variables: variables }
    }
export const ConnectionsAndReportsDocument = gql`
    query ConnectionsAndReports {
  dataSources {
    id
    name
    type
    status {
      lastRunAt
      setupState
      syncState
    }
  }
  reports {
    id
    name
    owner {
      firstName
      lastName
    }
    updatedAt
  }
}
    `;

/**
 * __useConnectionsAndReportsQuery__
 *
 * To run a query within a React component, call `useConnectionsAndReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsAndReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsAndReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConnectionsAndReportsQuery(baseOptions?: Apollo.QueryHookOptions<ConnectionsAndReportsQuery, ConnectionsAndReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionsAndReportsQuery, ConnectionsAndReportsQueryVariables>(ConnectionsAndReportsDocument, options);
      }
export function useConnectionsAndReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionsAndReportsQuery, ConnectionsAndReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionsAndReportsQuery, ConnectionsAndReportsQueryVariables>(ConnectionsAndReportsDocument, options);
        }
export type ConnectionsAndReportsQueryHookResult = ReturnType<typeof useConnectionsAndReportsQuery>;
export type ConnectionsAndReportsLazyQueryHookResult = ReturnType<typeof useConnectionsAndReportsLazyQuery>;
export type ConnectionsAndReportsQueryResult = Apollo.QueryResult<ConnectionsAndReportsQuery, ConnectionsAndReportsQueryVariables>;
export function refetchConnectionsAndReportsQuery(variables?: ConnectionsAndReportsQueryVariables) {
      return { query: ConnectionsAndReportsDocument, variables: variables }
    }
export const MultiSelectDocument = gql`
    query MultiSelect($input: ReportInput!, $reportId: Int) {
  runReport(input: $input, reportId: $reportId) {
    sql
    grid(distinct: true) {
      columns {
        name
        table
        schema
      }
      rows
    }
    count
  }
}
    `;

/**
 * __useMultiSelectQuery__
 *
 * To run a query within a React component, call `useMultiSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useMultiSelectQuery(baseOptions: Apollo.QueryHookOptions<MultiSelectQuery, MultiSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MultiSelectQuery, MultiSelectQueryVariables>(MultiSelectDocument, options);
      }
export function useMultiSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MultiSelectQuery, MultiSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MultiSelectQuery, MultiSelectQueryVariables>(MultiSelectDocument, options);
        }
export type MultiSelectQueryHookResult = ReturnType<typeof useMultiSelectQuery>;
export type MultiSelectLazyQueryHookResult = ReturnType<typeof useMultiSelectLazyQuery>;
export type MultiSelectQueryResult = Apollo.QueryResult<MultiSelectQuery, MultiSelectQueryVariables>;
export function refetchMultiSelectQuery(variables?: MultiSelectQueryVariables) {
      return { query: MultiSelectDocument, variables: variables }
    }
export const GetReportDataDocument = gql`
    query GetReportData($id: Int!) {
  report(id: $id) {
    id
    name
    accessLevel
    dataWarehouse {
      id
      name
      type
    }
    owner {
      id
      email
    }
    definition {
      ... on ReportSQL {
        query
        template {
          name
          value
          dataType
          inputType
          format
          multiSelectQuery
          multiSelectOptions {
            display
            value
          }
        }
      }
      ... on ReportTable {
        schema
        table {
          name
          cols {
            name
            label
          }
          groupBy {
            name
          }
          aggregations {
            func
            col {
              name
            }
          }
        }
        conditions {
          col {
            name
            label
          }
          op
          val
        }
        filters {
          col {
            name
            label
          }
          op
          vals
        }
        joins {
          join {
            schema
            table {
              name
              cols {
                name
              }
              groupBy {
                name
              }
              aggregations {
                func
                col {
                  name
                }
              }
            }
            conditions {
              col {
                name
                label
              }
              op
              val
            }
            filters {
              col {
                name
                label
              }
              op
              vals
            }
            joins {
              join {
                schema
                table {
                  name
                  cols {
                    name
                  }
                  groupBy {
                    name
                  }
                  aggregations {
                    func
                    col {
                      name
                    }
                  }
                }
                conditions {
                  col {
                    name
                    label
                  }
                  op
                  val
                }
                filters {
                  col {
                    name
                    label
                  }
                  op
                  vals
                }
                joins {
                  join {
                    schema
                    table {
                      name
                      cols {
                        name
                      }
                      groupBy {
                        name
                      }
                      aggregations {
                        func
                        col {
                          name
                        }
                      }
                    }
                    conditions {
                      col {
                        name
                        label
                      }
                      op
                      val
                    }
                    filters {
                      col {
                        name
                        label
                      }
                      op
                      vals
                    }
                    joins {
                      join {
                        schema
                        table {
                          name
                          cols {
                            name
                          }
                          groupBy {
                            name
                          }
                          aggregations {
                            func
                            col {
                              name
                            }
                          }
                        }
                        conditions {
                          col {
                            name
                            label
                          }
                          op
                          val
                        }
                        filters {
                          col {
                            name
                            label
                          }
                          op
                          vals
                        }
                        joins {
                          type
                          on {
                            parentCol {
                              name
                            }
                            op
                            childCol {
                              name
                            }
                          }
                        }
                      }
                      type
                      on {
                        parentCol {
                          name
                        }
                        op
                        childCol {
                          name
                        }
                      }
                    }
                  }
                  type
                  on {
                    parentCol {
                      name
                    }
                    op
                    childCol {
                      name
                    }
                  }
                }
              }
              type
              on {
                parentCol {
                  name
                }
                op
                childCol {
                  name
                }
              }
            }
          }
          type
          on {
            parentCol {
              name
            }
            op
            childCol {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetReportDataQuery__
 *
 * To run a query within a React component, call `useGetReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportDataQuery(baseOptions: Apollo.QueryHookOptions<GetReportDataQuery, GetReportDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportDataQuery, GetReportDataQueryVariables>(GetReportDataDocument, options);
      }
export function useGetReportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportDataQuery, GetReportDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportDataQuery, GetReportDataQueryVariables>(GetReportDataDocument, options);
        }
export type GetReportDataQueryHookResult = ReturnType<typeof useGetReportDataQuery>;
export type GetReportDataLazyQueryHookResult = ReturnType<typeof useGetReportDataLazyQuery>;
export type GetReportDataQueryResult = Apollo.QueryResult<GetReportDataQuery, GetReportDataQueryVariables>;
export function refetchGetReportDataQuery(variables?: GetReportDataQueryVariables) {
      return { query: GetReportDataDocument, variables: variables }
    }
export const GetFilterColsDocument = gql`
    query GetFilterCols($id: Int!, $schemas: [String!], $tables: [String!], $reportId: Int) {
  dataWarehouse(id: $id, reportId: $reportId) {
    schemas(schemaNames: $schemas, tableNames: $tables) {
      name
      tables {
        name
        columns {
          name
          type
        }
      }
    }
  }
}
    `;

/**
 * __useGetFilterColsQuery__
 *
 * To run a query within a React component, call `useGetFilterColsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterColsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterColsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      schemas: // value for 'schemas'
 *      tables: // value for 'tables'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetFilterColsQuery(baseOptions: Apollo.QueryHookOptions<GetFilterColsQuery, GetFilterColsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterColsQuery, GetFilterColsQueryVariables>(GetFilterColsDocument, options);
      }
export function useGetFilterColsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterColsQuery, GetFilterColsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterColsQuery, GetFilterColsQueryVariables>(GetFilterColsDocument, options);
        }
export type GetFilterColsQueryHookResult = ReturnType<typeof useGetFilterColsQuery>;
export type GetFilterColsLazyQueryHookResult = ReturnType<typeof useGetFilterColsLazyQuery>;
export type GetFilterColsQueryResult = Apollo.QueryResult<GetFilterColsQuery, GetFilterColsQueryVariables>;
export function refetchGetFilterColsQuery(variables?: GetFilterColsQueryVariables) {
      return { query: GetFilterColsDocument, variables: variables }
    }
export const PreviewReportRefetchDocument = gql`
    query PreviewReportRefetch($input: ReportInput!) {
  runReport(input: $input) {
    sql
  }
}
    `;

/**
 * __usePreviewReportRefetchQuery__
 *
 * To run a query within a React component, call `usePreviewReportRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewReportRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewReportRefetchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreviewReportRefetchQuery(baseOptions: Apollo.QueryHookOptions<PreviewReportRefetchQuery, PreviewReportRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewReportRefetchQuery, PreviewReportRefetchQueryVariables>(PreviewReportRefetchDocument, options);
      }
export function usePreviewReportRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewReportRefetchQuery, PreviewReportRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewReportRefetchQuery, PreviewReportRefetchQueryVariables>(PreviewReportRefetchDocument, options);
        }
export type PreviewReportRefetchQueryHookResult = ReturnType<typeof usePreviewReportRefetchQuery>;
export type PreviewReportRefetchLazyQueryHookResult = ReturnType<typeof usePreviewReportRefetchLazyQuery>;
export type PreviewReportRefetchQueryResult = Apollo.QueryResult<PreviewReportRefetchQuery, PreviewReportRefetchQueryVariables>;
export function refetchPreviewReportRefetchQuery(variables?: PreviewReportRefetchQueryVariables) {
      return { query: PreviewReportRefetchDocument, variables: variables }
    }
export const GetUserTeamsDocument = gql`
    query GetUserTeams {
  me {
    teams {
      id
      name
      description
      members {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;

/**
 * __useGetUserTeamsQuery__
 *
 * To run a query within a React component, call `useGetUserTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTeamsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserTeamsQuery, GetUserTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTeamsQuery, GetUserTeamsQueryVariables>(GetUserTeamsDocument, options);
      }
export function useGetUserTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTeamsQuery, GetUserTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTeamsQuery, GetUserTeamsQueryVariables>(GetUserTeamsDocument, options);
        }
export type GetUserTeamsQueryHookResult = ReturnType<typeof useGetUserTeamsQuery>;
export type GetUserTeamsLazyQueryHookResult = ReturnType<typeof useGetUserTeamsLazyQuery>;
export type GetUserTeamsQueryResult = Apollo.QueryResult<GetUserTeamsQuery, GetUserTeamsQueryVariables>;
export function refetchGetUserTeamsQuery(variables?: GetUserTeamsQueryVariables) {
      return { query: GetUserTeamsDocument, variables: variables }
    }
export const GetTeamsInAccountDocument = gql`
    query GetTeamsInAccount {
  me {
    id
    email
    firstName
    lastName
  }
  teams {
    id
    name
    description
    members {
      id
      firstName
      lastName
      email
    }
    owner {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useGetTeamsInAccountQuery__
 *
 * To run a query within a React component, call `useGetTeamsInAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsInAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsInAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamsInAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamsInAccountQuery, GetTeamsInAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamsInAccountQuery, GetTeamsInAccountQueryVariables>(GetTeamsInAccountDocument, options);
      }
export function useGetTeamsInAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamsInAccountQuery, GetTeamsInAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamsInAccountQuery, GetTeamsInAccountQueryVariables>(GetTeamsInAccountDocument, options);
        }
export type GetTeamsInAccountQueryHookResult = ReturnType<typeof useGetTeamsInAccountQuery>;
export type GetTeamsInAccountLazyQueryHookResult = ReturnType<typeof useGetTeamsInAccountLazyQuery>;
export type GetTeamsInAccountQueryResult = Apollo.QueryResult<GetTeamsInAccountQuery, GetTeamsInAccountQueryVariables>;
export function refetchGetTeamsInAccountQuery(variables?: GetTeamsInAccountQueryVariables) {
      return { query: GetTeamsInAccountDocument, variables: variables }
    }
export const GetAllPlansDocument = gql`
    query GetAllPlans {
  plans {
    id
    name
    description
    reportLimit
    dataWarehouseLimit
    dataSourceLimit
    userLimit
    adminLimit
    price {
      monthly
      yearly
    }
  }
  myAccount {
    id
    subscription {
      billingCycle
      amount
      nextBillingDate
      card {
        brand
        last4
        expiryMonth
        expiryYear
      }
    }
  }
}
    `;

/**
 * __useGetAllPlansQuery__
 *
 * To run a query within a React component, call `useGetAllPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPlansQuery, GetAllPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPlansQuery, GetAllPlansQueryVariables>(GetAllPlansDocument, options);
      }
export function useGetAllPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPlansQuery, GetAllPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPlansQuery, GetAllPlansQueryVariables>(GetAllPlansDocument, options);
        }
export type GetAllPlansQueryHookResult = ReturnType<typeof useGetAllPlansQuery>;
export type GetAllPlansLazyQueryHookResult = ReturnType<typeof useGetAllPlansLazyQuery>;
export type GetAllPlansQueryResult = Apollo.QueryResult<GetAllPlansQuery, GetAllPlansQueryVariables>;
export function refetchGetAllPlansQuery(variables?: GetAllPlansQueryVariables) {
      return { query: GetAllPlansDocument, variables: variables }
    }
export const GetDataSourceErrorLogsDocument = gql`
    query GetDataSourceErrorLogs($id: Int!) {
  dataSource(id: $id) {
    status {
      syncErrorLogs
    }
  }
}
    `;

/**
 * __useGetDataSourceErrorLogsQuery__
 *
 * To run a query within a React component, call `useGetDataSourceErrorLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataSourceErrorLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataSourceErrorLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataSourceErrorLogsQuery(baseOptions: Apollo.QueryHookOptions<GetDataSourceErrorLogsQuery, GetDataSourceErrorLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataSourceErrorLogsQuery, GetDataSourceErrorLogsQueryVariables>(GetDataSourceErrorLogsDocument, options);
      }
export function useGetDataSourceErrorLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataSourceErrorLogsQuery, GetDataSourceErrorLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataSourceErrorLogsQuery, GetDataSourceErrorLogsQueryVariables>(GetDataSourceErrorLogsDocument, options);
        }
export type GetDataSourceErrorLogsQueryHookResult = ReturnType<typeof useGetDataSourceErrorLogsQuery>;
export type GetDataSourceErrorLogsLazyQueryHookResult = ReturnType<typeof useGetDataSourceErrorLogsLazyQuery>;
export type GetDataSourceErrorLogsQueryResult = Apollo.QueryResult<GetDataSourceErrorLogsQuery, GetDataSourceErrorLogsQueryVariables>;
export function refetchGetDataSourceErrorLogsQuery(variables?: GetDataSourceErrorLogsQueryVariables) {
      return { query: GetDataSourceErrorLogsDocument, variables: variables }
    }